import { useState } from "react";
import { useConnectionData } from "./useConnectionData";

export const useDatabaseTypes = (store: any) => {
    const [isLoadingDatabaseTypes, setIsLoadingDatabaseTypes] = useState(false);
    const [databaseTypes, setDatabaseTypes] = useState<{ db_type: string; column_type: string }[]>([]);
    const { getConnectionDataById } = useConnectionData(store);

    const fetchDatabaseTypes = async (dbId: string | number) => {
        if (!dbId) return;

        setIsLoadingDatabaseTypes(true);

        const connection = await getConnectionDataById(dbId);

        if (!connection) {
            setIsLoadingDatabaseTypes(false);
            return;
        }

        store.newDataQuality
            .getDatabaseDataTypes(connection?.db_type)
            .then((data) => setDatabaseTypes(data))
            .catch(() => setDatabaseTypes([]))
            .finally(() => setIsLoadingDatabaseTypes(false));
    };

    return { databaseTypes, isLoadingDatabaseTypes, fetchDatabaseTypes };
};
