import { Col, Layout, Row, Spin, theme } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useStore } from "../../../stores/StoreContext";
export const MenuFooter = () => {
    const { Footer } = Layout;

    const { useToken } = theme;
    const store = useStore();
    const { token } = useToken();
    const [appVersion, setAppVersion] = useState("");

    useEffect(() => {
        async function fetchData() {
            return await store.configStore.getAppVersion().then(() => setAppVersion(store.configStore.app.version));
        }
        fetchData();
    }, []);

    return (
        <>
            <Footer className="da-bg-color-black-10" style={{ background: token.colorPrimaryBg }}>
                <Row align="middle" justify="space-between">
                    <Col md={12} span={24}>
                        <p className="da-badge-text da-mb-0">©{dayjs().format("YYYY")} X-Automate</p>
                    </Col>
                    <Col md={12} span={24} className="da-mt-sm-8 da-text-sm-center da-text-right">
                        <Row align="end">
                            {appVersion ? (
                                <span target="_blank" className="da-badge-text">
                                    APP version: {appVersion}
                                </span>
                            ) : (
                                <Spin className="remix-icon" />
                            )}
                        </Row>
                    </Col>
                </Row>
            </Footer>
        </>
    );
};

export default MenuFooter;
