import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class BugStore extends PaginationStore {
    store;

    bugs = observable({});

    error = {
        create: "",
        get: "",
    };

    constructor(store) {
        super();
        this.store = store;
    }

    setError = (id, data) => {
        this.error[id] = data;
    };

    setBugs = action((data, page, reported_bug_id) => {
        if (page === 1) {
            this.bugs[reported_bug_id] = data;
        } else {
            this.bugs[reported_bug_id].concat(data);
        }
    });

    getExecutionsByReportedBugId = action((reported_bug_id, page = 1, size = 50) => {
        return this.store.apiStore
            .get({
                url: `/reported_bugs/${reported_bug_id}?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setBugs(data["items"], page, reported_bug_id);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError(err, "get"));
    });

    getReportedBugsForExecutionId = action((execution_id) => {
        return this.store.apiStore
            .get({
                url: `/reported_bugs/by_execution/${execution_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError(err, "get"));
    });
}
export default BugStore;
