import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";
class LogStore extends PaginationStore {
    store;

    errors = observable([]);

    constructor(store) {
        super();
        this.store = store;
    }

    getErrorLogByExecutionId = action((execution_ids) => {
        let url = "/execution_error/by_execution_ids?page=1&size=50";
        url = execution_ids.map((i) => url.concat(`&execution_ids=${i}`));
        return this.store.apiStore
            .get({
                url: url,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });
}
export default LogStore;
