import { action, observable } from "mobx";

class SearchStore {
    store;

    searchText = observable("");
    obj = observable({
        item: {},
        category: "",
    });
    error = "";

    constructor(store) {
        this.store = store;
    }

    setError = action((err) => {
        this.error = err;
    });

    setSearchText = action((item) => {
        this.searchText = item;
    });

    setSearchItemObjectCategory = action((item) => {
        this.obj.category = item;
    });

    setSearchItemObject = action((item) => {
        this.obj.item = item;
    });

    setSearchItem = action((item) => {
        this.obj = item;
    });

    getSearchUrl = ({ pagination, page, size }) => {
        let url;
        if (pagination) {
            url = `/search/?pagination=${pagination}&distinct=true&page=${page}&size=${size}`;
        } else {
            url = `/search/?pagination=${pagination}&distinct=true`;
        }
        return url;
    };

    resolveTableByType = (type) => {
        let table = [];
        if (type === "suite") {
            table.push({
                table_name: "test_suite",
            });
        } else if (type === "approach") {
            table.push({
                table_name: "test_approach",
            });
        } else if (type === "case") {
            table.push({
                table_name: "test_case",
            });
        } else if (type === "requirement") {
            table.push({
                table_name: "requirements",
            });
        }
        return table;
    };

    resolveItemsByTypeWithValue = (type, value) => {
        let items = [];
        if (type === "suite") {
            items.push({
                column: "test_suite.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_suite.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "approach") {
            items.push({
                column: "test_approach.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_approach.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "case") {
            items.push({
                column: "test_case.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "test_case.id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        } else if (type === "requirement") {
            items.push({
                column: "requirements.name",
                is_value_column: false,
                search_value: `%${value}%`,
                search_type: "like",
            });
            items.push({
                column: "requirements.work_item_id",
                is_value_column: false,
                search_value: `${value}`,
                search_type: "eq",
            });
        }
        return items;
    };

    searchHeaderData = (type, value) => {
        let table = this.resolveTableByType(type);
        let items = this.resolveItemsByTypeWithValue(type, value);
        let final_body = {
            search: table,
            filters: [
                {
                    logical_opperand: "or",
                    items: items,
                },
            ],
        };

        return this.store.apiStore
            .post({
                url: "/search/?page=1&size=10",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                switch (type) {
                    case "suite":
                        if (data) {
                            this.store.testSuiteStore.setTestSuites(data);
                            this.store.testSuiteStore.setTotal(data.length);
                            this.store.testSuiteStore.setSize(50);
                            this.store.testSuiteStore.setPagination(false);
                        } else {
                            this.store.testSuiteStore.setTestSuites([]);
                            this.store.testSuiteStore.setTotal(0);
                            this.store.testSuiteStore.setSize(0);
                            this.store.testSuiteStore.setPagination(false);
                        }
                        break;
                    case "case":
                        if (data) {
                            this.store.testCaseStore.setTestCases(data);
                            this.store.testCaseStore.setTotal(data.length);
                            this.store.testCaseStore.setSize(50);
                            this.store.testCaseStore.setPagination(false);
                        } else {
                            this.store.testCaseStore.setTestCases([]);
                            this.store.testCaseStore.setTotal(0);
                            this.store.testCaseStore.setSize(0);
                            this.store.testCaseStore.setPagination(false);
                        }
                        break;
                    case "approach":
                        if (data) {
                            this.store.testApproachStore.setTestApproaches(data);
                            this.store.testApproachStore.setTotal(data.length);
                            this.store.testApproachStore.setSize(50);
                            this.store.testApproachStore.setPagination(false);
                        } else {
                            this.store.testApproachStore.setTestApproaches([]);
                            this.store.testApproachStore.setTotal(0);
                            this.store.testApproachStore.setSize(0);
                            this.store.testApproachStore.setPagination(false);
                        }
                        break;
                    case "requirement":
                        if (data) {
                            this.store.requirementStore.setRequirements(data);
                            this.store.requirementStore.setTotal(data.length);
                            this.store.requirementStore.setSize(50);
                            this.store.requirementStore.setPagination(false);
                        } else {
                            this.store.requirementStore.setRequirements([]);
                            this.store.requirementStore.setTotal(0);
                            this.store.requirementStore.setSize(0);
                            this.store.requirementStore.setPagination(false);
                        }
                        break;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError(err));
    };
}

export default SearchStore;
