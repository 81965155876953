import { useEffect } from "react";

export const useDataFromDags = (store: any) => {
    useEffect(() => {
        if (Object.keys(store.dagStore.settings).length !== 2) {
            Promise.allSettled([store.dagStore.getDagSettings(), store.dagStore.getDagSettings("TRIGGERS")]);
        }
    }, [store.dagStore.settings]);

    const getDataFromNodes = (nodes: any[]) => {
        return nodes.reduce(
            (acc, node) => {
                node.items.forEach((item) => {
                    const releaseId = item.kwargs.release_id;

                    if (releaseId && !acc.releaseIds.includes(releaseId)) {
                        acc.releaseIds.push(releaseId);
                    }

                    if (item.kwargs.filters) {
                        item.kwargs.filters.forEach((itemFilter) => {
                            const repoId = itemFilter.repo_id;
                            const dbId = itemFilter.db_id;

                            if (repoId && !acc.repoIds.includes(repoId)) {
                                acc.repoIds.push(repoId);
                            }

                            if (dbId && !acc.dbIds.includes(dbId)) {
                                acc.dbIds.push(dbId);
                            }
                        });
                    }

                    const sourceDbId = item.kwargs?.reconciliation_settings?.source_db_config_id;
                    const targetDbId = item.kwarks?.reconciliation_settings?.target_db_config_id;

                    if (sourceDbId && !acc.dbIds.includes(sourceDbId)) {
                        acc.dbIds.push(sourceDbId);
                    }

                    if (targetDbId && !acc.dbIds.includes(targetDbId)) {
                        acc.dbIds.push(targetDbId);
                    }
                });

                return acc;
            },
            { releaseIds: [], repoIds: [], dbIds: [] }
        );
    };

    const getDataFromDags = (dags: any[]) => {
        return dags.reduce(
            (acc, dag) => {
                const dataFromNodes = getDataFromNodes(dag?.execution_params?.args?.nodes || dag?.dag_template?.nodes);

                Object.entries(dataFromNodes).forEach(([key, value]) => {
                    (value as number[]).forEach((item) => {
                        if (acc[key].includes(item)) {
                            return acc;
                        } else {
                            acc[key].push(item);
                        }
                    });
                });

                return acc;
            },
            { releaseIds: [], repoIds: [], dbIds: [] }
        );
    };

    const fetchDataForNodes = async ({ releaseIds = [], repoIds = [], dbIds = [] }) => {
        const currentReleaseIds = store.releaseStore.releases.map((release) => release.id);
        const currentRepoIds = store.gitStore.repos.map((repo) => repo.id);
        const currentDbIds = store.settingsStore.databases.map((database) => database.id);

        const isNewReleaseIds = releaseIds.some((releaseId) => !currentReleaseIds.includes(releaseId));
        const isNewRepoIds = repoIds.some((repoId) => !currentRepoIds.includes(repoId));
        const isNewDbIds = dbIds.some((dbId) => !currentDbIds.includes(dbId));

        return Promise.allSettled([
            isNewReleaseIds
                ? store.releaseStore.searchRelease({
                      filters: { id: releaseIds },
                      pagination: false,
                  })
                : Promise.resolve(null),
            isNewRepoIds ? store.settingsStore.searchConnectionDBRepo({ id: repoIds }, "repo", "or") : Promise.resolve(null),
            isNewDbIds ? store.settingsStore.searchConnectionDBRepo({ id: dbIds }, "connection", "or") : Promise.resolve(null),
        ]);
    };

    return { getDataFromNodes, getDataFromDags, fetchDataForNodes };
};
