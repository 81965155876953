import { useEffect, useState } from "react";
import { VariableGroupType, VariableType } from "../view/pages/executions/simple/steps/UploadVariablesModal";

export const useExecutionVariables = (initVariables: VariableType[], initGroup: VariableGroupType) => {
    const [variables, setVariables] = useState<VariableType[]>(
        initVariables?.length ? initVariables : [{ key: "", value: "", show: false }]
    );
    const [group, setGroup] = useState<{ persistent: boolean; name: string | null }>(
        initGroup || {
            persistent: false,
            name: null,
        }
    );

    useEffect(() => {
        if (initVariables) {
            setVariables(initVariables);
        }
    }, [initVariables]);

    useEffect(() => {
        if (initGroup) {
            setGroup(initGroup);
        }
    }, [initGroup]);

    return { variables, group, setVariables, setGroup };
};
