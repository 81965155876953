import { observable, action, makeObservable } from "mobx";
import PaginationStore from "./paginationStore";

class WebExecutionStore extends PaginationStore {
    store;

    suites = observable([]);
    cases = observable([]);
    repo = observable([]);

    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, { repo: observable });
    }

    reset() {
        this.setWebTestCases([]);
        this.setWebSuites([]);
        this.setRepo([]);
    }

    setWebSuites = action((set) => {
        this.suites = set;
    });

    pushWebSuites = action((sut) => {
        this.suites.push(sut);
    });

    deleteWebSuites = action((cas) => {
        let index = this.suites.findIndex((caseI) => caseI.suite_name === cas.suite_name);
        this.suites.splice(index, 1);
    });

    setWebTestCases = action((cas) => {
        this.cases = cas;
    });

    pushWebCases = action((cas) => {
        this.cases.push(cas);
    });

    deleteWebCases = action((cas) => {
        let index = this.cases.findIndex((caseI) => caseI.id === cas.id);
        this.cases.splice(index, 1);
    });

    setRepo = action((repo) => {
        this.repo = repo;
    });
}
export default WebExecutionStore;
