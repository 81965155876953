import { BsClipboardData } from "react-icons/bs";
import { ImInsertTemplate } from "react-icons/im";
import { IoGitCompareOutline } from "react-icons/io5";
import { LuBookTemplate } from "react-icons/lu";
import { MdOutlineSpaceDashboard, MdRule } from "react-icons/md";
import {
    RiAddLine,
    RiBarChartFill,
    RiCheckboxCircleLine,
    RiDashboardFill,
    RiDatabaseLine,
    RiFoldersLine,
    RiGitCommitLine,
    RiGuideLine,
    RiPlayCircleLine,
    RiRocketLine,
} from "react-icons/ri";
import { TbGitCommit, TbGitMerge, TbLayoutDashboard, TbReportSearch } from "react-icons/tb";
import { VscVmRunning } from "react-icons/vsc";
import env from "../env/environment";

const pages = [
    {
        id: "dashboard",
        title: "Dashboard",
        icon: <RiDashboardFill />,
        navLink: "/",
        only_admin: false,
    },
    {
        id: "requirements",
        title: "Requirements",
        icon: <RiGuideLine />,
        navLink: "/requirements",
        only_admin: false,
    },
    {
        id: "ai_ml_predictions",
        title: "AI/ML predictions",
        icon: <RiRocketLine />,
        navLink: "/predictions",
        only_admin: false,
    },
    {
        id: "tests",
        title: "Test",
        icon: <RiCheckboxCircleLine />,
        only_admin: false,
        children: [
            {
                id: "tests_cases",
                title: "Test Suites",
                icon: <RiFoldersLine />,
                navLink: "/tests/suites",
                only_admin: false,
            },
            {
                id: "tests_approaches_cases",
                title: "Test Approaches",
                icon: <RiDatabaseLine />,
                navLink: "/tests/approaches",
                only_admin: false,
            },
            {
                id: "test_cases",
                title: "Test Cases",
                icon: <RiFoldersLine />,
                navLink: "/tests/cases",
                only_admin: false,
            },
            {
                id: "rules",
                title: "Rules",
                icon: <MdRule />,
                navLink: "/rules",
                only_admin: false,
            },
            {
                id: "new_test_case_definition",
                title: "New Test Case",
                icon: <RiAddLine />,
                navLink: "/tests/cases/new",
                only_admin: false,
            },
            {
                id: "reconciliation",
                title: "Compare & Run",
                icon: <IoGitCompareOutline />,
                navLink: "/reconciliation",
                only_admin: false,
            },
        ],
    },
    {
        id: "data_quality",
        title: "Data Quality",
        icon: <BsClipboardData />,
        only_admin: false,
        dq: true,
        children: [
            {
                id: "data_quality_dashboard",
                title: "Dashboard",
                icon: <TbLayoutDashboard />,
                navLink: "/data_quality/dashboard",
                only_admin: false,
                dq: true,
            },
            {
                id: "new_aggregation_check",
                title: "New DQ Rule",
                icon: <RiAddLine />,
                navLink: "/data_quality/new",
                only_admin: false,
                dq: true,
            },
            {
                id: "new_dq_view_results",
                title: "DQ Rules",
                icon: <MdOutlineSpaceDashboard />,
                navLink: "/data_quality/results",
                only_admin: false,
                dq: true,
            },
        ],
    },
    {
        id: "releases",
        title: "Releases",
        icon: <RiGitCommitLine />,
        navLink: "/release",
        only_admin: false,
    },
    {
        id: "run_execution",
        title: "Executions",
        icon: <RiPlayCircleLine />,
        only_admin: false,
        children: [
            {
                id: "runtime_variables_execution",
                title: "Runtime Variables",
                icon: <VscVmRunning />,
                navLink: "/variables/results",
                only_admin: true,
            },
            {
                id: "simple_execution",
                title: "Simple Run",
                icon: <TbGitCommit />,
                navLink: "/executions/new",
                only_admin: false,
            },
        ],
    },
    {
        id: "workflow",
        title: "Workflow",
        icon: <TbGitMerge />,
        only_admin: false,
        children: [
            {
                id: "workflow_new",
                title: "New Workflow",
                icon: <LuBookTemplate />,
                navLink: "/workflow/new",
                only_admin: false,
            },
            {
                id: "template_results",
                title: "Templates",
                icon: <ImInsertTemplate />,
                navLink: "/workflow/templates",
                only_admin: false,
            },
        ],
    },
    {
        id: "execution_results",
        title: "Results",
        icon: <RiBarChartFill />,
        navLink: "/executions/results",
        only_admin: false,
    },
    {
        id: "reports",
        title: "Reports",
        icon: <TbReportSearch />,
        navLink: `${env.METABASE_SUBPAGE}`,
        external_link: true,
        only_admin: false,
    },
];

export default pages;
