export enum SelectionType {
    PREDEFINED = "PREDEFINED",
    PREDEFINED_V2 = "PREDEFINED_V2",
    CUSTOM = "CUSTOM",
}

export enum AggregationSelectType {
    BASIC = "BASIC",
    ADVANCED = "ADVANCED",
}

export type AggregationType = {
    type: string | undefined;
    operator: string | undefined;
    values: any[];
};

export type CustomAggregationType = {
    enabled: boolean;
    value: string | undefined;
};

export enum AGGREGATION_TYPES {
    SUM = "SUM",
    AVG = "AVG",
    MIN = "MIN",
    MAX = "MAX",
    COUNT = "COUNT",
}

export const DEFAULT_WARNING_THRESHOLD = 99.7;
export const DEFAULT_FAILURE_THRESHOLD = 98.5;

export const DEFAULT_AGGREGATION_DEVIATION_WARNING = 5.0;
export const DEFAULT_AGGREGATION_DEVIATION_FAILURE = 10.0;

export enum JOIN {
    LEFT = "LEFT JOIN",
    RIGHT = "RIGHT JOIN",
    INNER = "INNER JOIN",
}

export type DQQueryType = {
    use_raw: boolean;
    input: string | undefined;
    loading: boolean;
    validated: boolean;
    status: any;
};

export type DqCheckConfig = {
    schema?: string;
    object?: string;
    column?: string;
    joinColumn?: string;
    aggregationType?: AGGREGATION_TYPES;
    where?: string;
    dqResults?: any;
    profilingResults?: any;
};

export enum TestCaseCategoryType {
    SIMPLE = "simple",
}
