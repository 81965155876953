import { useState } from "react";
import { optionsSize } from "../config";

export const useGitRepoOptions = (store: any) => {
    const [repos, setRepos] = useState({ items: [], pageCount: 1 });
    const [reposIsLoading, setReposIsLoading] = useState(false);

    const fetchRepoOptions = (optionFilters = {}, page = 1) => {
        if (!repos.items.length) setReposIsLoading(true);

        store.settingsStore
            .searchConnectionDBRepo(optionFilters, "repo", "or", page)
            .then((data) => {
                if (!data) return;

                setRepos((prevRepos) => {
                    const prevIds = new Set(prevRepos.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevRepos.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setReposIsLoading(false));
    };

    return { repos, reposIsLoading, fetchRepoOptions };
};
