import { Typography } from "antd";
import { FunctionComponent } from "react";

interface SelectOptionType {
    id: string | number;
    name: string;
}

export const SelectOption: FunctionComponent<SelectOptionType> = ({ name = "", id = "" }) => {
    return (
        <Typography>
            <Typography.Text type="secondary">{id}</Typography.Text> {name}
        </Typography>
    );
};
