import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class SettingsStore extends PaginationStore {
    store;
    databases = observable([]);

    database = observable({});
    users = observable(null);
    error = {
        get: "",
        new: "",
        edit: "",
        delete: "",
        metadata: "",
    };

    info = {
        test: "",
        move_dimensions: "",
    };

    constructor(store) {
        super();
        this.store = store;
    }

    getDefaultDbConnection = () => {
        return this.databases.find((it) => it.is_default);
    };

    setDatabaseConnection = action((dbs) => {
        this.databases = [...dbs.filter((db) => db.is_default), ...dbs.filter((db) => !db.is_default)];
    });

    setSingleDatabase = action((id, data) => {
        this.database[id] = data;
    });

    setUsers = action((users) => {
        this.users = users.users;
    });

    setTestConnectionInfo = action((err) => {
        this.info.test = err;
    });

    setDimensionConnectionInfo = action((err) => {
        this.info.move_dimensions = err;
    });

    setConnectionsError = action((err) => {
        this.error.get = err;
    });

    setNewConnectionError = action((err) => {
        if (err[0]?.msg) {
            this.error.new = err[0].msg;
        } else {
            this.error.new = err;
        }
    });

    setEditConnectionError = action((err) => {
        if (err[0]?.msg) {
            this.error.edit = err[0].msg;
        } else {
            this.error.edit = err;
        }
    });

    setDeleteConnectionError = action((err) => {
        this.error.delete = err;
    });

    setMetadataError = action((err) => {
        this.error.metadata = err;
    });

    getDatabaseConnections = action((page = 1, size = 50) => {
        return this.store.apiStore
            .get({
                url: `/connections/database?page=${page}&size=${size}`,

                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setDatabaseConnection(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setConnectionsError(err));
    });

    getDatabaseConnection = action((id) => {
        return this.store.apiStore
            .get({
                url: `/connections/database/${id}`,

                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSingleDatabase(id, data);
            })
            .catch((e) => {
                Promise.reject(`Unable to get connection for ${id}`);
            });
    });

    editDatabase = ({ id, item = null, status = null, is_default = null }) => {
        let finalItem;
        if (item) {
            finalItem = {
                connection_name: item.connection_name,
                db_name: item.name,
                db_type: item.type,
                db_host_name: item.hostname,
                db_port: item.port,
                db_user: item.user,
                db_password: item.password,
                is_default: item.is_default,
                db_description: item.description,
                db_sid: item.db_sid,
                db_schema: item.schema,
                db_account: item.dbaccount,
                db_warehouse: item.dbwarehouse,
                is_active: item.is_active,
                db_dq_write_schema: item.db_dq_write_schema,
                db_dq_write_table: item.db_dq_write_table,
                db_catalog: item.db_catalog,
                db_http_path: item.db_http_path,
                is_unity_catalog: item.is_unity_catalog,
            };
        } else if (typeof status == "boolean") {
            finalItem = {
                is_active: status,
            };
        } else if (typeof is_default == "boolean") {
            finalItem = {
                is_default: is_default,
            };
        }
        if (item?.password === "") {
            delete finalItem["db_password"];
        }

        return this.store.apiStore
            .put({
                url: `/connections/database/${id}`,

                body: JSON.stringify(finalItem),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setEditConnectionError(err));
    };

    createTestDatabase = (item) => {
        return this.store.apiStore
            .post({
                url: "/connections/database",

                body: JSON.stringify({
                    connection_name: item.db.connection_name,
                    db_name: item.db.dbtype === "DATABRICKS" ? item.db.connection_name : item.db.name,
                    db_type: item.db.dbtype,
                    db_host_name: item.db.hostname,
                    db_port: item.db.port,
                    db_user: item.db.dbtype === "DATABRICKS" ? "admin" : item.db.user,
                    db_password: item.db.password,
                    is_default: item.db.is_default,
                    db_description: item.db.description,
                    db_sid: item.db.db_sid,
                    db_schema: item.db.schema,
                    db_account: item.db.account,
                    db_warehouse: item.db.warehouse,
                    is_active: item.db.is_active,
                    db_dq_write_schema: item.db.db_dq_write_schema,
                    db_dq_write_table: item.db.db_dq_write_table,
                    db_catalog: item.db.db_catalog,
                    db_http_path: item.db.db_http_path,
                    is_unity_catalog: item.db.is_unity_catalog,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data["db_config_id"]) {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewConnectionError(err));
    };

    collectMetadata = ({ db_config_id, include, exclude, meta_only = true, full_flow = false } = {}) => {
        let body = { db_config_id: db_config_id, full_flow: full_flow };
        if (include) {
            body.include = include;
        }
        if (exclude) {
            body.exclude = exclude;
        }

        return this.store.apiStore
            .post({
                url: `/connections/database_metadata/gather?meta_only=${meta_only}`,

                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setMetadataError("");
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "metadata");
                this.store.intervalStore.registerWatcher();
                return this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `METADATA Submit`,
                    status: "SUCCESS",
                    type: "METADATA",
                    link: "/settings/connections",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setMetadataError(err));
    };

    deleteConnectionDatabase(database_id) {
        return this.store.apiStore
            .delete({
                url: `/connections/database/${database_id}`,

                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getDatabaseConnections(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteConnectionError(err));
    }

    testConnection = (item, type) => {
        let body = {};
        if (type === "DB") {
            body = {
                connection_name: item.db.connection_name,
                db_name: item.db.dbtype === "DATABRICKS" ? item.db.connection_name : item.db.name,
                db_type: item.db.dbtype,
                db_host_name: item.db.hostname,
                db_port: item.db.port,
                db_user: item.db.dbtype === "DATABRICKS" ? "admin" : item.db.user,
                db_password: item.db.password,
                db_description: item.db.description,
                db_sid: item.db.db_sid,
                db_schema: item.db.schema,
                db_account: item.db.account,
                db_warehouse: item.db.warehouse,
                db_catalog: item.db.db_catalog,
                db_http_path: item.db.db_http_path,
            };
        } else if (type === "GIT") {
            body = {
                connection_name: item.git.connection_name,
                description: item.git.description,
                git_repo_url: item.git.url,
                branch: item.git.branch,
                git_ssh_key: item.git.sshkey,
                git_token: item.git.token,
                repository_type: item.git.repository_type,
            };
        }
        return this.store.apiStore
            .post({
                url: `/connections/test/${type?.toLowerCase()}`,

                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestConnectionInfo(data["msg"]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data)))
            .catch((err) => this.setTestConnectionInfo(err["msg"] ? err["msg"] : err["detail"]));
    };

    testConnectionById(id) {
        return this.store.apiStore
            .post({
                url: `/connections/test/db/${id}`,

                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestConnectionInfo(data["msg"]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data)))
            .catch((err) => this.setTestConnectionInfo(err["msg"] ? err["msg"] : err["detail"]?.[0]?.msg));
    }

    moveDimensionsToRemoteTable = (id) => {
        return this.store.apiStore
            .post({
                url: `/connections/database/${id}/move_dimensions`,

                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setDimensionConnectionInfo(data?.["task_id"]);
                if (data?.["task_id"]) {
                    this.store.intervalStore.updateTaskUpdator(data.task_id, "dimensions");
                    this.store.intervalStore.registerWatcher();
                    this.store.intervalStore.pushNew({
                        task: data.task_id,
                        content: `Dimensions task submit`,
                        status: "SUCCESS",
                        type: "DIMENSIONS",
                        link: "/settings/connections",
                    });
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data)))
            .catch((err) => this.setTestConnectionInfo(err["msg"] ? err["msg"] : err["detail"]?.[0]?.msg));
    };

    searchConnectionDBRepo(filters, type, searchType, page = 1, size = 10) {
        let filtersToSend = this.combineFiltersForSearch(filters, type, searchType);
        let tables = this.combineTableForSearch(type);
        let body = {
            search: tables,
            filters: filtersToSend,
        };
        if (this.store.authStore.ifUserIsAnAdmin()) {
            if (type === "connection") {
                body["return_schema"] = "DbConfigSchemaExtended";
            } else if (type === "repo") {
                body["return_schema"] = "GitRepoSchemaExtended";
            }
        }

        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: true, page, size }),
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (type === "connection") {
                        this.store.settingsStore.setDatabaseConnection(data.items);
                    } else if (type === "repo") {
                        this.store.gitStore.setGitConnection(data.items);
                    }
                }

                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewConnectionError(err));
    }

    combineFiltersForSearch(filters, type, searchType) {
        let filtersReady = [];
        Object.entries(filters).map((key, value) => {
            let items = [];
            if (key[0] === "id" && type === "connection") {
                key[1].map((val) => {
                    items.push({
                        column: "db_config.id",
                        is_value_column: false,
                        search_value: `${val}`,
                        search_type: "eq",
                    });
                });
            } else if ((key[0] === "connection_name" || key[0] === "name") && type === "connection") {
                key[1].map((val) => {
                    items.push({
                        column: "db_config.connection_name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "is_active" && type === "connection") {
                key[1].map((val) => {
                    items.push({
                        column: "db_config.is_active",
                        is_value_column: false,
                        search_value: val,
                        search_type: "eq",
                    });
                });
            } else if (key[0] === "is_active" && type === "repo") {
                key[1].map((val) => {
                    items.push({
                        column: "git_repo.is_active",
                        is_value_column: false,
                        search_value: val,
                        search_type: "eq",
                    });
                });
            } else if (key[0] === "id" && type === "repo") {
                key[1].map((val) => {
                    items.push({
                        column: "git_repo.id",
                        is_value_column: false,
                        search_value: val,
                        search_type: "eq",
                    });
                });
            } else if ((key[0] === "connection_name" || key[0] === "name") && type === "repo") {
                key[1].map((val) => {
                    items.push({
                        column: "git_repo.connection_name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            }
            filtersReady.push({
                logical_opperand: searchType ? searchType : "and",
                items: items,
            });
        });
        return filtersReady;
    }

    combineTableForSearch(type) {
        let tables = [];
        if (type === "connection") {
            tables.push({
                table_name: "db_config",
            });
        } else if (type === "repo") {
            tables.push({
                table_name: "git_repo",
            });
        }
        return tables;
    }
}

export default SettingsStore;
