import { Empty, Skeleton } from "antd";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import React from "react";
import Chart from "react-apexcharts";
import { BarVericalChart } from "./BarVericalChart";

@inject("store")
@observer
class ExecutionsLineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            test_run: null,
        };
    }

    handleChartClick = ({ clientX, clientY }, chartContext, { dataPointIndex }) => {
        if (this.props.choosen.test_suite_name) {
            this.props.setLoading("test_run_details", true);
            this.setState({
                test_run: this.props.choosen.details[dataPointIndex].test_run_id,
            });
            return this.props.store.metricsStore
                .getRunDetails(this.props.choosen.details[dataPointIndex].name, this.props.choosen.details[dataPointIndex].test_run_id)
                .then(() => this.props.setLoading("test_run_details", false))
                .then(() => this.props.setRunDetails(true));
        }
    };

    render() {
        const data =
            !this.props.loading && !this.props.test_run_details
                ? {
                      series: [
                          {
                              name: "Execution duration",
                              type: "area",
                              data:
                                  this.props.choosen && this.props.choosen.details
                                      ? this.props.choosen.details.map((it) => it.execution_time_ms / 1000)
                                      : [],
                          },
                      ],

                      options: {
                          chart: {
                              events: {
                                  dataPointSelection: this.handleChartClick,
                              },
                              fontFamily: "Manrope, sans-serif",
                              type: "area",
                              zoom: {
                                  autoScaleYaxis: true,
                              },
                          },
                          tooltip: {
                              custom: (value) => {
                                  let color = this.props.choosen.details[value.dataPointIndex].status !== "FAIL" ? "#005B4D" : "#c65664";
                                  return (
                                      '<div class="apexcharts-tooltip-title" style="font-family: Manrope, sans-serif; font-size: 12px;"> ' +
                                      dayjs(this.props.choosen.details[value.dataPointIndex]?.start_time).format("YYYY-MM-DD HH:mm:ss") +
                                      ' </div> \
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color: #ccac5c;"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                        <span class="apexcharts-tooltip-text-y-label">Execution duration</span> \
                        <span class="apexcharts-tooltip-text-y-value">' +
                                      value.series[0][value.dataPointIndex] +
                                      's</span> \
                    </div> \
                </div> \
                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color:' +
                                      color +
                                      '"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                        <span class="apexcharts-tooltip-text-y-label">Execution Status</span> \
                        <span class="apexcharts-tooltip-text-y-value">' +
                                      this.props.choosen.details[value.dataPointIndex].status +
                                      "</span> \
                    </div> \
                </div>"
                                  );
                              },
                          },
                          colors: ["#ccac5c"],
                          labels: {
                              style: {
                                  fontSize: "14px",
                              },
                          },

                          dataLabels: {
                              enabled: false,
                          },

                          grid: {
                              borderColor: "#DFE6E9",
                              row: {
                                  opacity: 0.5,
                              },
                          },

                          xaxis: {
                              title: {
                                  text: "Date",
                              },
                              type: "datetime",
                              stroke: {
                                  curve: "smooth",
                              },

                              tickPlacement: "between",
                              labels: {
                                  datetimeUTC: false,
                                  style: {
                                      fontSize: "14px",
                                  },
                              },
                              categories:
                                  this.props.choosen && this.props.choosen.details
                                      ? this.props.choosen.details.map((it) => dayjs(it.start_time).format("YYYY-MM-DD HH:mm"))
                                      : [],
                          },
                          yaxis: {
                              title: {
                                  text: "Duration",
                              },
                              labels: {
                                  style: {
                                      fontSize: "14px",
                                  },
                                  formatter: (value) => {
                                      return `${Number(value.toFixed(2))} s`;
                                  },
                              },
                              forceNiceScale: true,
                          },
                          markers: {
                              colors: ["#ccac5c"],
                              strokeWidth: 0,
                              size: 6,
                              hover: {
                                  sizeOffset: 1,
                              },
                          },
                      },
                  }
                : {};

        const datas =
            this.props.test_run_details && !this.props.loading_tc_runs && this.state.test_run
                ? {
                      series: [
                          {
                              name: "Duration",
                              data: this.props.store.metricsStore.suites
                                  .filter((it) => it.test_suite_name === this.props.choosen.test_suite_name)[0]
                                  .runs[this.state.test_run].map((it) => it.execution_time_ms),
                          },
                      ],
                      options: {
                          chart: {
                              type: "bar",
                              height: "100%",
                          },
                          colors: ["#ccac5c"],
                          plotOptions: {
                              bar: {
                                  barHeight: "80%",
                                  horizontal: true,
                              },
                          },
                          dataLabels: {
                              enabled: false,
                          },
                          xaxis: {
                              type: "category",
                              categories: this.props.store.metricsStore.suites
                                  .filter((it) => it.test_suite_name === this.props.choosen.test_suite_name)[0]
                                  .runs[this.state.test_run].map((it) => it.name),
                              forceNiceScale: true,
                              labels: {
                                  style: {
                                      fontSize: "14px",
                                  },
                                  formatter: (value) => {
                                      return `${value} ms`;
                                  },
                              },
                          },
                          yaxis: {
                              show: true,
                              align: "right",
                              minWidth: 0,
                              maxWidth: 160,
                              style: {
                                  cssClass: "apexcharts-yaxis-label",
                              },
                              offsetX: 0,
                              offsetY: 0,
                              rotate: 0,
                              formatter: (value) => {
                                  return value;
                              },
                          },
                          tooltip: {
                              custom: (value) => {
                                  let point = this.props.store.metricsStore.suites.filter(
                                      (it) => it.test_suite_name === this.props.choosen.test_suite_name
                                  )[0].runs[this.state.test_run][value.dataPointIndex];
                                  let color = point.status !== "FAIL" ? "#005B4D" : "#c65664";
                                  return (
                                      '<div class="apexcharts-tooltip-title" style="font-family: Manrope, sans-serif; font-size: 12px;"> ' +
                                      point.name +
                                      ' </div> \
              <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color: #ccac5c;"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                      <span class="apexcharts-tooltip-text-y-label">Execution time</span> \
                      <span class="apexcharts-tooltip-text-y-value">' +
                                      value.series[0][value.dataPointIndex] +
                                      'ms </span> \
                  </div> \
              </div> \
              <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                                      '<span class="apexcharts-tooltip-marker" style="background-color:' +
                                      color +
                                      '"></span>' +
                                      '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                                      '<div class="apexcharts-tooltip-y-group"> \
                      <span class="apexcharts-tooltip-text-y-label">Status</span> \
                      <span class="apexcharts-tooltip-text-y-value">' +
                                      point.status +
                                      "</span> \
                  </div> \
              </div>"
                                  );
                              },
                          },
                      },
                  }
                : {};
        return (
            <>
                {this.props.test_run_details ? (
                    this.props.loading_tc_runs && !this.state.test_run ? (
                        <Skeleton active paragraph={{ rows: 9 }} />
                    ) : this.props.store.metricsStore.suites.filter((it) => it.test_suite_name === this.props.choosen?.test_suite_name)[0]
                          ?.runs?.[this.state.test_run].length > 0 ? (
                        <BarVericalChart
                            data_chart={this.props.store.metricsStore.suites
                                .filter((it) => it.test_suite_name === this.props.choosen.test_suite_name)[0]
                                .runs[this.state.test_run].map((it) => {
                                    return { sname: it.name, value: it.execution_time_ms };
                                })
                                .sort((a, b) => b.value - a.value)}
                            serie_name="Duration"
                        />
                    ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                ) : this.props.loading ? (
                    <Skeleton active paragraph={{ rows: 9 }} />
                ) : (
                    <div id="chart">
                        <Chart options={data.options} series={data.series} type="area" height={350} legend="legend" />
                    </div>
                )}
            </>
        );
    }
}
export default ExecutionsLineChart;
