import { Badge, Button, Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { execution_types } from "../../../../../enums";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import {
    useDBConnectionOptions,
    useDistinctValueOptions,
    useGitRepoOptions,
    useReleaseOptions,
    useTestApproachOptions,
    useTestCaseOptions,
    useTestSuiteOptions,
    useUserOptions,
} from "../hooks";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";

export const ExecutionsFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let exec_name = findFilter(filters, "execution_name");
    let bugs_only = findFilter(filters, "bugs_only");
    const store = useStore();
    const {
        distinctValues: statusOptions,
        distinctValuesIsLoading: statusOptionsIsLoading,
        fetchDistinctValueOptions: fetchStatusOptions,
    } = useDistinctValueOptions(store);

    const { testApproaches, testApproachesIsLoading, fetchTestApproachOptions } = useTestApproachOptions(store);
    const { testSuites, testSuitesIsLoading, fetchTestSuiteOptions } = useTestSuiteOptions(store);
    const { testCases, testCasesIsLoading, fetchTestCaseOptions } = useTestCaseOptions(store);
    const { users, usersIsLoading, fetchUserOptions } = useUserOptions(store);
    const { releases, releasesIsLoading, fetchReleaseOptions } = useReleaseOptions(store);
    const { repos, reposIsLoading, fetchRepoOptions } = useGitRepoOptions(store);
    const { dbConnections, dbConnectionsIsLoading, fetchDBConnectionOptions } = useDBConnectionOptions(store);
    useEffect(() => {
        fetchStatusOptions("execution", "status");
        fetchTestApproachOptions();
        fetchTestSuiteOptions();
        fetchTestCaseOptions();
        fetchUserOptions();
        fetchReleaseOptions();
        fetchRepoOptions();
        fetchDBConnectionOptions();
    }, []);

    const loadResults = () => setTimeout(() => getFullResults(filters, 1, 10), 500);
    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <Input
                allowClear
                value={exec_name}
                style={{ padding: 8, width: 300 }}
                placeholder={"Search execution name...."}
                onChange={(e: any) => {
                    setOptions(e.target.value, "execution_name");
                    loadResults();
                }}
            />
            <DropdownButtonFilter
                loading={false}
                options={execution_types.map((it: any) => ({ name: it, value: it }))}
                values={filters}
                searchLocal={true}
                buttonLabel={"Type"}
                keyOptions={"type"}
                setOptions={(e: any) => setOptions(e, "type")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={statusOptionsIsLoading}
                options={statusOptions.map((it: any) => ({ name: it, value: it }))}
                values={filters}
                searchLocal={true}
                buttonLabel={"Status"}
                keyOptions={"statuses"}
                setOptions={(e: any) => setOptions(e, "statuses")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={releasesIsLoading}
                options={releases.items.map((tag: any) => ({ name: tag.name, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={releases.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Release"}
                keyOptions={"release"}
                setOptions={(e: any) => setOptions(e, "release")}
                getOptions={fetchReleaseOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={dbConnectionsIsLoading}
                options={dbConnections.items.map((tag: any) => ({
                    name: tag.connection_name,
                    value: tag.db_config_id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={dbConnections.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"DB Config"}
                keyOptions={"db_connections"}
                setOptions={(e: any) => setOptions(e, "db_connections")}
                getOptions={fetchDBConnectionOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={reposIsLoading}
                options={repos.items.map((tag: any) => ({
                    name: tag.connection_name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={repos.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Git repositories"}
                keyOptions={"git_repo"}
                setOptions={(e: any) => setOptions(e, "git_repo")}
                getOptions={fetchRepoOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testSuitesIsLoading}
                options={testSuites.items.map((tag: any) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testSuites.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Test Suites"}
                keyOptions={"test_suites"}
                setOptions={(e: any) => setOptions(e, "test_suites")}
                getOptions={fetchTestSuiteOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testApproachesIsLoading}
                options={testApproaches.items.map((tag: any) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testApproaches.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Test Approaches"}
                keyOptions={"test_approaches"}
                setOptions={(e: any) => setOptions(e, "test_approaches")}
                getOptions={fetchTestApproachOptions}
                onSave={loadResults}
            />

            <DropdownButtonFilter
                loading={testCasesIsLoading}
                options={testCases.items.map((tag: any) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testCases.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Test Cases"}
                keyOptions={"test_cases"}
                setOptions={(e: any) => setOptions(e, "test_cases")}
                getOptions={fetchTestCaseOptions}
                onSave={loadResults}
            />

            <DropdownButtonFilter
                loading={usersIsLoading}
                options={users.items.map((tag: any) => ({ name: tag.username, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={users.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Created By"}
                keyOptions={"users"}
                setOptions={(e: any) => setOptions(e, "users")}
                getOptions={fetchUserOptions}
                onSave={loadResults}
            />
            <Button
                onClick={() => {
                    let current_val = null;
                    if (bugs_only?.length === 0) {
                        current_val = false;
                    } else if (bugs_only?.[0] === "0") {
                        current_val = false;
                    } else {
                        current_val = true;
                    }
                    setOptions(current_val ? ["0"] : ["1"], "bugs_only");
                    getFullResults([...filters, { bugs_only: current_val ? ["0"] : ["1"] }], 1, 10);
                }}
            >
                With Bugs Only
                {bugs_only?.length > 0 && bugs_only?.[0] !== "0" && <Badge count={"1"} />}
            </Button>
        </Space>
    );
});
