import { useState } from "react";
import { optionsSize } from "../config";

export const useTestDataParameterOptions = (store: any) => {
    const [testDataParameters, setTestDataParameters] = useState({ items: [], pageCount: 1 });
    const [testDataParametersIsLoading, setTestDataParametersIsLoading] = useState(false);

    const fetchTestDataParameterOptions = (optionFilters = {}, page = 1) => {
        if (!testDataParameters.items.length) setTestDataParametersIsLoading(true);

        store.testsStore
            .searchTestDataParameters({ search_value: optionFilters ? optionFilters?.["id"] : "", page, size: optionsSize })
            .then((data: any) => {
                if (!data) return;

                setTestDataParameters((prevTestDataParameters) => {
                    const prevIds = new Set(prevTestDataParameters.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTestDataParameters.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTestDataParametersIsLoading(false));
    };

    return { testDataParameters, testDataParametersIsLoading, fetchTestDataParameterOptions };
};
