import { message } from "antd";
import { action, observable } from "mobx";
import { ExecutionType, TEST_TYPE_TECHNOLOGY } from "../enums";
class ExecutionStore {
    store;
    quickTrigger = false;
    technology = observable([]);
    release = observable({});
    execution_name = observable("");
    test_type = observable("");
    separate_tags = observable(false);
    interval = null;
    notification = true;
    errors = {
        new: "",
        rerun: "",
        bug: "",
    };
    tags = observable([]);

    reset() {
        this.setTechnology([]);
        this.setRelease({});
        this.setTags([]);
    }

    constructor(store) {
        this.store = store;
    }
    setNotification = action((val) => (this.notification = val));

    setExecutionName = action((val) => (this.execution_name = val));

    setTags = action((val, separate_tags = false) => {
        this.tags = val;
        this.separate_tags = separate_tags;
    });

    setQuickTrigger = action((val) => (this.quickTrigger = val));

    setTestType = action((val) => {
        this.test_type = val;
    });

    setRelease = action((rel) => {
        this.release = rel;
    });

    setTechnology = action((tech) => {
        this.technology = tech;
    });

    setNewErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.errors.new = err[0].msg;
        } else {
            this.errors.new = err;
        }
    });

    setCancelErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.errors.cancel = err[0].msg;
        } else {
            this.errors.cancel = err;
        }
    });

    setNewBugErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.errors.bug = err[0].msg;
        } else {
            this.errors.bug = err;
        }
    });

    setNewRerunErrorMessage = action((err) => {
        if (err[0]?.msg) {
            this.errors.rerun = err[0].msg;
        } else {
            this.errors.rerun = err;
        }
    });

    checkDBFilter = (test_case, test_suite, test_approach, separate_tags) => {
        let filter = {};

        if (test_case?.length > 0) {
            filter["test_cases"] = test_case
                .filter(
                    (it) =>
                        it.test_type &&
                        [TEST_TYPE_TECHNOLOGY.ETL, TEST_TYPE_TECHNOLOGY.DQ, TEST_TYPE_TECHNOLOGY.RECON].includes(it.test_type)
                )
                .map((item) => item.id);
        } else if (test_approach?.length > 0) {
            filter["test_approaches"] = test_approach.map((item) => item.id);
        } 

        filter["test_suites"] = test_suite
            .filter(
                (it) =>
                    it.test_type && [TEST_TYPE_TECHNOLOGY.ETL, TEST_TYPE_TECHNOLOGY.DQ, TEST_TYPE_TECHNOLOGY.RECON].includes(it.test_type)
            )
            .map((item) => item.id);
        filter["db_id"] = this.store.dbExecutionStore.connection;
        filter["rec_db_id"] = this.store.dbExecutionStore.reconciliationConnection;
        filter["test_tags"] = this.store.executionStore.tags || [];
        filter["separate_tags"] = separate_tags;

        return filter;
    };

    checkWebFilter = (test_case, test_suite, test_approach, separate_tags) => {
        let filter = {};
        filter["test_suites"] = test_suite
            .filter((it) => it.test_type && it.test_type === TEST_TYPE_TECHNOLOGY.CUSTOM)
            .map((item) => item.id);
        if (test_case.length > 0) {
            filter["test_cases"] = test_case
                .filter((it) => it.test_type && it.test_type.includes(TEST_TYPE_TECHNOLOGY.CUSTOM))
                .map((item) => item.id);
        }
        filter["repo_id"] = this.store.webExecutionStore.repo;
        filter["test_tags"] = this.store.executionStore.tags;
        filter["separate_tags"] = separate_tags;
        return filter;
    };

    checkRuntimeVariables = (vars) => {
        let runtime_variables_payload = {};
        if (vars) {
            runtime_variables_payload = {
                variable_group: {
                    group_name: vars?.v_group.name,
                    persistent: vars?.v_group.persistent,
                    variables: vars?.variables.map((it) => {
                        return { variable_name: it.key, variable_value: it.value };
                    }),
                },
            };
        } else {
            if (this.store.dbExecutionStore.runtime_variables.length > 0) {
                runtime_variables_payload = {
                    variable_group: {
                        group_name: this.store.dbExecutionStore.v_group.persistent ? this.store.dbExecutionStore.v_group.name : null,
                        persistent: this.store.dbExecutionStore.v_group.persistent,
                        variables: this.store.dbExecutionStore.runtime_variables.map((it) => {
                            return { variable_name: it.key, variable_value: it.value };
                        }),
                    },
                };
            }
        }

        return runtime_variables_payload;
    };

    createNewExecution = action(({ test_case = [], test_suite = [], test_approach = [] }) => {
        let final_filters = [];
        if (this.technology.some((r) => [TEST_TYPE_TECHNOLOGY.ETL, TEST_TYPE_TECHNOLOGY.DQ, TEST_TYPE_TECHNOLOGY.RECON].includes(r))) {
            final_filters.push(this.checkDBFilter(test_case, test_suite, test_approach, this.separate_tags));
        }
        if (this.technology.includes("custom")) {
            final_filters.push(this.checkWebFilter(test_case, test_suite, test_approach, this.separate_tags));
        }
        let runtime_variables_payload = this.checkRuntimeVariables();
        let payload = {
            release_id: this.release,
            filters: final_filters,
            execution_name: this.execution_name,
            runtime_variables: runtime_variables_payload,
            notify: typeof this.notification == "boolean" ? this.notification : true,
        };

        if (Object.keys(payload.runtime_variables).length === 0) {
            delete payload["runtime_variables"];
        }
        return this.store.apiStore
            .post({
                url: "/tasks/execute",
                body: JSON.stringify(payload),
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.updateTaskUpdator(data.task_id, "execution");
                this.store.intervalStore.registerWatcher();
                this.store.intervalStore.pushNew({
                    task: data.task_id,
                    content: `EXECUTION Submit`,
                    status: "SUCCESS",
                    type: "EXECUTION",
                });
                this.reset();
                this.store.dbExecutionStore.reset();
                this.store.webExecutionStore.reset();
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewErrorMessage(err));
    });

    cancelExecution = action((execution_id) => {
        return this.store.apiStore
            .post({
                url: `/execution/cancel_execution/${execution_id}`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.["msg"]) {
                    message.success(data?.["msg"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => message.error(err));
    });

    getExecutionVariables = (exec_id) => {
        return this.store.apiStore
            .get({
                url: `/execution/${exec_id}/variables`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    };

    rerunExecution = (item_id, execution_type, only_failed = false, name = "") => {
        const runtimeVariables = this.store.dbExecutionStore.runtime_variables || [];

        this.setNewRerunErrorMessage("");
        // &name=${name.replaceAll(" ", "_")} is not supported in execution without varaibles for now
        let url = `/tasks/execute/rerun/${item_id}?failed_only=${only_failed}`;
        let payload = { ...execution_type === ExecutionType.DAG ? { ...(name && { name }), variables: {} } : {} };

        if (execution_type && execution_type !== ExecutionType.TECHNICAL && runtimeVariables.length > 0) {
            url = `/execution/rerun_with_variables/${item_id}?failed_only=${only_failed}`;

            if (execution_type === ExecutionType.TEST || execution_type === ExecutionType.DAG_SUBTASK) {
                payload = { ...(name && { name }), variables: {} };
                runtimeVariables.forEach((variable) => (payload["variables"][variable?.key] = variable?.value));
            } else if (execution_type === ExecutionType.DAG) {
                let exec_variables = {};
                runtimeVariables.forEach((item) => {
                    exec_variables = {};
                    item?.variables.forEach((variable) => (exec_variables[variable["key"]] = variable["value"]));
                    if (Object.values(exec_variables)?.length > 0) {
                        payload["variables"][item?.key] = {};
                        payload["variables"][item?.key] = { variables: exec_variables, user_defined_name: item?.user_defined_name };
                    }
                });
            }
        }

        return this.store.apiStore
            .post({
                url: url,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.dbExecutionStore.setRuntimeVariables([]);
                this.store.intervalStore.updateTaskUpdator(data.task_id, "rerun");
                this.store.intervalStore.registerWatcher();
                this.store.intervalStore.pushNew({
                    task: data.task_id,
                    content: `RERUN Submit`,
                    status: "SUCCESS",
                    type: "RERUN",
                    link: `/executions/${item_id}`,
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewRerunErrorMessage(err));
    };

    getConfigForTestType = (test_type) => {
        if ([TEST_TYPE_TECHNOLOGY.ETL, TEST_TYPE_TECHNOLOGY.DQ, TEST_TYPE_TECHNOLOGY.RECON].includes(test_type?.toLowerCase())) {
            return "db_config";
        } else if ([TEST_TYPE_TECHNOLOGY.CUSTOM].includes(test_type)) {
            return "git_repo";
        }
    };

    createBug = action(({ execution_id, form, tc, rlr_suite_id, rlr_test_id, report_urls }) => {
        let logs = tc.keywords.map((it) => it.logs?.filter((log) => log.log_level === "FAIL").map((log) => log.message));

        let full = "";
        tc.keywords.map((it) => {
            full = full !== "" ? `${full} <br/> ${it.keyword}` : `${it.keyword}`;
            if (it.arguments.length > 0) {
                let args = it.arguments.join("<br/>");
                full = `${full}: ${args}`;
            }
        });
        let start_time_results =
            logs[0] && logs[0].length > 0 ? tc.keywords[0]?.logs?.filter((log) => log.log_level === "FAIL")?.[0]?.timestamp : tc.start_time;
        let type = this.getConfigForTestType(tc.type);

        let finalBody = {
            execution_ids: [execution_id],
            test_case_name: form.test_case_name,
            description: form.test_case_description,
            tc_type: tc.type.toUpperCase(),
            test_approaches: form.test_case_approaches,
            test_suites: form.test_case_suites,
            expected_result: `${full}`,
            actual_result: `Test failed <br/> ${logs[0] && logs[0].length > 0 ? `Reason: ${logs}` : "No logs found"}`,
            start_test_result_datetime: start_time_results,
            connection_details: tc.env?.[type]?.[0] ?? "No data",
            robot_report_urls: report_urls,
            requirements: form.requirements,
            robot_live_results_suite_id: rlr_suite_id,
            robot_live_results_test_case_id: rlr_test_id,
        };
        return this.store.apiStore
            .post({
                url: `/requirements/create_bug_for_execution`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(finalBody),
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.["msg"]) {
                    let data_list = data["msg"].split("URL:");
                    let full_msg = (
                        <>
                            {data_list[0]}{" "}
                            <a className="primary_link" target="_blank" rel="noopener noreferrer" href={data_list[1]}>
                                See in external system
                            </a>
                        </>
                    );
                    message.success({ content: full_msg, duration: 5, key: "bugs" });
                    this.store.intervalStore.pushNew({
                        task: (
                            <a className="primary_link" target="_blank" rel="noopener noreferrer" href={data_list[1]}>
                                See in external system
                            </a>
                        ),
                        content: `BUG Created!`,
                        status: "SUCCESS",
                        type: "BUG",
                        link: `executions/${execution_id}`,
                    });
                } else if (data?.["error"]) {
                    this.setNewBugErrorMessage(data?.["error"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewBugErrorMessage(err));
    });

    createExternalBug = action(({ execution_id, external_bugs_work_ids, rlr_suite_id, rlr_test_id }) => {
        let finalBody = {
            execution_ids: [execution_id],
            bug_work_item_ids: external_bugs_work_ids,
            robot_live_results_suite_id: rlr_suite_id,
            robot_live_results_test_case_id: rlr_test_id,
        };
        return this.store.apiStore
            .post({
                url: `/requirements/link_external_bug_to_execution`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(finalBody),
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.["msg"]) {
                    message.success({ content: data?.["msg"], duration: 5, key: "bugs" });
                    this.store.intervalStore.pushNew({
                        content: `BUG Link Created!`,
                        status: "SUCCESS",
                        type: "BUG",
                        link: `executions/${execution_id}`,
                    });
                } else if (data?.["error"]) {
                    this.setNewBugErrorMessage(data?.["error"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewBugErrorMessage(err));
    });

    getTechnologiesByExecutionItem = (executionItem) => {
        let technologies = [];

        const { filters = [] } = executionItem.execution_params.args;
        filters.forEach((item) => {
            if (item.db_id) {
                technologies.push(TEST_TYPE_TECHNOLOGY.ETL);
            }
            if (item.rec_db_id) {
                technologies.push(TEST_TYPE_TECHNOLOGY.RECON);
            }
            if (item.repo_id) {
                technologies.push(TEST_TYPE_TECHNOLOGY.CUSTOM);
            }
        });

        return technologies;
    };
}
export default ExecutionStore;
