import { Checkbox, Col, Empty, List, Radio, Space, Spin } from "antd";
import React from "react";
import { SelectOption } from "../../../../view/shared/SelectOption";
import { InfiniteScrollWrapper } from "../infiniteScrollWrapper";

interface Props {
    options: any[];
    selectedOptions: string[];
    loading: boolean;
    keyOptions: string;
    pageCount: number;
    setOptions: (e: string[], value: string) => void;
    onChange?: (value: any) => void;
    onLoadPage?: (page: number) => void;
    isRadio?: boolean;
}

export const FilterOptions: React.FC<Props> = ({
    options,
    selectedOptions,
    loading,
    keyOptions,
    pageCount,
    setOptions,
    onChange,
    onLoadPage,
    isRadio = false,
}) => {
    return (
        <InfiniteScrollWrapper
            items={options}
            pageCount={pageCount}
            isLoading={loading}
            height="auto"
            onLoadPage={onLoadPage}
            style={{ maxHeight: 300, minHeight: 50 }}
        >
            {options?.length > 0 ? (
                <>
                    {isRadio ? (
                        <Radio.Group
                            id={`radio-${keyOptions}-group`}
                            onChange={(e) => {
                                setOptions([e.target.value], keyOptions);
                            }}
                            value={selectedOptions?.[0]}
                        >
                            <Space direction="vertical" wrap>
                                {options.map((item: any, index) => (
                                    <Radio
                                        key={`radio-${keyOptions}-option-${index}`}
                                        id={`radio-${keyOptions}-option-${item.name}`}
                                        value={item.value}
                                    >
                                        {item.name}
                                    </Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    ) : (
                        <Checkbox.Group value={selectedOptions} onChange={onChange}>
                            <Spin spinning={loading}>
                                <List>
                                    {options?.map((item: any, index) => (
                                        <Col key={`${item.value}-col-${index}`} style={{ marginTop: 7, marginBottom: 7 }}>
                                            <Checkbox
                                                id={`checkbox-${keyOptions}-option-${item.name}`}
                                                key={`${item.value}-check-${item?.key ?? "key"}`}
                                                onChange={(e) => {
                                                    let id = e.target.value;
                                                    let checkedList = [...selectedOptions];
                                                    const result = checkedList.includes(id)
                                                        ? checkedList.filter((x) => x !== id)
                                                        : [...checkedList, id];
                                                    setOptions(result, keyOptions);
                                                }}
                                                value={item.value}
                                            >
                                                {item.friendly_id ? <SelectOption name={item.name} id={item?.friendly_id} /> : item.name}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </List>
                            </Spin>
                        </Checkbox.Group>
                    )}
                </>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </InfiniteScrollWrapper>
    );
};
