import { observable } from "mobx";

class DefaultStore {
    store;

    defaults = observable([]);

    error = {
        create: "",
        get: "",
    };

    constructor(store) {
        this.store = store;
    }

    setDefaults = (id, data) => {
        this.defaults[id] = data;
    };

    getCategory = (item) => {
        if (item === "profiling") {
            return "profiling";
        } else if (item === "dq_check") {
            return "dq_check";
        } else if (item === "metadata") {
            return "metadata";
        }
    };

    setDefaultError = (err, key) => {
        this.error[key] = err;
    };

    getTable = () => {
        return "DB";
    };

    createDefaultValue = (id, table, category, value) => {
        return this.store.apiStore
            .post({
                url: "/defaults/",

                body: JSON.stringify({
                    foreign_id: id,
                    foreign_table: this.getTable(),
                    category: this.getCategory(category),
                    value: value,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.store.intervalStore.pushNew({
                    task: `CATEGORY: ${category.toUpperCase()}`,
                    content: `New Default Value`,
                    status: "SUCCESS",
                    type: "DEFAULT",
                    link: "/settings/connections",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDefaultError(err, "create"));
    };

    getDefaults = (table, category, db_config_id) => {
        return this.store.apiStore
            .get({
                url: `/defaults?table=${table}&category=${category}&foreign_id=${db_config_id}`,

                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setDefaults(db_config_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDefaultError(err, "get"));
    };
}
export default DefaultStore;
