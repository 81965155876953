import { message } from "antd";
import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class TestCaseStore extends PaginationStore {
    store;

    test_cases_by_suite_id = observable({});
    test_cases_by_approach_id = observable({});
    testsCases = observable([]);
    errors = {
        case: {
            upload: "",
            template: "",
            new: "",
            edit: "",
            delete: "",
        },
    };

    requirements_error = {};
    info = {
        case: {
            new: "",
        },
    };
    created_case = observable([]);

    cases_to_download = [];

    itemsToDownload = observable([]);

    constructor(store) {
        super();
        this.store = store;
    }

    @action reset() {
        this.errors = {
            case: {
                new: "",
            },
        };
        this.info = {
            case: {
                new: "",
            },
        };
        this.test_cases_by_suite_id = {};
        this.test_cases_by_approach_id = {};
    }

    setItemsToDownload = action((cases) => {
        this.itemsToDownload = cases;
    });

    setTestCasesByApproachId = action((approach_id, cases) => {
        this.test_cases_by_approach_id[approach_id] = cases;
    });

    setTestCasesBySuiteId = action((suite_id, cases) => {
        this.test_cases_by_suite_id[suite_id] = cases;
    });

    setTestCases = action((cases) => {
        this.testsCases = cases;
    });

    setTestCase = action((case_id, data) => {
        let case_objs = this.testsCases.filter((it) => it.id === case_id);
        if (case_objs.length > 0) {
            case_objs[0]["keyword"] = data["keyword"];
        }
    });

    setTestCasesForDownload = action((cases) => {
        this.cases_to_download = cases;
    });

    setRequirementsForTestCase = action((case_id, data) => {
        let case_objs = this.testsCases.filter((it) => it.id === case_id);
        if (case_objs.length > 0) {
            case_objs[0]["requirements"] = data;
        }
    });

    setExecutionsHistoryForTestCase = action((case_id, data) => {
        let case_objs = this.testsCases.filter((it) => it.id === case_id);
        if (case_objs.length > 0) {
            case_objs[0]["live_execution_history"] = data;
        }
    });

    setNewTestCase = action((data) => {
        this.created_case = data;
    });

    setRequirementsError = action((test_case_id, data) => {
        this.requirements_error[test_case_id] = data;
    });

    setNewUploadCaseErrorMessage = action((error) => {
        this.errors.case.upload = error;
    });

    setNewTemplateCaseErrorMessage = action((error) => {
        this.errors.case.template = error;
    });

    setNewCaseErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.case.new = error[0].msg;
        } else {
            this.errors.case.new = error;
        }
    });

    setDeleteCaseErrorMessage = action((error) => {
        this.errors.case.delete = error;
    });

    setEditCaseErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.case.edit = error[0].msg;
        } else {
            this.errors.case.edit = error;
        }
    });

    getAllTestsCases(page = 1, size = 50, returned = false) {
        let url = `/test_case/?page=${page}&size=${size}`;
        return this.store.apiStore
            .get({
                url: url,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) {
                    return data?.items;
                }
                this.setTestCases(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((e) => {
                Promise.reject("Unable to get tests-cases");
            });
    }

    getTestCase = (case_id) => {
        return this.store.apiStore
            .get({
                url: `/test_case/${case_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestCase(case_id, data);
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get tests-cases");
            });
    };

    createTestCase = ({ finalItem, refreshed = true }) => {
        return this.store.apiStore
            .post({
                url: "/test_case/",
                body: JSON.stringify(finalItem),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (refreshed) {
                    this.setNewTestCase(data);
                    return this.store.testsStore.searchTestCaseData({
                        page: this.page,
                        filters: { name: [""] },
                        pagination: true,
                    });
                }
                if (data) this.setNewTestCase(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewCaseErrorMessage(err));
    };

    deleteTestCase(test_case_id) {
        return this.store.apiStore
            .delete({
                url: `/test_case/${test_case_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteCaseErrorMessage(err));
    }

    editTestCase(case_id, item = null, status = null) {
        return this.store.apiStore
            .put({
                url: `/test_case/${case_id}`,
                body: item
                    ? JSON.stringify({
                          name: item.name,
                          description: item.description,
                          test_suite_ids: item.test_suite_ids,
                          test_approach_ids: item.test_approach_ids,
                          requirements_ids: item.requirements_ids,
                          is_active: item.is_active,
                          tags: item.tags,
                          keywords: item.keywords,
                      })
                    : JSON.stringify({
                          is_active: status,
                      }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => {
                return err?.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0]));
            })
            .catch((err) => this.setEditCaseErrorMessage(err));
    }

    getTestCasesByApproach = ({ approach_id, page = 1, size = 50 }) => {
        return this.store.apiStore
            .get({
                url: `/test_case/test_cases_by_test_approach_id/${approach_id}?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (page === 1) {
                    this.setTestCasesByApproachId(approach_id, data["items"]);
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                } else {
                    this.setTestCasesByApproachId(approach_id, this.test_cases_by_approach_id[approach_id].concat(data["items"]));
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                }
            })
            .catch((e) => {
                Promise.reject("Unable to get test cases by approach");
            });
    };

    getTestCasesByTestSuiteId = ({ suite_id, page = 1, size = 50 } = {}) => {
        return this.store.apiStore
            .get({
                url: `/test_case/test_cases_by_test_suite_id/${suite_id}?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (page === 1) {
                    this.setTestCasesBySuiteId(suite_id, data["items"]);
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                } else {
                    this.setTestCasesBySuiteId(suite_id, this.test_cases_by_suite_id[suite_id].concat(data["items"]));
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                }
            })
            .catch((e) => {
                Promise.reject("Unable to get tests-cases with test-suites");
            });
    };

    getRequirementsByTestCaseId = (test_case_id) => {
        return this.store.apiStore
            .get({
                url: `/requirements/test_case/${test_case_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setRequirementsForTestCase(test_case_id, data);
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setRequirementsForTestCase(test_case_id, []);
                this.setRequirementsError(test_case_id, err);
                return [];
            });
    };

    getHistoryExecutionsForTests = action((test_case_id, name) => {
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    metric_name: "test_result_live_results_for_history",
                    metric_params: {
                        name: name,
                    },
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setExecutionsHistoryForTestCase(test_case_id, data);
                return data;
            })
            .catch((e) => {
                this.setExecutionsHistoryForTestCase([]);
                Promise.reject("Unable to get total executions");
            });
    });

    bulkEditExcelTestCase(file) {
        return this.store.apiStore
            .post({
                url: `/test_case/bulk_excel`,
                body: file,
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => {
                return response.status !== 200
                    ? this.store.testsStore.searchTestCaseData({
                          filters: { name: [""] },
                          pagination: true,
                      })
                    : response.json();
            })
            .catch((err) => Promise.reject(err))
            .catch((err) => this.setNewUploadCaseErrorMessage(err));
    }

    getBulkTemplate = () => {
        let tc = this.itemsToDownload.map((it) => it.id);
        return this.store.apiStore
            .post({
                url: `/test_case/bulk_excel/template`,
                body: JSON.stringify(tc),
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setItemsToDownload([]);

                return !data
                    ? this.store.testsStore.searchTestSuiteData({
                          filters: { name: [""] },
                          pagination: true,
                      })
                    : data;
            })
            .catch((e) => {
                this.setNewTemplateCaseErrorMessage(e);
                message.error("Unable to get excel template. Try again later.");
            });
    };

    getExecutionsByTestCaseId = ({ testCaseId, page = 1, size = 50 }) => {
        return this.store.apiStore
            .get({
                url: `/execution/?page=${page}&size=${size}&test_case_ids=${testCaseId}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch((error) => {
                error.json().then((data) => {
                    Promise.reject(data?.detail || `Unable to get executions by Test Case ID - ${testCaseId}`);
                });
            });
    };
}
export default TestCaseStore;
