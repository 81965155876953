import { useState } from "react";
import { optionsSize } from "../config";

export const useUserOptions = (store: any) => {
    const [users, setUsers] = useState({ items: [], pageCount: 1 });
    const [usersIsLoading, setUsersIsLoading] = useState(false);

    const fetchUserOptions = (optionFilters = {}, page = 1) => {
        if (!users.items.length) setUsersIsLoading(true);

        store.usersStore
            .searchUsers({ filters: optionFilters, type: "or", pagination: true, page })
            .then((data) => {
                if (!data?.items?.length) return;

                setUsers((prevUsers) => {
                    const prevIds = new Set(prevUsers.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevUsers.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setUsersIsLoading(false));
    };

    return { users, usersIsLoading, fetchUserOptions };
};
