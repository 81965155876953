import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class RulesStore extends PaginationStore {
    store;

    rules = observable([]);
    filteredRule = observable({});
    fitleredRuleError = "";

    rule = observable({
        template: "",
        db_config_id: "",
        name: "",
        approaches: [],
        description: "",
    });

    errors = {
        new: "",
        delete: "",
        edit: "",
        warning: "",
    };

    constructor(store) {
        super();
        this.store = store;
    }

    @action reset() {
        this.rules = observable([]);
        this.filteredRule = observable({});
        this.fitleredRuleError = "";
        this.rule = observable({
            template: "",
            db_config_id: "",
            name: "",
            approaches: [],
            description: "",
        });
        this.errors = {
            new: "",
            delete: "",
            edit: "",
        };
    }

    setEditRuleWarningMessage = action((err) => {
        this.errors.warning = err;
    });

    setEditRuleErrorMessage = action((err) => {
        this.errors.edit = err;
    });

    setNewRuleErrorMessage = action((err) => {
        this.errors.new = err;
    });

    setDeleteRuleErrorMessage = action((err) => {
        this.errors.delete = err;
    });

    setDbConfigRule = action((config) => {
        this.rule.db_config_id = config;
    });

    setTemplateRule = action((template) => {
        this.rule.template = template;
    });

    setApproachesRule = action((approaches) => {
        this.rule.approaches = approaches;
    });

    setNameRule = action((name) => {
        this.rule.name = name;
    });

    setDescriptionRule = action((description) => {
        this.rule.description = description;
    });

    setRules = action((rules) => {
        this.rules = rules;
    });

    setFilteredRule = action((rule) => {
        this.filteredRule = rule;
    });

    setFilteredRuleError = action((err) => {
        this.fitleredRuleError = err;
    });

    getAllRules(page = 1, size = 50) {
        return this.store.apiStore
            .get({
                url: `/rule/?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setRules(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((e) => {
                Promise.reject("Unable to get rules");
            });
    }

    getRuleByFilterCondition(rule_condition) {
        return this.store.apiStore
            .post({
                url: "/rule/by_filter_condition",
                body: JSON.stringify({
                    condition: rule_condition,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setFilteredRule(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setFilteredRuleError(err));
    }

    getConnectionTypeForApi(connection_type) {
        if (connection_type?.toUpperCase() === "MYSQL") {
            return "my_sql";
        } else if (connection_type?.toUpperCase() === "MSSQL") {
            return "ms_sql";
        } else if (connection_type?.toUpperCase() === "POSTGRESQL") {
            return "postgre_sql";
        } else if (connection_type?.toUpperCase() === "REDSHIFT") {
            return "redshift";
        } else if (connection_type?.toUpperCase() === "SNOWFLAKE") {
            return "snowflake";
        } else if (connection_type?.toUpperCase() === "DATABRICKS") {
            return "databricks";
        } else {
            return "general";
        }
    }

    createRule() {
        let body = {
            rule_name: this.rule.name,
            rule_condition: this.rule.template,
            test_approach_ids: this.rule.approaches,
            rule_parameter: this.store.testsStore.sqlQueryMetadata.values.length,
            description: this.rule.description,
        };
        if (this.rule.db_config_id !== "") {
            let connection = this.store.settingsStore.databases.filter((item) => item.db_config_id === this.rule.db_config_id);
            if (connection.length > 0) {
                body["sql_dialect"] = this.getConnectionTypeForApi(connection[0]["db_type"]);
            }
        }
        return this.store.apiStore
            .post({
                url: "/rule/",
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getRuleByFilterCondition(this.rule.template);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewRuleErrorMessage(err));
    }

    editRule(rule_id, status = null, item = null) {
        return this.store.apiStore
            .put({
                url: `/rule/${rule_id}`,
                body: item
                    ? JSON.stringify(item)
                    : JSON.stringify({
                          is_active: status,
                      }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setEditRuleErrorMessage(err));
    }

    deleteRule(rule_id) {
        return this.store.apiStore
            .delete({
                url: `/rule/${rule_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getAllRules(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteRuleErrorMessage(err));
    }

    combineTableForSearch(filters) {
        let tables = [];
        tables.push({
            table_name: "rule",
        });
        return tables;
    }

    combineFiltersForSearch(filters) {
        let filtersReady = [];
        Object.entries(filters).map((key, value) => {
            let items = [];
            if (key[0] === "name") {
                key[1].map((val) => {
                    items.push({
                        column: "rule.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "is_active") {
                key[1].map((val) => {
                    items.push({
                        column: "rule.is_active",
                        is_value_column: false,
                        search_value: `${val !== "0" ? "1" : "0"}`,
                        search_type: "eq",
                    });
                });
            }
            filtersReady.push({
                logical_opperand: "and",
                items: items,
            });
        });
        return filtersReady;
    }

    searchRules = ({ filters, pagination = false, page = 1, size = 50 }) => {
        let filtersToSend = this.combineFiltersForSearch(filters);
        let tables = this.combineTableForSearch(filters);
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: true, page: page, size: size }),
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (pagination) {
                        this.setRules(data["items"]);
                        this.setTotal(data.total);
                        this.setPage(data["page"]);
                        this.setSize(data.size);
                        this.setPagination(true);
                    } else {
                        this.setRules(data);
                        this.setTotal(data.length);
                        this.setSize(data.length);
                        this.setPagination(false);
                    }
                } else {
                    this.setRules([]);
                    this.setTotal(0);
                    this.setSize(0);
                    this.setPagination(false);
                }
            })
            .catch((err) => {
                this.setRules([]);
                this.setTotal(0);
                this.setSize(0);
                this.setPagination(false);
            });
        // .catch(err => this.setNewConnectionError(err))
    };
}

export default RulesStore;
