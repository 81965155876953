import { lazy } from "react";

const SuperUserPagesRoutes = [
    {
        path: "/settings/users",
        component: lazy(() => import("../../view/pages/settings/users/users")),
        layout: "VerticalLayout",
    },
    {
        path: "/settings/configurations",
        component: lazy(() => import("../../view/pages/settings/configurations/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/settings/notifications",
        component: lazy(() => import("../../view/pages/settings/notifications/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/settings",
        component: lazy(() => import("../../view/pages/settings/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/variables/results",
        component: lazy(() => import("../../view/pages/executions/variables/index")),
        layout: "VerticalLayout",
    },
];

export default SuperUserPagesRoutes;
