import { Button, Col, Layout, Row } from "antd";
import { RiMenuFill } from "react-icons/ri";
import { ExecutionsCalendarComponent } from "./ExecutionsCalendarComponent";
import HeaderSearch from "./HeaderSearch";
import HeaderUser from "./HeaderUser";
import dayjs from "dayjs";

const { Header } = Layout;
const date = dayjs();
const month = date.format("MMMM");
const date_amount = date.date();

export default function MenuHeader(props) {
    const { setVisible } = props;
    // Mobile Sidebar
    const showDrawer = () => {
        setVisible(true);
    };

    return (
        <Header>
            <Row className="da-w-100 da-position-relative" align="middle" justify="space-between">
                <Col className="da-mobile-sidebar-button da-mr-24">
                    <Button
                        className="da-mobile-sidebar-button"
                        type="text"
                        onClick={showDrawer}
                        icon={<RiMenuFill size={24} className="remix-icon da-text-color-black-80" />}
                    />
                </Col>

                <Col md={24} span={24} className="da-header-left-text da-d-flex-center">
                    <Col md={8} span={24} style={{ display: "flex" }}>
                        <HeaderSearch />
                    </Col>
                    <Col md={8} span={24} className="text-container da-header-center-text da-d-flex-center">
                        <ExecutionsCalendarComponent />
                        <span className="da-font-weight-300 today-text">Today</span>
                        <span className="da-header-left-text-item da-input-label da-text-color-black-100" id="today-date">
                            {month} {date_amount}
                        </span>
                    </Col>
                    <Col md={8} span={24}>
                        <HeaderUser />
                    </Col>
                </Col>
            </Row>
        </Header>
    );
}
