import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class DbExecutionStore extends PaginationStore {
    store;

    suites = observable([]);
    cases = observable([]);
    approaches = observable([]);
    connection = null;
    reconciliationConnection = null;
    runtime_variables = observable([]);
    v_group = observable({
        name: null,
        persistent: false,
    });
    errors = {
        new: "",
    };

    filteredApproaches = observable([]);
    filteredTestCases = observable([]);

    constructor(store) {
        super();
        this.store = store;
    }

    reset = () => {
        this.suites = [];
        this.cases = [];
        this.approaches = [];
        this.filteredApproaches = [];
        this.filteredTestCases = [];
        this.setConnection(null);
        this.setReconciliationConnection(null);
        this.setRuntimeVariables([]);
        this.setGroupVariables({});
    };

    setGroupVariables = action((caseObjs) => {
        this.v_group = caseObjs;
    });

    setRuntimeVariables = action((caseObjs) => {
        this.runtime_variables = caseObjs;
    });

    setTestCases = action((caseObjs) => {
        this.cases = caseObjs;
    });

    pushCases = action((cas) => {
        this.cases.push(cas);
    });

    deleteCases = action((cas) => {
        let index = this.cases.findIndex((caseI) => caseI.id === cas.id);
        this.cases.splice(index, 1);
    });

    setConnection = action((conn) => {
        this.connection = conn;
    });

    setReconciliationConnection = action((conn) => {
        this.reconciliationConnection = conn;
    });

    setApproaches = action((name) => {
        this.approaches = name;
    });

    setSuites = action((suite) => {
        this.suites = suite;
    });

    pushSuites = action((suite) => {
        this.suites.push(suite);
    });

    deleteSuites = action((suite) => {
        let index = this.suites.findIndex((sui) => sui.id === suite.id);
        this.suites.splice(index, 1);
    });

    setfilteredApproaches = action((approach) => {
        this.filteredApproaches = approach;
    });

    pushApproaches = action((approach) => {
        this.approaches.push(approach);
    });

    deleteApproaches = action((approach) => {
        let index = this.approaches.findIndex((app) => app.id === approach.id);
        this.approaches.splice(index, 1);
    });

    setfilteredTestCases = action((cases) => {
        this.filteredTestCases = cases;
    });

    getFilteredApproaches({ page = 1, size = 50, pre_suites = [], name = [] }) {
        let body = {
            test_suites: pre_suites.map((suite) => suite.id),
        };
        if (name?.length > 0) {
            body["name"] = name?.[0];
            return this.store.apiStore
                .post({
                    url: `/search/?pagination=true&distinct=true&size=${size}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                    body: JSON.stringify({
                        search: [
                            { table_name: "test_approach" },
                            {
                                table_name: "test_case",
                                parent: "test_approach",
                            },
                            {
                                table_name: "test_suite",
                                parent: "test_case",
                            },
                        ],
                        filters: [
                            {
                                items: [
                                    { column: "test_suite.id", is_value_column: false, search_value: body?.test_suites, search_type: "in" },
                                    {
                                        column: "test_approach.name",
                                        is_value_column: false,
                                        search_value: `%${body?.name}%`,
                                        search_type: "like",
                                    },
                                ],
                                logical_opperand: "and",
                            },
                        ],
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.setfilteredApproaches(data?.items || []);
                    this.setPage(data?.page || 1);
                    this.setTotal(data?.total || 0);
                    this.setSize(data?.size || 50);
                    this.setPagination(true);
                });
        }
        return this.store.apiStore
            .post({
                url: `/tasks/filter?page=${page}&size=${size}`,
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setfilteredApproaches(data?.items || []);
                this.setPage(data?.page || 1);
                this.setTotal(data?.total || 0);
                this.setSize(data?.size || 50);
                this.setPagination(true);
            })
            .catch((err) =>
                err.json().then((data) => {
                    this.setfilteredApproaches([]);
                    Promise.reject(data?.detail);
                })
            )
            .catch(() => {
                this.setfilteredApproaches([]);
                this.setPage(1);
                this.setTotal(0);
                this.setSize(50);
                this.setPagination(true);
            });
    }

    getFilteredTestCases = ({ page = 1, size = 50, approaches = [], suites = [], tc_name = null }) => {
        let body = {
            test_suites: suites.map((suite) => suite.id),
            test_approaches: approaches.map((app) => app.id),
        };
        if (tc_name?.length > 0) {
            body["name"] = tc_name?.[0];
            let payload_items = [];
            let payload_tables = [];
            if (body?.test_suites?.length > 0) {
                payload_tables.push({
                    table_name: "test_suite",
                    parent: "test_case",
                });
                payload_items.push({ column: "test_suite.id", is_value_column: false, search_value: body?.test_suites, search_type: "in" });
            }
            if (body?.test_approaches?.length > 0) {
                payload_tables.push({
                    table_name: "test_approach",
                    parent: "test_case",
                });
                payload_items.push({
                    column: "test_approach.id",
                    is_value_column: false,
                    search_value: body?.test_approaches,
                    search_type: "in",
                });
            }
            return this.store.apiStore
                .post({
                    url: `/search/?pagination=true&distinct=true&size=${size}`,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                    body: JSON.stringify({
                        search: [{ table_name: "test_case" }, ...payload_tables],
                        filters: [
                            {
                                items: [
                                    ...payload_items,
                                    {
                                        column: "test_case.name",
                                        is_value_column: false,
                                        search_value: `%${body?.name}%`,
                                        search_type: "like",
                                    },
                                ],
                                logical_opperand: "and",
                            },
                        ],
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    this.setfilteredTestCases(data?.items || []);
                    this.setPage(data?.page || 1);
                    this.setTotal(data?.total || 0);
                    this.setSize(data?.size || 50);
                    this.setPagination(true);
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => {
                    this.setfilteredTestCases([]);
                    this.setPage(1);
                    this.setTotal(0);
                    this.setSize(50);
                    this.setPagination(true);
                });
        }

        return this.store.apiStore
            .post({
                url: `/tasks/filter?page=${page}&size=${size}`,
                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setfilteredTestCases(data?.items || []);
                this.setPage(data?.page || 1);
                this.setTotal(data?.total || 0);
                this.setSize(data?.size || 50);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setfilteredTestCases([]);
                this.setPage(1);
                this.setTotal(0);
                this.setSize(50);
                this.setPagination(true);
            });
    };

    combineTableForSearch(table) {
        let tables = [];
        if (table === "approach") {
            tables.push({
                table_name: "test_approach",
            });
        } else if (table === "case") {
            tables.push({
                table_name: "test_case",
            });
        }
        return tables;
    }

    combineFiltersForSearch(filters, table) {
        let filtersReady = [];
        Object.entries(filters).forEach((key, value) => {
            let items = [];
            if (key[0] === "name" && table === "approach") {
                key[1].forEach((val) => {
                    items.push({
                        column: "test_approach.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            }
            if (key[0] === "name" && table === "case") {
                key[1].forEach((val) => {
                    items.push({
                        column: "test_case.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            }
            filtersReady.push({
                logical_opperand: "and",
                items: items,
            });
        });
        return filtersReady;
    }

    searchExecutionTests(filters, type) {
        let filtersToSend = this.combineFiltersForSearch(filters, type);
        let tables = this.combineTableForSearch(type);
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && type === "approach") {
                    this.setfilteredApproaches(data);
                    this.setTotal(data.length);
                    this.setSize(data.length);
                    this.setPagination(false);
                } else if (data && type === "case") {
                    this.setfilteredTestCases(data);
                    this.setTotal(data.length);
                    this.setSize(data.length);
                    this.setPagination(false);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.store.searchStore.setError(err));
    }

    getFilteredTestCasesWithoutApproaches = ({ page = 1, size = 50, suites = [], tc_name = null }) => {
        let body_filters = [
            {
                column: "test_suite.id",
                is_value_column: false,
                search_value: suites.map((val) => val.id),
                search_type: "in",
            },
        ];
        if (tc_name?.length > 0) {
            body_filters.push({
                column: "test_case.name",
                is_value_column: false,
                search_value: `%${tc_name}%`,
                search_type: "like",
            });
        }
        return this.store.apiStore
            .post({
                url: `/search/?pagination=true&page=${page}&size=${size}`,
                body: JSON.stringify({
                    search: [
                        {
                            table_name: "test_case",
                        },
                        {
                            table_name: "test_suite",
                            parent: "test_case",
                        },
                    ],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: body_filters,
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setfilteredTestCases(data?.items || []);
                this.setPage(data?.page || 1);
                this.setTotal(data?.total || 0);
                this.setSize(data?.size || 50);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.store.searchStore.setError(err));
    };
}

export default DbExecutionStore;
