import { Provider } from "mobx-react";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { GitReposConflictModalProvider } from "./layout/components/content/gitReposConflictModal";
import { InactiveTestResourcesModalProvider } from "./layout/components/content/warningTestResourcesModal/inactiveTestResourcesModal";
import Router from "./router/Router";
import { StoreProvider } from "./stores/StoreContext";
import AppStore from "./stores/appStore";
import { ThemeProvider } from "./themeProvider";

const store = new AppStore();

export default function App() {
    return (
        <Suspense fallback="loading">
            <Provider store={store}>
                <StoreProvider store={store}>
                    <ThemeProvider>
                        <GitReposConflictModalProvider>
                            <InactiveTestResourcesModalProvider>
                                <BrowserRouter store={store}>
                                    <Router />
                                </BrowserRouter>
                            </InactiveTestResourcesModalProvider>
                        </GitReposConflictModalProvider>
                    </ThemeProvider>
                </StoreProvider>
            </Provider>
        </Suspense>
    );
}
