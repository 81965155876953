import { action, observable } from "mobx";
class DefectStore {
    store;

    constructor(store) {
        this.store = store;
    }

    defect_types_allowed = observable([]);

    error = {
        new: "",
    };

    setNewError = (data) => {
        this.error.new = data;
    };

    setDefaultTypesAllowed = action((data) => {
        this.defect_types_allowed = data;
    });

    getDefaultTypesAllowed = action(() => {
        return this.store.apiStore
            .get({
                url: `/defect_types_accepted/allowed_defect_types`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) this.setDefaultTypesAllowed(data);
            })
            .catch((e) => {
                this.setDefaultTypesAllowed([]);
                Promise.reject("Unable to get defect types allowed");
            });
    });

    getDefectTypesForLogMessage = action((log_message_id) => {
        return this.store.apiStore
            .get({
                url: `/defect_types_accepted/${log_message_id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) return data;
            });
    });

    getDefectTypesPredictedForLogMessage = action((log_message_id) => {
        return this.store.apiStore
            .get({
                url: `/defect_types_predicted/${log_message_id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) return data;
            });
    });

    createDefectTypesForLogMessage = action((defect_types, log_message_id, execution_id) => {
        this.setNewError("");
        return this.store.apiStore
            .post({
                url: `/defect_types_accepted/`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ defect_types: defect_types, log_message_id: log_message_id }),
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => {
                this.store.intervalStore.pushNew({
                    task: defect_types.join(" "),
                    content: `New Defect Type - created! `,
                    status: "SUCCESS",
                    type: "BUG",
                    link: `executions/${execution_id}`,
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewError(err));
    });

    updateDefectTypesForLogMessage = action((defect_types, log_message_id, execution_id) => {
        this.setNewError("");
        return this.store.apiStore
            .put({
                url: `/defect_types_accepted/${log_message_id}`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ defect_types: defect_types }),
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => {
                this.store.intervalStore.pushNew({
                    task: defect_types.join(" "),
                    content: `New Defect Type - updated! `,
                    status: "SUCCESS",
                    type: "BUG",
                    link: `executions/${execution_id}`,
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewError(err));
    });
}
export default DefectStore;
