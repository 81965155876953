const darkConfig = {
    colorPrimary: "#ccac5c",
    colorPrimaryBg: "#33322e",
    colorPrimaryBgHover: "#141414",
    colorSuccess: "#18342a",
    colorSuccessBg: "#cedfd9",
    colorWarning: "#ffc212",
    colorError: "#c65664",
    colorInfo: "#949494",
    colorTextBase: "#f4eedb",
    colorTextSecondary: "#636e72",
    borderRadius: 8,
    wireframe: false,
    type: "dark",
    fontFamily: "Manrope,sans-serif",
    colorTableHover: "#f4f2eb",
    colorTagPrimaryBg: "#909193",
    colorTagTextPrimary: "#fffff",
    colorTextLightSolid: "#141414",
};

export default darkConfig;
