import { Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";
import { useStore } from "../../../../../stores/StoreContext";
import { useDebouncedValue } from "../../../../../hooks";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { TestCase } from "../../../../../view/types/testCaseType";
import { TEST_TYPE_TECHNOLOGY } from "../../../../../enums";
import { useGitRepoOptions, useTestSuiteOptions } from "../hooks";

export const TestSuitesFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let execName = findFilter(filters, "name")[0] || "";

    const store = useStore();
    const { testSuites, testSuitesIsLoading, fetchTestSuiteOptions } = useTestSuiteOptions(store);
    const { repos, reposIsLoading, fetchRepoOptions } = useGitRepoOptions(store);
    const debouncedExecName = useDebouncedValue(execName, 500);

    const loadResults = () => getFullResults(filters, 1, 50);

    useEffect(() => {
        fetchTestSuiteOptions();
        fetchRepoOptions();
    }, []);

    useEffect(() => {
        if (!filters.length) return;

        loadResults();
    }, [debouncedExecName]);

    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <DropdownButtonFilter
                loading={testSuitesIsLoading}
                options={testSuites.items.map((testSuite: TestCase) => ({
                    name: testSuite?.name,
                    value: testSuite?.id,
                    friendly_id: testSuite?.friendly_id,
                }))}
                pageCount={testSuites.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"ID"}
                keyOptions={"id"}
                setOptions={(e: any) => setOptions(e, "id")}
                getOptions={fetchTestSuiteOptions}
                onSave={loadResults}
            />
            <Input
                value={execName}
                id={"search-test-suite-name-input"}
                allowClear
                style={{ padding: 8, width: 300 }}
                placeholder={"Search test suite name...."}
                onChange={(e: any) => setOptions([e.target.value], "name")}
            />
            <DropdownButtonFilter
                loading={false}
                options={Object.values(TEST_TYPE_TECHNOLOGY)
                    .filter((type) => type)
                    .map((type) => ({
                        name: type.toUpperCase(),
                        value: type,
                    }))}
                values={filters}
                searchLocal={true}
                buttonLabel={"Test type"}
                keyOptions={"test_type"}
                setOptions={(e: any) => setOptions(e, "test_type")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={false}
                options={[
                    { name: "ACTIVE", value: "1" },
                    { name: "INACTIVE", value: "0" },
                ]}
                searchLocal={true}
                values={filters}
                buttonLabel={"Status"}
                keyOptions={"is_active"}
                radio={true}
                setOptions={(e: any) => setOptions(e, "is_active")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={reposIsLoading}
                options={repos.items.map((it: any) => ({
                    name: it.connection_name,
                    value: it.id,
                    friendly_id: it.friendly_id,
                }))}
                pageCount={repos.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Git repo"}
                keyOptions={"git_repo"}
                setOptions={(e: any) => setOptions(e, "git_repo")}
                getOptions={fetchRepoOptions}
                onSave={loadResults}
            />
        </Space>
    );
});
