import { Task } from "../enums";
import { getTestsFromNodeFilters } from "../utils";
import { VariableType } from "../view/pages/executions/results/executions/shared/IndentifierTagVariables";

export const useWorkflowNode = (store: any, setIsLoading?: (value: boolean) => void) => {
    const getTransformedNode = async (node: any) => {
        const nodeItems = node.items[0];
        const nodeFilters = nodeItems.kwargs.filters
            ? nodeItems.kwargs.filters.reduce(
                  (acc, filter) => {
                      acc = {
                          db_id: acc.db_id || filter.db_id,
                          repo_id: acc.repo_id || filter.repo_id,
                          tags: [],
                          separate_tags: acc.separate_tags || filter.separate_tags,
                      };

                      if (filter.test_tags?.length) {
                          acc.tags.push(...filter.test_tags);
                      }

                      return acc;
                  },
                  { db_id: null, repo_id: null, tags: [], separate_tags: false }
              )
            : null;

        const testsFromNodes = getTestsFromNodeFilters(nodeItems?.kwargs?.filters);
        let test_suite = [],
            test_case = [],
            test_approach = [];

        // TODO: it's better to create a single request  for the tests
        if (testsFromNodes.testSuites.length) {
            if (setIsLoading) setIsLoading(true);
            test_suite =
                (await store.testsStore.searchTestSuiteData({
                    filters: { id: testsFromNodes.testSuites },
                    returned: true,
                })) || [];
        }

        if (testsFromNodes.testApproaches.length) {
            test_approach =
                (await store.testsStore.searchTestApproachData({
                    filters: { id: testsFromNodes.testApproaches },
                    returned: true,
                })) || [];
        }

        if (testsFromNodes.testCases.length) {
            test_case =
                (await store.testsStore.searchTestCaseData({
                    filters: { id: testsFromNodes.testCases },
                    returned: true,
                })) || [];
        }

        if (nodeFilters?.repo_id) {
            store.webExecutionStore.setRepo(nodeFilters.repo_id);
        }

        const runtimeVariablesGroupId = nodeItems.kwargs.runtime_variables?.variables_group_id;
        const runtimeVariablesByGroupId =
            runtimeVariablesGroupId &&
            (await store.variablesStore.searchVariableWithValuesById([runtimeVariablesGroupId])).filter(
                (item) => item.variable_type === VariableType.INPUT
            );
        const runtimeVariablesGroup = runtimeVariablesGroupId && (await store.variablesStore.searchGroupById(runtimeVariablesGroupId));
        const dagRuntimeVariables = runtimeVariablesGroupId && {
            group_name: runtimeVariablesGroup?.persistent ? runtimeVariablesGroup.group_name : null,
            persistent: !!runtimeVariablesGroup?.persistent,
            variables: runtimeVariablesByGroupId,
        };
        const runtimeVariables = nodeItems.kwargs.runtime_variables?.variable_group || dagRuntimeVariables;

        if (setIsLoading) setIsLoading(false);

        return {
            id: node.name,
            user_defined_name: node.user_defined_name,
            name: node.name,
            flow_type: "workflow",
            task: nodeItems.function,
            condition: node.trigger_conditions,
            parameters_mode: nodeItems.parameters_mode,
            release_id: nodeItems.kwargs.release_id,
            ...(nodeItems.function === Task.RECON && {
                settings: {
                    generate_only: nodeItems.kwargs.generate_only,
                    reconciliation_settings: nodeItems.kwargs.reconciliation_settings,
                    release_id: nodeItems.kwargs.release_id,
                    execution_name: nodeItems.kwargs.execution_name,
                },
            }),
            ...(nodeFilters && {
                repo_id: nodeFilters.repo_id,
                db_config_id: nodeFilters.db_id,
                tags: { tags: nodeFilters.tags, separate_tags: nodeFilters.separate_tags },
            }),
            ...(runtimeVariables && {
                runtime_variables: {
                    variables: runtimeVariables.variables.map((variable) => ({
                        key: variable.variable_name,
                        value: variable.variable_value,
                    })),
                    v_group: {
                        persistent: runtimeVariables.persistent,
                        name: runtimeVariables.group_name,
                    },
                },
            }),
            ...(testsFromNodes.testSuites.length && { test_suite }),
            ...(testsFromNodes.testApproaches.length && { test_approach }),
            ...(testsFromNodes.testCases.length && { test_case }),
            ...(nodeItems?.function === Task.PROFILING && { ...nodeItems.kwargs }),
            ...(nodeItems?.function === Task.METADATA && { ...nodeItems.kwargs.settings }),
        };
    };

    return { getTransformedNode };
};
