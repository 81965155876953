import { useState } from "react";
import { optionsSize } from "../config";

export const useTestCaseOptions = (store: any) => {
    const [testCases, setTestCases] = useState({ items: [], pageCount: 1 });
    const [testCasesIsLoading, setTestCasesIsLoading] = useState(false);

    const fetchTestCaseOptions = (optionFilters = {}, page = 1) => {
        if (!testCases.items.length) setTestCasesIsLoading(true);

        store.testsStore
            .searchTestCaseData({
                page,
                filters: optionFilters,
                pagination: true,
                size: optionsSize,
                type: "or",
                returned: true,
            })
            .then((data: any) => {
                setTestCases((prevTestCases) => {
                    const prevIds = new Set(prevTestCases.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTestCases.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTestCasesIsLoading(false));
    };

    return { testCases, testCasesIsLoading, fetchTestCaseOptions };
};
