import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class TestSuiteStore extends PaginationStore {
    store;

    constructor(store) {
        super();
        this.store = store;
    }

    itemsToDownload = observable([]);
    suites_to_download = [];

    errors = {
        suite: {
            new: "",
            edit: "",
            delete: "",
            template: "",
            upload: "",
        },
    };

    testsSuites = observable([]);

    setNewTemplateSuiteErrorMessage = action((suite) => {
        this.errors.suite.template = suite;
    });

    setNewUploadSuiteErrorMessage = action((suite) => {
        this.errors.suite.upload = suite;
    });

    setTestSuitesForDownload = action((suites) => {
        this.suites_to_download = suites;
    });

    setItemsToDownload = action((cas) => {
        this.itemsToDownload = cas;
    });

    setTestSuiteCases = action((suite, cases) => {
        suite["test_cases"] = cases["test_cases"];
    });

    setTestSuitesApproach = action((approach_id, suites) => {
        let approach = this.store.testApproachStore.testApproaches.filter((it) => it.id === approach_id);
        if (approach.length > 0) {
            approach[0]["test_suites"] = suites;
        }
    });

    setTestSuites = action((suites) => {
        this.testsSuites = suites;
    });

    setNewSuiteErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.suite.new = error[0].msg;
        } else {
            this.errors.suite.new = error;
        }
    });

    setDeleteSuiteErrorMessage = action((error) => {
        this.errors.suite.delete = error;
    });

    setEditSuiteErrorMessage = action((error) => {
        if (error[0]?.msg) {
            this.errors.suite.edit = error[0].msg;
        } else {
            this.errors.suite.edit = error;
        }
    });

    getTestSuites = (page = 1, size = 50, returned = false) => {
        return this.store.apiStore
            .get({
                url: `/test_suite/?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (!returned) {
                    this.setTestSuites(data["items"]);
                    this.setPage(data["page"]);
                    this.setTotal(data["total"]);
                    this.setSize(data["size"]);
                    this.setPagination(true);
                } else {
                    return data;
                }
            })
            .catch((e) => {
                Promise.reject("Unable to get test-suites");
            });
    };

    getTestSuite = ({ suite_id, returned = false }) => {
        return this.store.apiStore
            .get({
                url: `/test_suite/${suite_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                let suite = this.testsSuites.filter((it) => it.id === suite_id);
                if (suite.length > 0) {
                    suite[0]["test_cases"] = data["test_cases"];
                }
            })
            .catch((e) => {
                Promise.reject("Unable to get test-suites");
            });
    };

    editTestSuite(suite_id, item = null, status = null) {
        return this.store.apiStore
            .put({
                url: `/test_suite/${suite_id}`,
                body: item
                    ? JSON.stringify({
                          name: item.name,
                          expected_test_case_count: item.expected_test_case_count,
                          test_type: item.test_type,
                          is_active: item.is_active,
                          description: item.description,
                          test_cases: item.test_cases,
                      })
                    : JSON.stringify({
                          is_active: status,
                      }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setEditSuiteErrorMessage(err));
    }

    deleteTestSuite(suite_id) {
        return this.store.apiStore
            .delete({
                url: `/test_suite/${suite_id}`,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then(() => this.getTestSuites(this.page, this.size))
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDeleteSuiteErrorMessage(err));
    }

    createTestSuite(item) {
        return this.store.apiStore
            .post({
                url: "/test_suite/",
                body: JSON.stringify({
                    name: item.name,
                    expected_test_case_count: item.expected_test_case_count,
                    test_type: item.test_type.toLowerCase(),
                    is_active: item.is_active ? true : item.is_active,
                    description: item.description,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getTestSuites(this.page, this.size);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => this.setNewSuiteErrorMessage(err));
    }

    // when test suite template will be reafactored
    // getBulkTemplate = (filename) => {
    //     let ts = this.itemsToDownload.map((it) => it.id);
    //     return this.store.apiStore
    //         .post({
    //             url: `/test_suite/bulk_excel/template`,
    //             body: JSON.stringify(ts),
    //             headers: { "Content-Type": "application/json" },
    //             auth_headers: this.store.authStore.getAuthHeader(),
    //         })
    //         .then((response) => response.blob())
    //         .then((blob) => {
    //             this.store.apiStore.downloadAsFile(blob, `${filename}_${dayjs().format("YYYY_MM_DD_HH_mm")}.xlsx`);
    //             this.setItemsToDownload([]);
    //         })
    //         .catch((e) => {
    //             this.setNewTemplateSuiteErrorMessage(e);
    //             message.error("Unable to get excel template. Try again later.");
    //         });
    // };

    getBulkTemplate() {
        let ts = this.itemsToDownload.map((it) => it.id);
        return this.store.apiStore
            .post({
                url: `/test_suite/bulk_excel/template`,
                body: JSON.stringify(ts),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.setTestSuitesForDownload(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewTemplateSuiteErrorMessage(err));
    }

    bulkEditExcelTestSuite(file) {
        return this.store.apiStore
            .post({
                url: `/test_suite/bulk_excel`,
                body: file,
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => {
                return response.status !== 200
                    ? this.store.testsStore.searchTestSuiteData({
                          filters: { name: [""] },
                          pagination: true,
                      })
                    : response.json();
            })
            .catch((err) => Promise.reject(err))
            .catch((err) => this.setNewUploadSuiteErrorMessage(err));
    }

    getInactiveTestCasesForTestSuites = (items_ids) => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "test_case" }, { table_name: "test_suite", parent: "test_case" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                { column: "test_case.is_active", is_value_column: false, search_value: "0", search_type: "eq" },
                                { column: "test_suite.id", is_value_column: false, search_value: items_ids, search_type: "in" },
                            ],
                        },
                    ],
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    let uniques = [...new Map(data.map((item) => [item["id"], item])).values()];
                    return uniques;
                }
                return [];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewApproachErrorMessage(err));
    };
}
export default TestSuiteStore;
