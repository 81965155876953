import { Modal } from "antd";
import { AiOutlineWarning } from "react-icons/ai";
import { DeleteConfirmationContent } from "./DeleteConfirmationContent";

export const deleteConfirmModal = (onConfirm: () => Promise<string | undefined>, type: string, name: string) => {
    Modal.confirm({
        title: (
            <div className="da-d-flex-center">
                <AiOutlineWarning className="da-mr-8 delete-confirm-modal-icon" size={28} />
                <span>Confirm</span>
            </div>
        ),
        icon: null,
        content: <DeleteConfirmationContent type={type} name={name} onConfirm={onConfirm} />,
        footer: null,
        className: "delete-confirm-modal",
        autoFocusButton: null,
    });
};
