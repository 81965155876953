import { DqCheckConfig, SelectionType } from "../utils";

const parseJsonParam = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (err) {
        return value;
    }
};

const getReferenceValues = (values: string): any[] => {
    const value = parseJsonParam(values);
    if (typeof value === "number" || typeof value === "string" || typeof value === "object") {
        return Array.isArray(value) ? value : [value];
    } else return null;
};

const getParam = (testCaseKeyword: any, paramName: string) => {
    const paramIndex = testCaseKeyword.details?.keyword_parameters?.findIndex((p: any) => p.name === paramName);
    return testCaseKeyword?.parameters?.[paramIndex]?.parameter;
};

export const mapTestCaseToDqConfigs = (testCase: any) => {
    const keyword = testCase.keyword[0];
    const aggregationType = getParam(keyword, "Aggregation Type");
    const isAggregateDeviation = aggregationType === "aggregate_deviation";

    switch (testCase?.keyword?.[0]?.details?.name) {
        case "Data Quality Custom Check":
            return {
                dq_type: SelectionType.CUSTOM,
                query_bad_count: {
                    input: getParam(keyword, "Count Broken Data Query"),
                    use_raw: false,
                    status: true,
                    loading: false,
                    validated: true,
                },
                query_count: {
                    input: getParam(keyword, "Count All Elements Query"),
                    use_raw: false,
                    status: true,
                    loading: false,
                    validated: true,
                },
                query_validate: {
                    input: getParam(keyword, "Verification Query"),
                    use_raw: false,
                    status: true,
                    loading: false,
                    validated: true,
                },
                db_config_id: Number(getParam(keyword, "DB Configuration Identifier")),
                dq_configs: [
                    {
                        dbConfigId: Number(getParam(keyword, "DB Configuration Identifier")),
                        schema: getParam(keyword, "Schema"),
                        object: getParam(keyword, "Table"),
                        column: getParam(keyword, "Attribute"),
                        where: getParam(keyword, "Where Clause") ?? "",
                    },
                ],
                aggregation: {
                    type: getParam(keyword, "Aggregation Type"),
                    operator: getParam(keyword, "Operator Type"),
                    values: getReferenceValues(getParam(keyword, "Reference Value")),
                },
                failure_threshold: Number(getParam(keyword, "Critical Threshold")),
                warning_threshold: Number(getParam(keyword, "Warning Threshold")),
            };
        case "Data Quality Simple Check":
            const customAggregation = getParam(keyword, "Custom Aggregation");
            const filters = getParam(keyword, "Filters");
            
            return {
                dq_type: SelectionType.PREDEFINED_V2,
                db_config_id: Number(getParam(keyword, "DB Configuration Identifier")),
                global_where: getParam(keyword, "Global Where Clause"),
                dq_configs: [
                    {
                        schema: getParam(keyword, "Schema"),
                        object: getParam(keyword, "Table"),
                        column: getParam(keyword, "Attribute"),
                        where: getParam(keyword, "Where Clause") ?? "",
                    },
                ],
                custom_aggregation: {
                    enabled: !!customAggregation,
                    value: customAggregation,
                },
                aggregation: {
                    type: aggregationType,
                    operator: getParam(keyword, "Operator Type"),
                    values: getReferenceValues(getParam(keyword, "Reference Value")),
                },
                filters: Array.isArray(filters) ? filters.map(parseJsonParam) : [],
                failure_threshold: isAggregateDeviation
                    ? Number(getParam(keyword, "Critical Deviation"))
                    : Number(getParam(keyword, "Critical Threshold")),
                warning_threshold: isAggregateDeviation
                    ? Number(getParam(keyword, "Accepted Deviation"))
                    : Number(getParam(keyword, "Warning Threshold")),
            };
        default:
            return {
                dq_type: SelectionType.PREDEFINED,
                db_config_id: Number(getParam(keyword, "DB Configuration Identifier")),
                global_where: getParam(keyword, "Global Where Clause"),
                dq_configs: [
                    {
                        schema: getParam(keyword, "Schema"),
                        object: getParam(keyword, "Table"),
                        column: getParam(keyword, "Attribute"),
                        where: getParam(keyword, "Where Clause") ?? "",
                    },
                ],
                aggregation: {
                    type: aggregationType,
                    operator: getParam(keyword, "Operator Type"),
                    values: getReferenceValues(getParam(keyword, "Reference Value")),
                },
                failure_threshold: isAggregateDeviation
                    ? Number(getParam(keyword, "Critical Deviation"))
                    : Number(getParam(keyword, "Critical Threshold")),
                warning_threshold: isAggregateDeviation
                    ? Number(getParam(keyword, "Accepted Deviation"))
                    : Number(getParam(keyword, "Warning Threshold")),
            };
    }
};

export const mapDqRequest = (testCase: any) => {
    const dqConfig = testCase.dq_configs[0];
    if (testCase.dq_type === SelectionType.PREDEFINED) {
        return {
            db_config_id: testCase.db_config.db_config_id,
            database_type: testCase.db_config.db_type?.toLowerCase(),
            schema_name: dqConfig.schema,
            table_name: dqConfig.object,
            column_name: dqConfig.column,
            operator_type: testCase.aggregation.operator ?? null,
            test_case_name: testCase.name,
            is_active: testCase.is_active,
            test_case_description: testCase.description,
            aggregation_type: testCase.aggregation.type,
            custom_approach_ids: testCase.customApproachIds,
            custom_suite_ids: testCase.customSuiteIds,
            custom_requirements_ids: testCase.customRequirementIds,
            custom_tags: testCase.custom_tags,
            aggregation_value: testCase.aggregation.values.filter((i: any) => i !== null) ?? [],
            threshold_warning: testCase.warning_threshold,
            threshold_failure: testCase.failure_threshold,
            where: dqConfig.where,
            global_where: testCase.predefined_global_where
        };
    } else {
        return {
            db_config_id: testCase.db_config.db_config_id,
            database_type: testCase.db_config.db_type?.toLowerCase(),
            schema_name: dqConfig.schema,
            table_name: dqConfig.object,
            column_name: dqConfig.column,
            where: dqConfig.where,
            is_active: testCase.is_active,
            test_case_name: testCase.name,
            test_case_description: testCase.description,
            custom_approach_ids: testCase.customApproachIds,
            custom_suite_ids: testCase.customSuiteIds,
            custom_requirements_ids: testCase.customRequirementIds,
            custom_tags: testCase.custom_tags,
            threshold_warning: testCase.warning_threshold,
            threshold_failure: testCase.failure_threshold,
            custom_count_query: testCase.query_count.input,
            custom_count_query_raw: testCase.query_count.use_raw,
            custom_bad_count_query: testCase.query_bad_count.input,
            custom_bad_count_raw: testCase.query_bad_count.use_raw,
            custom_verification_query: testCase.query_validate.input,
            custom_verification_raw: testCase.query_validate.use_raw,
        };
    }
};

export const getQueryConfig = (dqConfig: DqCheckConfig) => {
    return {
        schema_name: dqConfig.schema,
        table_name: dqConfig.object,
        column_name: dqConfig.column,
        where: dqConfig.where ?? null,
        aggregation_type: dqConfig.aggregationType?.toLowerCase() ?? null,
        join_column: dqConfig.joinColumn ?? null,
    };
};
