import { message } from "antd";
import { action, observable } from "mobx";
class ConfigStore {
    store;

    constructor(store) {
        this.store = store;
    }

    configurations = observable([]);

    app = observable({
        version: "",
    });

    error = {
        new: "",
    };

    setNewErrorMessage = action((data) => {
        this.error.new = data;
    });

    setAppVer = action((version) => {
        this.app.version = version["app_version"];
    });

    setAppConfigurations = action((data) => {
        this.configurations = data;
    });

    getAppVersion() {
        return this.store.apiStore
            .get({
                url: `/settings/version`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setAppVer(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setAppVer({ app_version: "undefined" }));
    }

    getAppSettings = action((returned = false) => {
        return this.store.apiStore
            .get({
                url: `/settings/`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) {
                    return data;
                }
                this.setAppConfigurations(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    changeAppSettings = action((edited_settings) => {
        let body = edited_settings.map((i) => {
            return {
                settings_name: i.name,
                value: i.value,
            };
        });

        return this.store.apiStore
            .post({
                url: `/settings/`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(body),
            })
            .then((response) => response.json())
            .then((data) => {
                message.success({ type: "success", content: "Settings was successfully updated! " });
                return this.getAppSettings();
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setNewErrorMessage(err));
    });

    getDistinctValues(table, column) {
        return this.store.apiStore
            .get({
                url: `/settings/${table}/${column}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => data)
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => []);
    }
}
export default ConfigStore;
