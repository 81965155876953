import { Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { useDebouncedValue } from "../../../../../hooks";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { useTagsOptions, useUserOptions } from "../hooks";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";

export const TemplatesFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let dagName = findFilter(filters, "dag_name")[0] || "";

    const store = useStore();
    const { users, usersIsLoading, fetchUserOptions } = useUserOptions(store);
    const { tags, tagsIsLoading, fetchTagsOptions } = useTagsOptions(store);

    const debouncedDagName = useDebouncedValue(dagName, 500);

    const loadResults = () => getFullResults(filters);

    useEffect(() => {
        fetchUserOptions();
    }, []);

    useEffect(() => {
        if (!filters.length) return;

        loadResults();
    }, [debouncedDagName]);

    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <Input
                id={"search-dag-name-input"}
                allowClear
                value={dagName}
                style={{ padding: 8, width: 300 }}
                placeholder={"Search template name...."}
                onChange={(e: any) => setOptions([e.target.value], "dag_name")}
            />
            <DropdownButtonFilter
                loading={tagsIsLoading}
                options={tags.items.map((tag: any) => ({ name: tag.tag, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={tags.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Tags"}
                keyOptions={"tag"}
                setOptions={(e: any) => setOptions(e, "tag")}
                getOptions={fetchTagsOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={usersIsLoading}
                options={users.items.map((tag: any) => ({ name: tag.username, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={users.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Created By"}
                keyOptions={"users"}
                setOptions={(e: any) => setOptions(e, "users")}
                getOptions={fetchUserOptions}
                onSave={loadResults}
            />
        </Space>
    );
});
