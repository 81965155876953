import { observer } from "mobx-react";
import { Suspense } from "react";

// Hooks
import { useAuth } from "../hooks";

// Router
import { BrowserRouter as AppRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import SuperUserRoute from "./SuperUserRoute";

// Routes
import { PrivateRoutes, PublicRoutes, SuperUserPagesRoutes } from "./routes";

// Layouts
import FullLayout from "../layout/FullLayout";
import VerticalLayout from "../layout/VerticalLayout";

// Components
import { Spin } from "antd";
import { useStore } from "../stores/StoreContext";
import HomePage from "../view/home";
import Error404 from "../view/pages/error";

const Router = observer(() => {
    const store = useStore();
    useAuth(store);
    
    // Default Layout
    const DefaultLayout = null; // FullLayout or VerticalLayout

    // All of the available layouts
    const Layouts = { VerticalLayout, FullLayout };

    // Return Filtered Array of Routes & Paths
    const LayoutRoutesAndPaths = (layout, type) => {
        const LayoutRoutes = [];
        const LayoutPaths = [];
        if (PrivateRoutes && type === "private") {
            PrivateRoutes.filter(
                (route) => (route.layout === layout || DefaultLayout === layout) && (LayoutRoutes.push(route), LayoutPaths.push(route.path))
            );
        }
        if (PublicRoutes && type === "public") {
            PublicRoutes.filter(
                (route) => (route.layout === layout || DefaultLayout === layout) && (LayoutRoutes.push(route), LayoutPaths.push(route.path))
            );
        }
        if (PublicRoutes && type === "super") {
            SuperUserPagesRoutes.filter(
                (route) => (route.layout === layout || DefaultLayout === layout) && (LayoutRoutes.push(route), LayoutPaths.push(route.path))
            );
        }

        return { LayoutRoutes, LayoutPaths };
    };
    // Return Route to Render
    const ResolvePublicRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout, "public");
            const LayoutTag = Layouts[layout];

            return (
                <PublicRoute path={LayoutPaths} store={store} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        render={(props) => {
                                            return (
                                                <Suspense fallback={null}>
                                                    <route.component {...props} />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </LayoutTag>
                </PublicRoute>
            );
        });
    };
    const ResolvePrivateRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout, "private");
            const LayoutTag = Layouts[layout];

            return (
                <PrivateRoute path={LayoutPaths} store={store} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        render={(props) => {
                                            return (
                                                <Suspense fallback={null}>
                                                    <route.component {...props} />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </LayoutTag>
                </PrivateRoute>
            );
        });
    };
    const ResolveSuperUserRoutes = () => {
        return Object.keys(Layouts).map((layout, index) => {
            const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout, "super");
            const LayoutTag = Layouts[layout];

            return (
                <SuperUserRoute path={LayoutPaths} store={store} key={index}>
                    <LayoutTag>
                        <Switch>
                            {LayoutRoutes.map((route) => {
                                return (
                                    <Route
                                        key={route.path}
                                        path={route.path}
                                        exact={route.exact === true}
                                        render={(props) => {
                                            return (
                                                <Suspense fallback={null}>
                                                    <route.component {...props} />
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </LayoutTag>
                </SuperUserRoute>
            );
        });
    };

    return (
        <AppRouter>
            <Spin spinning={store.authStore.isUserLoading} style={{ minHeight: "100vh" }}>
                <Switch>
                    {ResolvePublicRoutes()}
                </Switch>
                
                {store.authStore.user && (
                    <Switch>
                        {ResolvePrivateRoutes()}
                        {ResolveSuperUserRoutes()}
                        
                        {/* should stay  */}
                        <PrivateRoute
                            exact
                            path={"/"}
                            store={store}
                            render={() => {
                                return (
                                    <Layouts.VerticalLayout>
                                        <HomePage />
                                    </Layouts.VerticalLayout>
                                );
                            }}
                        />

                        {/* NotFound */}
                        <Route path="*">
                            <Error404 />
                        </Route>
                    </Switch>
                )}
            </Spin>
        </AppRouter>
    );
});

export default Router;