import { Bar } from "@ant-design/plots";
import { theme } from "antd";
import { FunctionComponent } from "react";

type DataChart = {
    sname: string;
    value: any;
};

type BarChartProps = {
    data_chart: DataChart[];
    serie_name: string;
};

export const BarVericalChart: FunctionComponent<BarChartProps> = ({ data_chart, serie_name }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const data = data_chart;
    const config = {
        data,
        yField: "sname",
        xField: "value",
        state: {
            active: {
                animate: { duration: 100, easing: "easeLinear" },
            },
        },
        yAxis: {
            label: {
                autoEllipsis: true,
                autoRotate: false,
            },
        },
        tooltip: {
            formatter: (item: any) => {
                return { name: serie_name, value: item.value + " ms" };
            },
        },
        xAxis: {
            label: {
                formatter: (text: string, item: any, index: number) => `${text} ms`,
            },
        },
        legend: false,
        color: token.colorPrimary,
        scrollbar: {
            type: "vertical",
            animate: true,
            style: {
                thumbColor: token.colorPrimaryBg,
            },
        },
    };

    return <Bar {...(config as any)} />;
};
