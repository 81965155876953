import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { ScheduleTaskType } from '../../../../../../enums';
import { IScheduledExecution } from "../../../types";
import { Schedule, ScheduledFrequency } from "./SchedulerModalComponent";

dayjs.extend(localeData);

const locale = dayjs().locale("en").localeData();
const getShiftedArray = (weekdays: string[] = []): string[] => {
    const temp: string[] = [...weekdays] ?? [];
    const r = temp.shift() ?? [];
    return [...temp, r] as string[];
};

const localeWeekdays = getShiftedArray(locale.weekdays());
const localeWeekdaysShort = getShiftedArray(locale.weekdaysShort());

export const DATE_FORMAT = "YYYY-MM-DD HH:mm";
export const defaultSchedule: Schedule = {
    type: "cron",
    taskType: "execute_robot_task_schedule",
    runOnceDate: dayjs().format(DATE_FORMAT),
    startDate: dayjs().format(DATE_FORMAT),
    endDate: dayjs().add(1, "year").format(DATE_FORMAT),
    frequency: "daily",
};

export const weekdays = localeWeekdays.map((w, index) => {
    const value = localeWeekdaysShort[index] as string;
    return {
        label: w,
        value: value?.toLowerCase(),
    };
}) as { label: string; value: string }[];

export const getScheduleAsText = (schedule: Schedule | null): string => {
    if (!schedule) {
        return "";
    }
    if (schedule?.type === "date") {
        return `Run once ${schedule.runOnceDate}`;
    } else if (schedule.type === "cron") {
        switch (schedule.frequency) {
            case "daily":
                return `Daily, on ${getDays(schedule.daily?.weekdays)
                    ?.join(", ")
                    .replace(/,([^,]*)$/, " and$1")}`;
            case "weekly":
                return `Weekly, on ${getDays(schedule?.weekly?.weekday) ?? ""} every 
            ${schedule.weekly?.week} ${schedule.weekly?.week === 1 ? "week" : "weeks"}`;
            case "monthly":
                return `Monthly, on day ${schedule.monthly?.day}`;
            default:
                return "";
        }
    }
    return "";
};

export const getDays = (days: string | string[] | undefined = []) => {
    return weekdays.filter((d) => days?.includes(d.value)).map((d) => d.label);
};

const getFrequencyForTask = (task: IScheduledExecution): ScheduledFrequency => {
    let type: ScheduledFrequency = "";
    if (task) {
        if (task?.trigger_params?.day?.includes("-")) {
            type = "weekly";
        } else if (parseInt(task.trigger_params.day) > 0) {
            type = "monthly";
        } else {
            type = "daily";
        }
    }
    return type;
};

const calculateWeeksFromItem = (task: IScheduledExecution) => {
    if (task) {
        const patterns = task.trigger_params?.day?.split(",");
        if (patterns?.length === 4) {
            return 1;
        } else if (patterns?.length === 2) {
            return 2;
        } else {
            if (task.trigger_params.day === "15-21") return 3;
            if (task.trigger_params.day === "22-28") return 4;
        }
    }
    return 1;
};

export const getSavedSchedule = (schedule: IScheduledExecution): Schedule => ({
    type: schedule.trigger_type?.toLowerCase() === "cron" ? "cron" : "date",
    taskType: schedule.type,
    runOnceDate:
        schedule.trigger_type?.toLowerCase() === "date"
            ? dayjs(schedule.next_run).format(DATE_FORMAT)
            : dayjs().add(1, "d").format(DATE_FORMAT),
    startDate: dayjs(schedule.trigger_params.start_date).format(DATE_FORMAT),
    endDate: dayjs(schedule.trigger_params.end_date).format(DATE_FORMAT),
    frequency: getFrequencyForTask(schedule),
    daily: {
        weekdays: schedule?.trigger_params?.day_of_week?.split(","),
    },
    weekly: {
        weekday: schedule?.trigger_params?.day_of_week,
        week: calculateWeeksFromItem(schedule),
    },
    monthly: {
        day: parseInt(schedule?.trigger_params?.day),
    },
});

export const getTitleForTaskType = (task_val: string) => {
    let item = Object.entries(ScheduleTaskType).filter((it: any) => {
        return task_val === it[1];
    });
    if (item.length > 0) {
        return item[0][0];
    }
};
