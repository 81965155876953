import PaginationStore from "./paginationStore";
import { observable, action } from "mobx";

class RolesStore extends PaginationStore {
    store;
    roles = observable([]);

    constructor(store) {
        super();
        this.store = store;
    }

    setRoles = action((roles) => {
        this.roles = roles;
    });

    getAllRoles = () => {
        return this.store.apiStore
            .get({
                url: "/roles/",
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
                this.setRoles(data["items"]);
            })
            .catch((e) => {
                Promise.reject("Unable to get roles");
            });
    };
}
export default RolesStore;
