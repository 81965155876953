import { deleteConfirmModal } from "../layout/components/content/deleteConfirmation/deleteConfirmationModal";
import { useStore } from "../stores/StoreContext";

export const useTestCaseActions = (setLoading: (value: boolean) => void, setFilters: (value: any) => void, isDqActions = false) => {
    const store = useStore();

    const handleTableCasesChange = async (pagination, filters) => {
        let filled = Object.fromEntries(Object.entries(filters).filter(([_, v]) => v != null));
        if (Object.keys(filled).length > 0 || typeof filters === "object") {
            if (
                pagination &&
                pagination.current &&
                pagination.pageSize &&
                (pagination.current !== store.testCaseStore.page || pagination.pageSize !== store.testCaseStore.size)
            ) {
                setLoading(true);
                return await store.testsStore
                    .searchTestCaseData({
                        page: pagination.current,
                        size: pagination.pageSize,
                        filters,
                        pagination: true,
                    })
                    .finally(() => setLoading(false));
            } else {
                setLoading(true);
                return await store.testsStore
                    .searchTestCaseData({
                        page: store.testCaseStore.page,
                        size: store.testCaseStore.size,
                        filters,
                        pagination: true,
                    })
                    .finally(() => setLoading(false));
            }
        } else {
            setLoading(true);
            setFilters([]);
            return await store.testsStore
                .searchTestCaseData({
                    page: pagination?.current ? pagination.current : 1,
                    size: pagination?.pageSize ? pagination.pageSize : 50,
                    filters: { name: [""] },
                    pagination: true,
                })
                .finally(() => setLoading(false));
        }
    };

    const handleClickDelete = (item, filters = {}) => {
        return new Promise<void>((resolve, reject) => {
            deleteConfirmModal(
                () => {
                    return store.testCaseStore
                        .deleteTestCase(item.id)
                        .then(() => {
                            setLoading(true);
                            return handleTableCasesChange(store.testCaseStore.pagination, filters);
                        })
                        .then(() => {
                            const errorMessage = store.testCaseStore.errors.case.delete;
                            if (errorMessage !== "") {
                                reject();
                                store.testCaseStore.setDeleteCaseErrorMessage("");
                                throw new Error(errorMessage);
                            } else {
                                resolve();
                            }
                        })
                        .finally(() => setLoading(false));
                },
                isDqActions ? "Dq Rule" : "Test Case",
                item.name
            );
        });
    };

    return { handleClickDelete, handleTableCasesChange };
};
