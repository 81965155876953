import { lazy } from "react";

const PrivatePages = [
    {
        path: "/requirements",
        component: lazy(() => import("../../view/pages/requirements/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/tests/suites",
        component: lazy(() => import("../../view/pages/tests/testSuitesCases/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/tests/suites/:id",
        component: lazy(() => import("../../view/pages/tests/testSuitesCases/single/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/tests/approaches",
        component: lazy(() => import("../../view/pages/tests/testCasesApproach/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/tests/cases",
        component: lazy(() => import("../../view/pages/tests/testCaseTable/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/tests/:id",
        component: lazy(() => import("../../view/pages/tests/testCaseTable/single/index")),
        layout: "VerticalLayout",
        exact: true,
    },
    {
        path: "/tests/cases/new",
        component: lazy(() => import("../../view/pages/tests/newDefinition/newDefinition")),
        layout: "VerticalLayout",
        exact: false,
    },
    {
        path: "/predictions",
        component: lazy(() => import("../../view/pages/predictions/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/error-page",
        component: lazy(() => import("../../view/pages/error")),
        layout: "FullLayout",
    },
    {
        path: "/release",
        component: lazy(() => import("../../view/pages/release/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/rules",
        component: lazy(() => import("../../view/pages/rules/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/executions/new",
        component: lazy(() => import("../../view/pages/executions/simple/steps/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/workflow/new",
        component: lazy(() => import("../../view/pages/workflow/new")),
        layout: "VerticalLayout",
    },
    {
        path: "/workflow/templates",
        component: lazy(() => import("../../view/pages/workflow/templates")),
        layout: "VerticalLayout",
    },
    {
        path: "/executions/results",
        component: lazy(() => import("../../view/pages/executions/results/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/executions/:id",
        component: lazy(() => import("../../view/pages/executions/single/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/profile/information",
        component: lazy(() => import("../../view/pages/profile/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/profile/password-change",
        component: lazy(() => import("../../view/pages/profile/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/reconciliation",
        component: lazy(() => import("../../view/pages/reconciliation/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/data_quality/dashboard",
        component: lazy(() => import("../../view/pages/data_quality/dashboard/dashboard")),
        layout: "FullLayout",
    },
    {
        path: "/data_quality/new",
        component: lazy(() => import("../../view/pages/data_quality/new/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/data_quality/results",
        component: lazy(() => import("../../view/pages/data_quality/results/index")),
        layout: "VerticalLayout",
    },
    {
        path: "/settings/connections",
        component: lazy(() => import("../../view/pages/settings/connections/connections")),
        layout: "VerticalLayout",
    },
];

export default PrivatePages;
