import { Avatar, Button, Col, Drawer, Layout, Menu, Row, Space } from "antd";
import { createElement, useState } from "react";
import { RiCloseFill, RiLogoutBoxLine, RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import logoSmall from "../../../assets/images/logo/logo.png";
import { AUTH_TOKEN } from "../../../enums";
import navigation from "../../../navigation";
import { useStore } from "../../../stores/StoreContext";
import MenuFooter from "./footer/index";
import MenuLogo from "./logo";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Sidebar(props) {
    const store = useStore();
    const { visible, setVisible } = props;
    const [collapsed, setCollapsed] = useState(localStorage.getItem("sidebar_hide") === "true" ?? false);

    // Location
    const location = useLocation();
    const { pathname } = location;

    const splitLocation = pathname.split("/");

    // Mobile Sidebar
    const onClose = () => {
        setVisible(false);
    };

    const onLogout = () => {
        localStorage.removeItem(AUTH_TOKEN);
        onClose();
    };

    // Menu
    function toggle() {
        setCollapsed(!collapsed);
        localStorage.setItem("sidebar_hide", !collapsed);
    }

    const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
        className: "trigger",
        onClick: toggle,
    });

    const splitLocationUrl = splitLocation[splitLocation.length - 2] + "/" + splitLocation[splitLocation.length - 1];

    const menuItem = navigation.map((item, index) => {
        if (item.header) {
            return <Menu.ItemGroup key={index} title={item.header}></Menu.ItemGroup>;
        }

        if (item.children) {
            let children_submenu = (
                <SubMenu key={item.id} icon={item.icon} title={item.title}>
                    {item.children.map((childrens, index) => {
                        if (!childrens.children) {
                            const childrenNavLink = childrens.navLink.split("/");
                            let children_menu = (
                                <Menu.Item
                                    key={childrens.id}
                                    icon={childrens.icon}
                                    className={
                                        splitLocationUrl ===
                                        childrenNavLink[childrenNavLink.length - 2] + "/" + childrenNavLink[childrenNavLink.length - 1]
                                            ? "ant-menu-item-selected children-item"
                                            : "ant-menu-item-selected-in-active children-item"
                                    }
                                    onClick={onClose}
                                >
                                    <Link to={childrens.navLink}>{childrens.title}</Link>
                                </Menu.Item>
                            );
                            return childrens.only_admin ? (store.authStore.ifUserIsAnAdmin() ? children_menu : null) : children_menu;
                        } else {
                            let submenu = (
                                <SubMenu key={childrens.id} icon={childrens.icon} title={childrens.title}>
                                    {childrens.children.map((childItem, index) => {
                                        const childrenItemLink = childItem.navLink.split("/");

                                        return (
                                            <Menu.Item
                                                key={childItem.id}
                                                icon={childItem.icon}
                                                className={
                                                    splitLocationUrl ===
                                                    childrenItemLink[childrenItemLink.length - 2] +
                                                        "/" +
                                                        childrenItemLink[childrenItemLink.length - 1]
                                                        ? "ant-menu-item-selected"
                                                        : "ant-menu-item-selected-in-active"
                                                }
                                                onClick={onClose}
                                            >
                                                <Link to={childItem.navLink}>{childItem.title}</Link>
                                            </Menu.Item>
                                        );
                                    })}
                                </SubMenu>
                            );
                            return childrens.only_admin ? (store.authStore.ifUserIsAnAdmin() ? submenu : null) : submenu;
                        }
                    })}
                </SubMenu>
            );
            return item.only_admin ? (store.authStore.ifUserIsAnAdmin() ? children_submenu : null) : children_submenu;
        } else {
            const itemNavLink = item.navLink.split("/");

            let menu_item = (
                <Menu.Item
                    key={item.id}
                    icon={item.icon}
                    onClick={onClose}
                    className={
                        item.id === "profile"
                            ? splitLocation[splitLocation.length - 3] + "/" + splitLocation[splitLocation.length - 2] ===
                              itemNavLink[itemNavLink.length - 3] + "/" + itemNavLink[itemNavLink.length - 2]
                                ? "ant-menu-item-selected"
                                : "ant-menu-item-selected-in-active"
                            : splitLocation[splitLocation.length - 2] + "/" + splitLocation[splitLocation.length - 1] ===
                              itemNavLink[itemNavLink.length - 2] + "/" + itemNavLink[itemNavLink.length - 1]
                            ? "ant-menu-item-selected"
                            : "ant-menu-item-selected-in-active"
                    }
                >
                    {item.external_link ? (
                        <a target="_blank" rel="noopener noreferrer" href={item.navLink}>
                            {item.title}
                        </a>
                    ) : (
                        <Link to={item.navLink}>{item.title}</Link>
                    )}
                </Menu.Item>
            );

            return item.only_admin ? (store.authStore.ifUserIsAnAdmin() ? menu_item : null) : menu_item;
        }
    });

    return (
        <Sider trigger={null} collapsible collapsed={collapsed} theme="light" width={256} className="da-sidebar">
            <Row className="da-mr-12 da-ml-24 da-mt-24" align="bottom" justify="space-between">
                <Space style={collapsed === false ? { marginLeft: 15 } : null}>
                    <Col>{collapsed === false ? <MenuLogo store={store} onClose={onClose} /> : ""}</Col>

                    <Col className="da-pr-0">
                        <Space style={{ padding: 10 }}>
                            <Button icon={trigger} type="text" className="da-float-right"></Button>
                        </Space>
                    </Col>
                </Space>

                {collapsed !== false && (
                    <Space style={{ padding: 10, marginLeft: 5 }}>
                        <Col className="da-mt-8">
                            <Link to="/" onClick={onClose}>
                                <img className="da-logo" style={{ height: 50 }} src={logoSmall} alt="logo" />
                            </Link>
                        </Col>
                    </Space>
                )}
            </Row>

            <Menu
                mode="inline"
                defaultOpenKeys={[
                    splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
                    splitLocation[splitLocation.length - 2],
                ]}
            >
                {menuItem}
            </Menu>

            {collapsed === false ? (
                <MenuFooter onClose={onClose} />
            ) : (
                <Row className="da-sidebar-footer da-py-16" align="middle" justify="center">
                    <Col>
                        <Link to="/login" onClick={onLogout}>
                            <Avatar icon={<RiLogoutBoxLine className="remix-icon" />} />
                        </Link>
                    </Col>
                </Row>
            )}

            <Drawer
                title={<MenuLogo store={store} onClose={onClose} />}
                className="da-mobile-sidebar"
                placement="left"
                closable={true}
                onClose={onClose}
                open={visible}
                closeIcon={<RiCloseFill className="remix-icon da-text-color-black-80" size={24} />}
            >
                <Menu
                    mode="inline"
                    defaultOpenKeys={[
                        splitLocation.length === 5 ? splitLocation[splitLocation.length - 3] : null,
                        splitLocation[splitLocation.length - 2],
                    ]}
                >
                    {menuItem}
                </Menu>
                <MenuFooter onClose={onClose} />
            </Drawer>
        </Sider>
    );
}
