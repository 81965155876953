import { Select, Spin } from "antd";
import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useStore } from "../../stores/StoreContext";
import { AppSettings } from "../types/appSettings";
import { DbConfig } from "../types/dbConfigType";
import { SelectOption } from "./SelectOption";

type DbConfigSelectComponentProps = {
    dbConnection: string | number | null;
    id?: string;
    placeholder?: string;
    onDbConnectionChanged: (selected: string | number) => void;
    disabled?: boolean;
};

export const DbConfigSelectComponent: FunctionComponent<DbConfigSelectComponentProps> = ({
    dbConnection = null,
    placeholder = null,
    id = null,
    onDbConnectionChanged,
    disabled = false,
}) => {
    const store = useStore();
    const [isDbConnectionLoading, setDbConnectionLoading] = useState<boolean>(false);
    const [isDbConnectionValuesLoading, setDbConnectionValuesLoading] = useState<boolean>(false);
    const [isConfigurationLoading, setConfigurationLoading] = useState<boolean>(true);

    const handleConnectionSearch = useCallback(
        debounce((searchValue: EventTarget | string) => {
            setDbConnectionValuesLoading(true);
            return store.testsStore
                .searchConnectionData({ connection: [searchValue], connection_ids: [searchValue] }, true, "or")
                .then(() => setDbConnectionValuesLoading(false));
        }, 300),
        []
    );

    useEffect(() => {
        async function getAppConfiguration() {
            if (store.configStore.configurations?.length === 0) {
                store.configStore.getAppSettings().then(() => setConfigurationLoading(false));
            } else {
                setConfigurationLoading(false);
            }
        }
        getAppConfiguration();
    }, []);

    useEffect(() => {
        async function getDBs() {
            if (disabled) {
                onDbConnectionChanged(null);
                return;
            }

            if (store.settingsStore.databases.length === 0) {
                setDbConnectionLoading(true);
                store.settingsStore.getDatabaseConnections().then(() => {
                    setDbConnectionLoading(false);
                    onDbConnectionChanged(store.settingsStore.databases[0]?.db_config_id);
                });
            } else {
                if (!dbConnection) {
                    onDbConnectionChanged(store.settingsStore.databases[0]?.db_config_id);
                }
            }
        }
        getDBs();
    }, [dbConnection, store.settingsStore.databases[0]?.db_config_id, disabled]);

    return (
        <>
            <Select
                id={id ?? "dq-source-db-config"}
                showSearch
                loading={isDbConnectionLoading || isDbConnectionValuesLoading || isConfigurationLoading}
                disabled={isDbConnectionLoading || isConfigurationLoading || disabled}
                onSearch={(e) => handleConnectionSearch(e)}
                placeholder={placeholder ?? "Source db config"}
                filterOption={(inputValue: string, option: any) => {
                    return (
                        option.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
                        `${option.value}`?.toLowerCase() === `${inputValue}`?.toLowerCase()
                    );
                }}
                notFoundContent={isDbConnectionLoading ? <Spin /> : "No matches"}
                optionFilterProp="name"
                value={dbConnection}
                onSelect={(selectedDbConfig: string | number) => {
                    onDbConnectionChanged(selectedDbConfig);
                }}
            >
                {store.settingsStore.databases.map((value: DbConfig) => {
                    let technology_support = !store.configStore.configurations
                        .filter((it: AppSettings) => it.name === "TECHNOLOGY_SUPPORT")?.[0]
                        ?.value?.split(",")
                        .includes(value.db_type);

                    return (
                        <Select.Option
                            className="dq-source-db-config-select"
                            key={value.db_config_id}
                            name={value.connection_name}
                            value={value.db_config_id}
                            disabled={!value.is_active || technology_support}
                        >
                            <SelectOption id={value.friendly_id} name={value.connection_name} />
                        </Select.Option>
                    );
                })}
            </Select>
        </>
    );
};
