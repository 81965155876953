import { Card, Col, DatePicker, Row, Skeleton, Spin, TreeSelect } from "antd";
import dayjs from "dayjs";
import { inject, observer } from "mobx-react";
import React from "react";
import getValue from "../../common/getValue";
import PageTitle from "../../layout/components/content/page-title";
import { DbConfigSelectComponent } from "../shared/DbConfigSelectComponent";
import ColumnChart from "./columnChart";
import ExecutionsLineChart from "./executionsLineChart";
import LineChart from "./lineChart";
import { RadialBarChart } from "./radialBarChart";
import StatsTileRow from "./statsRow";
import TopFailedTests from "./topFailedTests";

@inject("store")
@observer
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                db_attribute_coverage: true,
                test_case_coverage_expected_actual: true,
                executions_in_time: true,
                automation_trend: true,
                top_failed_tests: true,
                suites: true,
                suites_chart: true,
                test_run_details: false,
                connections: true,
                query_predictions: true,
            },
            test_run_details: false,
            executions_select: null,
            connection_select: null,
            query_predictions: null,
            treeData: [],
        };
    }

    getDbMetaResults = async (db_config_id = null) => {
        if (!db_config_id) {
            await this.props.store.dbMetaResults.getStatisticsForDb(this.props.store.settingsStore.databases[0].db_config_id);
        } else {
            await this.props.store.dbMetaResults.getStatisticsForDb(db_config_id);
        }
    };

    componentDidMount = async () => {
        await this.props.store.metricsStore.getExecutionsTrend().then(() =>
            this.setState({
                loading: { ...this.state.loading, automation_trend: false },
            })
        );
        await this.props.store.metricsStore.getTopFailedTests().then(() =>
            this.setState({
                loading: { ...this.state.loading, top_failed_tests: false },
            })
        );
        await this.props.store.metricsStore.getExecutionsInTime().then(() =>
            this.setState({
                loading: { ...this.state.loading, executions_in_time: false },
            })
        );
        await this.props.store.metricsStore.getTestSuiteCoverageExpectedTestCasesVsActual().then(() =>
            this.setState({
                loading: {
                    ...this.state.loading,
                    test_case_coverage_expected_actual: false,
                },
            })
        );
        await this.props.store.mlPredictionStore.getAllPredictiedQueriesModel().then((data) =>
            this.setState({
                loading: { ...this.state.loading, query_predictions: false },
                query_predictions: data,
            })
        );
        await Promise.allSettled([this.props.store.configStore.getAppSettings(), this.props.store.settingsStore.getDatabaseConnections()])
            .then(() =>
                this.setState({
                    loading: { ...this.state.loading, connections: false },
                })
            )
            .then(async () => {
                let is_default = this.props.store.settingsStore.databases.filter((it) => it.is_default);
                if (is_default.length > 0) {
                    this.setState({
                        connection_select: is_default[0].db_config_id,
                    });
                    return await this.getDbMetaResults();
                }
            })
            .then(() =>
                this.setState({
                    loading: { ...this.state.loading, db_attribute_coverage: false },
                })
            );
        await this.props.store.metricsStore
            .getSuites()
            .then(() => {
                if (this.props.store.metricsStore.suites.length > 0) {
                    this.props.store.metricsStore.getSuiteDetails(this.props.store.metricsStore.suites[0].test_suite_name).then(() => {
                        this.setState({
                            loading: { ...this.state.loading, suites: false },
                            executions_select: {
                                type: "suite",
                                data: this.props.store.metricsStore.suites[0].test_suite_name,
                            },
                            treeData: this.props.store.metricsStore.suites.map((it) => {
                                return {
                                    id: it.test_suite_name,
                                    key: `${it.test_suite_name}^suite`,
                                    title: it.test_suite_name,
                                    value: `${it.test_suite_name}^suite`,
                                    isLeaf: false,
                                };
                            }),
                        });
                    });
                }
            })
            .then(() =>
                this.setState({
                    loading: { ...this.state.loading, suites_chart: false },
                })
            );
    };

    handleSelectDatabaseChange = (e) => {
        let value = getValue(e);
        if (value) {
            this.setState({
                loading: {
                    ...this.state.loading,
                    db_attribute_coverage: true,
                },
                connection_select: value,
            });
            return this.getDbMetaResults(value).then(() =>
                this.setState({
                    loading: {
                        ...this.state.loading,
                        db_attribute_coverage: false,
                    },
                })
            );
        }
    };

    automationExecutionTrendChange = (e) => {
        let value = getValue(e);
        if (value) {
            this.setState({
                loading: {
                    ...this.state.loading,
                    automation_trend: true,
                },
            });
            return this.props.store.metricsStore.getExecutionsTrend(value.$d).then(() =>
                this.setState({
                    loading: {
                        ...this.state.loading,
                        automation_trend: false,
                    },
                })
            );
        }
    };

    onChangeSelect = (e) => {
        this.setState({
            test_run_details: false,
            loading: { ...this.state.loading, suites_chart: true },
        });
        let value = getValue(e);

        let splitted_value = value.split("^");
        let type = splitted_value.at(-1);

        switch (type) {
            case "suite":
                let resource_name = splitted_value.slice(0, -1).join("^");
                this.setState({
                    executions_select: { data: resource_name, type: type },
                });
                return this.props.store.metricsStore.getSuiteDetails(resource_name).then(() =>
                    this.setState({
                        loading: { ...this.state.loading, suites_chart: false },
                    })
                );
            case "case":
                let case_name = splitted_value.slice(1, -1).join("^");
                let suite_name = splitted_value.slice(0, -2).join("^");
                this.setState({
                    executions_select: {
                        data: case_name,
                        parent: suite_name,
                        type: type,
                    },
                });
                return this.props.store.metricsStore.getCaseDetails(suite_name, case_name).then(() =>
                    this.setState({
                        loading: { ...this.state.loading, suites_chart: false },
                    })
                );
        }
    };

    setLoading = (property, value) => {
        this.setState({ loading: { ...this.state.loading, [property]: value } });
    };

    setRunDetails = (value) => {
        this.setState({ test_run_details: value });
    };

    onLoadData = (e) => {
        let value = getValue(e);
        this.setLoading({ suites: false });
        let suite = this.props.store.metricsStore.suites.filter((it) => it.test_suite_name === value.title)[0];
        return this.props.store.metricsStore.getSuiteCasesDetails(value.title).then(() => {
            if (suite.cases.length > 0) {
                suite.cases.map((cas) => {
                    this.setState({
                        treeData: [
                            ...this.state.treeData,
                            {
                                id: cas.test_case_name,
                                key: `${suite.test_suite_name}^${cas.test_case_name}^case`,
                                pId: suite.test_suite_name,
                                title: cas.test_case_name,
                                value: `${suite.test_suite_name}^${cas.test_case_name}^case`,
                                isLeaf: true,
                            },
                        ],
                    });
                });
            }
            this.setLoading({ suites: false });
        });
    };

    getDefaultSuiteRuns = () => {
        if (this.state.executions_select) {
            if (this.state.executions_select.type === "suite") {
                return this.props.store.metricsStore.suites.filter((it) => it.test_suite_name === this.state.executions_select.data)[0];
            } else {
                return this.props.store.metricsStore.suites
                    .filter((it) => it.test_suite_name === this.state.executions_select.parent)[0]
                    .cases.filter((it) => it.test_case_name === this.state.executions_select.data)[0];
            }
        }
    };

    render() {
        const user = this.props.store.authStore.user;
        let data = this.getDefaultSuiteRuns();

        return (
            <>
                <Col span={24}>
                    <Row align="middle" justify="space-between">
                        <Col md={12} span={24}>
                            <PageTitle pageTitle={`Hello${user?.username ? `, ${user?.username}` : ""} 👋`} pageText={"Welcome to XAutomate"} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="da-mt-32">
                    <Row gutter={[32, 32]}>
                        <Col span={24}>
                            <StatsTileRow
                                execution_in_time={this.state.loading.executions_in_time}
                                query_predictions={this.state.query_predictions}
                                loading={this.state.loading}
                            />
                        </Col>

                        <Col md={16} span={24}>
                            <Card bordered={false} className="da-border-color-black-40">
                                <Row>
                                    <Col className="da-mb-16" span={24}>
                                        <Row justify="space-between">
                                            <Row align="bottom" className="da-pb-16">
                                                <h5 className="da-mr-8" id="test_case_coverage_title">
                                                    Test Case Coverage - Expected Test Cases vs Actual Test Cases
                                                </h5>
                                            </Row>
                                        </Row>
                                    </Col>

                                    <Col span={24}>
                                        {this.state.loading.test_case_coverage_expected_actual ? (
                                            <Skeleton active paragraph={{ rows: 10 }} />
                                        ) : (
                                            <ColumnChart
                                                actual_data={this.props.store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                    (item) => item.actual_test_cases_count
                                                )}
                                                expected_data={this.props.store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                    (item) => item.expected_test_case_count
                                                )}
                                                categories={this.props.store.metricsStore.test_case_coverage_expected_test_cases_vs_actual.map(
                                                    (item) => item.test_suite_name
                                                )}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col md={8} span={24}>
                            <Card bordered={false} className="da-border-color-black-40">
                                <Row>
                                    <Col md={24} span={24}>
                                        <Row align="middle" justify="space-between">
                                            <h5 id="metadata_coverage">Attributes Metadata Coverage</h5>
                                            {this.state.loading.connections ? (
                                                <Spin />
                                            ) : (
                                                <DbConfigSelectComponent
                                                    id="database-config-dashboard"
                                                    dbConnection={this.state.connection_select}
                                                    onDbConnectionChanged={(e) => this.handleSelectDatabaseChange(e)}
                                                />
                                            )}
                                        </Row>

                                        {this.state.loading.db_attribute_coverage ? (
                                            <Skeleton active style={{ marginTop: 20 }} paragraph={{ rows: 10 }} />
                                        ) : (
                                            <RadialBarChart
                                                handleSelectDatabaseChange={this.handleSelectDatabaseChange}
                                                loading={this.state.loading.db_attribute_coverage}
                                                connection_select={this.state.connection_select}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col md={8} span={24}>
                            <Card
                                bordered={false}
                                className="da-border-color-black-40 da-project-ecommerce-table-card"
                                style={{ marginBottom: 50 }}
                            >
                                <Row>
                                    <Col md={24} span={24}>
                                        <Row align="middle" justify="space-between" style={{ marginBottom: "5%" }}>
                                            <h5 id="executions_title">Executions</h5>

                                            {this.state.loading.suites ? (
                                                <Spin />
                                            ) : (
                                                <TreeSelect
                                                    id="executions_select_input"
                                                    treeDataSimpleMode
                                                    showSearch
                                                    defaultValue={this.props.store.metricsStore.suites[0].test_suite_name}
                                                    onChange={this.onChangeSelect}
                                                    placeholder={"Select Suite"}
                                                    style={{ width: "80%" }}
                                                    loadData={(e) => this.onLoadData(e)}
                                                    treeData={this.state.treeData}
                                                />
                                            )}
                                        </Row>

                                        {this.state.loading.suites && this.state.loading.suites_chart && this.state.executions_select ? (
                                            <Skeleton active paragraph={{ rows: 9 }} />
                                        ) : (
                                            <ExecutionsLineChart
                                                setLoading={this.setLoading}
                                                setRunDetails={this.setRunDetails}
                                                test_run_details={this.state.test_run_details}
                                                loading_tc_runs={this.state.loading.test_run_details}
                                                loading={this.state.loading.suites_chart}
                                                choosen={data}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col xl={8} md={6} span={24}>
                            <Card
                                bordered={false}
                                className="da-border-color-black-40 da-project-ecommerce-table-card"
                                style={{ marginBottom: 50 }}
                            >
                                <Row>
                                    <Col md={24} span={24}>
                                        <Row align="middle" justify="space-between">
                                            <h5 className="da-mb-32" id="top_failed_tests_title">
                                                Top 5 Failed Tests
                                            </h5>
                                        </Row>
                                        {this.state.loading.top_failed_tests ? (
                                            <Skeleton active paragraph={{ rows: 9 }} />
                                        ) : (
                                            <TopFailedTests items={this.props.store.metricsStore.top_failed_tests} />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xl={8} md={8} span={24}>
                            <Card bordered={false} className="da-border-color-black-40" style={{ marginBottom: 50 }}>
                                <Row>
                                    <Col className="da-mb-16" span={24}>
                                        <Row justify="space-between">
                                            <Row align="bottom" className="da-pb-16">
                                                <h5 className="da-mr-8" id="execution_trend_title">
                                                    Automation Test Execution Trend
                                                </h5>
                                            </Row>

                                            <Col>
                                                <DatePicker
                                                    onChange={(e) => this.automationExecutionTrendChange(e)}
                                                    picker="year"
                                                    defaultValue={dayjs()}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={24}>
                                        {this.state.loading.automation_trend ? (
                                            <Skeleton active paragraph={{ rows: 9 }} />
                                        ) : (
                                            <LineChart
                                                names={["PASS", "FAIL"]}
                                                items={this.props.store.metricsStore.test_automation_trend?.slice(-10)}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </>
        );
    }
}
export default Home;
