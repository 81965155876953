import { Avatar, Badge, Button, Card, Col, Divider, Dropdown, Row, Space, Typography } from "antd";
import { Fragment, FunctionComponent, ReactNode } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";

interface HeaderNotificationsType {
    icon: ReactNode;
    dropdown: any[];
    id: string;
    title: string;
    badge?: boolean;
    clear?: boolean;
    handleClear?: () => void;
}

export const HeaderNotifications: FunctionComponent<HeaderNotificationsType> = ({
    icon,
    dropdown,
    id,
    title,
    badge,
    clear,
    handleClear,
}) => {
    const store = useStore();

    const dropdownItem = (item: any) => (
        <Row key={id} align="middle">
            <Col className="da-mr-8">
                <Avatar size={32} icon={item.icon} className="da-d-flex-center-full" />
            </Col>

            <Space direction="vertical">
                <Typography.Text style={{ fontSize: "small" }} className="da-d-block da-w-100 da-mb-4 da-badge-text da-font-weight-600 ">
                    {item.title}
                </Typography.Text>

                <Typography.Text
                    type="secondary"
                    style={{ fontSize: "small" }}
                    className="da-d-block da-input-description da-font-weight-600 da-text-color-black-80"
                >
                    {item.subtitle}
                </Typography.Text>

                {item?.date && (
                    <Typography.Text
                        type="secondary"
                        className="da-d-block da-mt-4 da-input-description da-font-weight-600 da-text-color-black-80"
                    >
                        {item.date}
                    </Typography.Text>
                )}
            </Space>
        </Row>
    );

    const getMenuItemType = (item: any) => {
        if (item?.support) {
            return (
                <a target="_blank" rel="noopener noreferrer" onClick={() => store.apiStore.getSupportBundle()}>
                    {dropdownItem(item)}
                </a>
            );
        } else if (item?.external) {
            return (
                <a target="_blank" rel="noopener noreferrer" href={item.link}>
                    {dropdownItem(item)}
                </a>
            );
        } else if (item?.link) {
            return <Link to={item.link}>{dropdownItem(item)}</Link>;
        } else {
            return dropdownItem(item);
        }
    };

    const notificationMenu = () => {
        return (
            <>
                {dropdown.length > 0 ? (
                    <Card bordered={false} key={`${id}-item-title`} className="da-bg-color-black-0  ">
                        <div key={`${id}-item-title-2`}>
                            <Row align="middle" justify="space-between">
                                <Col className="da-p1-body da-font-weight-600 da-text-color-black-100 da-mr-64">{title}</Col>

                                {badge ? <Badge count={dropdown.length} /> : null}
                            </Row>
                        </div>

                        <Divider className="da-bg-color-black-40" />

                        {dropdown.map((item, index) => {
                            return (
                                <Fragment key={`${item.link}-${index}`}>
                                    <div>{getMenuItemType(item)}</div>
                                    {dropdown.length !== index + 1 ? <Divider className="da-mx-0 da-bg-color-black-40" /> : null}
                                </Fragment>
                            );
                        })}
                        {clear && handleClear ? (
                            <>
                                <Divider className="da-my-16 da-mx-0 da-bg-color-black-40" />
                                <div className="da-px-16">
                                    <Button onClick={handleClear} type="primary" disabled={!badge} block>
                                        Clear all notifications
                                    </Button>
                                </div>
                            </>
                        ) : null}
                    </Card>
                ) : null}
            </>
        );
    };

    const avatar = <Avatar size={50} className={`${id}-avatar`} icon={icon} />;
    return (
        <Dropdown
            overlay={notificationMenu}
            overlayStyle={badge ? { overflow: "auto", maxHeight: 300 } : {}}
            className={`${id}-dropdown`}
            placement="bottomRight"
        >
            {badge ? (
                <Badge status="error" dot offset={[-5, 5]} style={{ width: 10, height: 10 }}>
                    {avatar}
                </Badge>
            ) : (
                avatar
            )}
        </Dropdown>
    );
};
export default HeaderNotifications;
