import { useState } from "react";
import { optionsSize } from "../config";

export const useTestSuiteOptions = (store: any) => {
    const [testSuites, setTestSuites] = useState({ items: [], pageCount: 1 });
    const [testSuitesIsLoading, setTestSuitesIsLoading] = useState(false);

    const fetchTestSuiteOptions = (optionFilters = {}, page = 1) => {
        if (!testSuites.items.length) setTestSuitesIsLoading(true);

        store.testsStore
            .searchTestSuiteData({
                page,
                filters: optionFilters,
                pagination: true,
                size: optionsSize,
                type: "or",
                returned: true,
            })
            .then((data: any) => {
                setTestSuites((prevTestSuites) => {
                    const prevIds = new Set(prevTestSuites.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTestSuites.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTestSuitesIsLoading(false));
    };

    return { testSuites, testSuitesIsLoading, fetchTestSuiteOptions };
};
