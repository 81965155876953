import { Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import getValue from "../../../../../common/getValue";
import { useDebouncedValue } from "../../../../../hooks";
import { useStore } from "../../../../../stores/StoreContext";
import { DbConfig } from "../../../../../view/types/dbConfigType";
import { TagType } from "../../../../../view/types/tagType";
import { TestApproachType } from "../../../../../view/types/testApproachType";
import { TestSuiteType } from "../../../../../view/types/testSuiteType";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import {
    useDBConnectionOptions,
    useDistinctValueOptions,
    useTagsOptions,
    useTestApproachOptions,
    useTestSuiteOptions,
    useUserOptions,
} from "../hooks";
import { useAggregationOptions } from "../hooks/useAggregationOptions";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";

export const DQResultsFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let dqTestName = findFilter([filters], "name")[0] || "";

    const store = useStore();
    const {
        distinctValues: schemaOptions,
        distinctValuesIsLoading: schemaOptionsIsLoading,
        fetchDistinctValueOptions: fetchSchemaOptions,
    } = useDistinctValueOptions(store);
    const {
        distinctValues: columnOptions,
        distinctValuesIsLoading: columnOptionsIsLoading,
        fetchDistinctValueOptions: fetchColumnOptions,
    } = useDistinctValueOptions(store);
    const {
        distinctValues: tableOptions,
        distinctValuesIsLoading: tableOptionsIsLoading,
        fetchDistinctValueOptions: fetchTableOptions,
    } = useDistinctValueOptions(store);
    const { testApproaches, testApproachesIsLoading, fetchTestApproachOptions } = useTestApproachOptions(store);
    const { testSuites, testSuitesIsLoading, fetchTestSuiteOptions } = useTestSuiteOptions(store);
    const { users, usersIsLoading, fetchUserOptions } = useUserOptions(store);
    const { dbConnections, dbConnectionsIsLoading, fetchDBConnectionOptions } = useDBConnectionOptions(store);
    const { tags, tagsIsLoading, fetchTagsOptions } = useTagsOptions(store);
    const { aggregations, aggregationsIsLoading, fetchAggregationsOptions } = useAggregationOptions(store);

    const debouncedDqTestName = useDebouncedValue(dqTestName, 500);

    useEffect(() => {
        fetchDBConnectionOptions();
        fetchSchemaOptions("database_metadata", "schema");
        fetchTableOptions("database_metadata", "table_name");
        fetchColumnOptions("database_metadata", "column_name");
        fetchTestApproachOptions();
        fetchTestSuiteOptions();
        fetchUserOptions();
        fetchTagsOptions();
        fetchAggregationsOptions();
    }, []);

    const loadResults = () => getFullResults(filters, 1, 10);

    useEffect(() => {
        if (!filters?.name) return;

        loadResults();
    }, [debouncedDqTestName]);

    return (
        <Space wrap>
            <Input
                allowClear
                value={dqTestName}
                style={{ padding: 8, width: 300 }}
                placeholder={"Search rule name...."}
                onChange={(e: any) => {
                    let value = getValue(e);
                    setOptions([value], "name");
                }}
            />
            <DropdownButtonFilter
                loading={dbConnectionsIsLoading}
                options={dbConnections.items.map((tag: DbConfig) => ({
                    name: tag.connection_name,
                    value: tag.db_config_id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={dbConnections.pageCount}
                searchLocal={false}
                values={[filters]}
                buttonLabel={"DB Config"}
                keyOptions={"db_connections"}
                setOptions={(e: any) => setOptions(e, "db_connections")}
                getOptions={fetchDBConnectionOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={false}
                options={[
                    { name: "ACTIVE", value: true },
                    { name: "INACTIVE", value: false },
                ]}
                searchLocal={true}
                values={[filters]}
                buttonLabel={"Status"}
                keyOptions={"is_active"}
                radio={true}
                setOptions={(e: any) => setOptions(e, "is_active")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={schemaOptionsIsLoading}
                options={schemaOptions.map((i: string) => ({ name: i, value: i }))}
                searchLocal={true}
                values={[filters]}
                buttonLabel={"Database Schema"}
                keyOptions={"db_schema"}
                setOptions={(e: any) => setOptions(e, "db_schema")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={tableOptionsIsLoading}
                options={tableOptions.map((i: string) => ({ name: i, value: i }))}
                searchLocal={true}
                values={[filters]}
                buttonLabel={"Database Table"}
                keyOptions={"db_table"}
                setOptions={(e: any) => setOptions(e, "db_table")}
                onSave={loadResults}
            />

            <DropdownButtonFilter
                loading={columnOptionsIsLoading}
                options={columnOptions.map((i: string) => ({ name: i, value: i }))}
                searchLocal={true}
                values={[filters]}
                buttonLabel={"Database Column"}
                keyOptions={"db_column"}
                setOptions={(e: any) => setOptions(e, "db_column")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={aggregationsIsLoading}
                options={aggregations.map((i: any) => ({ name: i?.display_name, value: i?.type }))}
                searchLocal={true}
                values={[filters]}
                buttonLabel={"Aggregation Type"}
                keyOptions={"aggregation_type"}
                setOptions={(e: any) => setOptions(e, "aggregation_type")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testSuitesIsLoading}
                options={testSuites.items.map((tag: TestSuiteType) => ({ name: tag.name, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={testSuites.pageCount}
                searchLocal={false}
                values={[filters]}
                buttonLabel={"Test Suites"}
                keyOptions={"test_suites"}
                setOptions={(e: any) => setOptions(e, "test_suites")}
                getOptions={fetchTestSuiteOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testApproachesIsLoading}
                options={testApproaches.items.map((tag: TestApproachType) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testApproaches.pageCount}
                searchLocal={false}
                values={[filters]}
                buttonLabel={"Test Approaches"}
                keyOptions={"test_approaches"}
                setOptions={(e: any) => setOptions(e, "test_approaches")}
                getOptions={fetchTestApproachOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={tagsIsLoading}
                options={tags.items.map((tag: TagType) => ({ name: tag.tag, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={tags.pageCount}
                searchLocal={false}
                values={[filters]}
                buttonLabel={"Tags"}
                keyOptions={"tags"}
                setOptions={(e: any) => setOptions(e, "tags")}
                getOptions={fetchTagsOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={usersIsLoading}
                options={users.items.map((tag: any) => ({ name: tag.username, value: tag.id, friendly_id: tag.friendly_id }))}
                pageCount={users.pageCount}
                searchLocal={false}
                values={[filters]}
                buttonLabel={"Created By"}
                keyOptions={"users"}
                setOptions={(e: any) => setOptions(e, "users")}
                getOptions={fetchUserOptions}
                onSave={loadResults}
            />
        </Space>
    );
});
