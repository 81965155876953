import { Table, Tag } from "antd";
import Text from "../../layout/components/content/text/text";

export default function TopFailedTests(props) {
    const { items } = props;

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "10%",
            render: (text) => {
                return <Text isLink={false} ellipsis={{ tooltip: `${text}` }} text={text} />;
            },
        },
        {
            title: "Failed Count",
            dataIndex: "failed_count",
            key: "failed_count",
            width: "5%",
            render: (text) => <Tag className={`tag-failed-no-hover`}>{text}</Tag>,
        },
    ];

    return <Table tableLayout="fixed" rowKey={(record) => record.test_id} columns={columns} dataSource={items} pagination={false} />;
}
