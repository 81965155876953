import { Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect } from "react";
import { schedule_types_mode } from "../../../../../enums";
import { useDebouncedValue } from "../../../../../hooks";
import { useStore } from "../../../../../stores/StoreContext";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { useScheduleOptions } from "../hooks";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";

export const ScheduleFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let scheduleName = findFilter(filters, "name");

    const store = useStore();
    const { schedules, schedulesIsLoading, fetchScheduleOptions } = useScheduleOptions(store);

    const debouncedScheduleName = useDebouncedValue(scheduleName, 500);

    useEffect(() => {
        if (filters.some((item) => Object.keys(item).includes("name"))) {
            loadResults();
        }
    }, [debouncedScheduleName]);

    const loadResults = () => getFullResults(filters, 1, 10);

    return (
        <Space wrap>
            <DropdownButtonFilter
                radio={true}
                loading={schedulesIsLoading}
                options={schedules.items.map((item) => ({
                    name: item.schedule_id,
                    value: item.schedule_id,
                    friendly_id: item.schedule_id,
                }))}
                pageCount={schedules.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"ID"}
                keyOptions={"id"}
                setOptions={(e: any) => setOptions(e, "id")}
                getOptions={fetchScheduleOptions}
                onSave={loadResults}
            />
            <Input
                allowClear
                value={scheduleName}
                style={{ padding: 8, width: 300 }}
                placeholder="Search schedule name...."
                onChange={(e: any) => setOptions(e.target.value, "name")}
            />
            <DropdownButtonFilter
                loading={false}
                options={Object.keys(schedule_types_mode).map((it: any) => ({ name: it, value: it }))}
                values={filters}
                searchLocal={true}
                buttonLabel={"Type"}
                keyOptions={"type"}
                setOptions={(e: any) => setOptions(e, "type")}
                onSave={loadResults}
            />
        </Space>
    );
});
