import { useState } from "react";
import { optionsSize } from "../config";

export const useScheduleOptions = (store: any) => {
    const [schedules, setSchedules] = useState({ items: [], pageCount: 1 });
    const [schedulesIsLoading, setSchedulesIsLoading] = useState(false);

    const fetchScheduleOptions = (optionFilters = {} as any, page = 1) => {
        if (!schedules.items.length) setSchedulesIsLoading(true);

        store.croneJobStore
            .getSchedule({ page, size: optionsSize, filters: [optionFilters], returned: true })
            .then((data: any) => {
                if (!data) return;

                setSchedules((prevSchedules) => {
                    const prevIds = new Set(prevSchedules.items.map((prevItem) => prevItem.schedule_id));
                    return {
                        items: [...prevSchedules.items, ...data.items.filter((item) => !prevIds.has(item.schedule_id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setSchedulesIsLoading(false));
    };

    return { schedules, schedulesIsLoading, fetchScheduleOptions };
};
