import APIStore from "./apiStore";
import AuthStore from "./authStore";
import BugStore from "./bugStore";
import ConfigStore from "./configStore";
import CroneJobStore from "./croneJobStore";
import DagStore from "./dagStore";
import DataQuality from "./dataQuality";
import DataQualityMetric from "./dataQualityMetric";
import DbExecutionStore from "./dbExecutionStore";
import DbMetaResults from "./dbMetaResults";
import DefaultStore from "./defaultStore";
import DefectStore from "./defectStore";
import ExecutionStore from "./executionStore";
import GitStore from "./gitStore";
import HistoryStore from "./historyStore";
import IntervalStore from "./intervalStore";
import LineageStore from "./lineageStore";
import LogStore from "./logStore";
import MetricsStore from "./metricStore";
import MLPredictions from "./mlPredictions";
import NewDataQuality from "./newDataQuality";
import NotificationStore from "./notificationStore";
import ReconciliationStore from "./reconciliationStore";
import ReleaseStore from "./releaseStore";
import RequirementStore from "./requirementStore";
import RolesStore from "./rolesStore";
import RulesStore from "./rulesStore";
import SearchStore from "./searchStore";
import SettingsStore from "./settingsStore";
import TagsStore from "./tagsStore";
import TasksStore from "./tasksStore";
import TestApproachStore from "./testApproachStore";
import TestCaseStore from "./testCaseStore";
import TestSuiteStore from "./testSuiteStore";
import TestsStore from "./testsStore";
import UsersStore from "./usersStore";
import VariablesStore from "./variablesStore";
import WebExecutionStore from "./webExecutionStore";
class AppStore {
    usersStore;
    settingsStore;
    releaseStore;
    testsStore;
    authStore;
    apiStore;
    rulesStore;
    dbExecutionStore;
    metricsStore;
    tasksStore;
    searchStore;
    testCaseStore;
    webExecutionStore;
    configStore;
    croneJobStore;
    tagsStore;
    requirementStore;
    intervalStore;
    dbMetaResults;
    reconciliationStore;
    dataQuality;
    mlPredictions;
    dataQualityMetric;
    defaultStore;
    dagStore;
    defectStore;
    lineageStore;
    variablesStore;
    logStore;
    historyStore;
    notificationStore;
    newDataQuality;
    bugStore;

    constructor() {
        this.historyStore = new HistoryStore(this);
        this.apiStore = new APIStore(this);
        this.authStore = new AuthStore(this);
        this.testsStore = new TestsStore(this);
        this.usersStore = new UsersStore(this);
        this.testSuiteStore = new TestSuiteStore(this);
        this.testCaseStore = new TestCaseStore(this);
        this.testApproachStore = new TestApproachStore(this);
        this.settingsStore = new SettingsStore(this);
        this.rolesStore = new RolesStore(this);
        this.releaseStore = new ReleaseStore(this);
        this.rulesStore = new RulesStore(this);
        this.dbExecutionStore = new DbExecutionStore(this);
        this.webExecutionStore = new WebExecutionStore(this);
        this.executionStore = new ExecutionStore(this);
        this.metricsStore = new MetricsStore(this);
        this.tasksStore = new TasksStore(this);
        this.searchStore = new SearchStore(this);
        this.gitStore = new GitStore(this);
        this.configStore = new ConfigStore(this);
        this.croneJobStore = new CroneJobStore(this);
        this.tagsStore = new TagsStore(this);
        this.requirementStore = new RequirementStore(this);
        this.intervalStore = new IntervalStore(this);
        this.dbMetaResults = new DbMetaResults(this);
        this.reconciliationStore = new ReconciliationStore(this);
        this.dataQualityStore = new DataQuality(this);
        this.mlPredictionStore = new MLPredictions(this);
        this.dataQualityMetricStore = new DataQualityMetric(this);
        this.defaultStore = new DefaultStore(this);
        this.dagStore = new DagStore(this);
        this.defectStore = new DefectStore(this);
        this.lineageStore = new LineageStore(this);
        this.variablesStore = new VariablesStore(this);
        this.logStore = new LogStore(this);
        this.notificationStore = new NotificationStore(this);
        this.newDataQuality = new NewDataQuality(this);
        this.bugsStore = new BugStore(this);
    }
}
export default AppStore;
