import { useState } from "react";
import { optionsSize } from "../config";

export const useDistinctValueOptions = (store: any) => {
    const [distinctValues, setDistinctValues] = useState([]);
    const [distinctValuesIsLoading, setDistinctValuesIsLoading] = useState(false);

    const fetchDistinctValueOptions = (table: string, column: string) => {
        setDistinctValuesIsLoading(true);

        store.configStore
            .getDistinctValues(table, column)
            .then((data) => {
                if (!data) return;

                setDistinctValues(data.slice(0, optionsSize));
            })
            .finally(() => setDistinctValuesIsLoading(false));
    };

    return { distinctValues, distinctValuesIsLoading, fetchDistinctValueOptions };
};
