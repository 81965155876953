import { action, makeObservable, observable } from "mobx";
import PaginationStore, { DEFAULT_PAGE_SIZE } from "./paginationStore";
class NotificationStore extends PaginationStore {
    store;

    events = observable([]);
    subscribers = observable([]);

    error = {
        new: "",
    };
    constructor(store) {
        super();
        this.store = store;

        makeObservable(this, {
            events: observable,
            subscribers: observable,
        });
    }

    setEvents = (data) => {
        this.events = data;
    };

    setSubscribers = (data) => {
        this.subscribers = data;
    };

    setNewError = (err) => {
        this.error.new = err;
    };

    getSubscribers = action((page = 1, size = DEFAULT_PAGE_SIZE) => {
        return this.store.apiStore
            .get({
                url: `/subscribers/?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSubscribers(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);

                return data["items"];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    getSubscriber = action((id) => {
        return this.store.apiStore
            .get({
                url: `/subscribers/settings/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSubscribers(
                    this.subscribers.map((item) => ({
                        ...item,
                        ...item.subscriber_id === id && {
                            details: data
                        },
                    }))
                );

                return { subscriber_id: id, details: data };
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    getEvents = action((page = 1, size = DEFAULT_PAGE_SIZE) => {
        return this.store.apiStore
            .get({
                url: `/events/?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setEvents(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    getSubscribersByEventId = action(({ id, page = 1, size  = DEFAULT_PAGE_SIZE }) => {
        return this.store.apiStore
            .get({
                url: `/subscribers/by_event/${id}?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data["items"];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    editSubscriber = action(({ id, payload }) => {
        return this.store.apiStore
            .put({
                url: `/subscribers/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({ subscriber_name: payload.subscriber_name }),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    createSubscriber = action((payload) => {
        return this.store.apiStore
            .post({
                url: `/subscribers/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(payload),
            })
            .then((response) => response.json())
            .catch((err) => {
                err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0]));
                this.setNewError(err);
            })
    });

    editSubscriberSettings = action(({ id, payload }) => {
        return this.store.apiStore
            .put({
                url: `/subscribers/settings/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({ name: payload.subscriber_key, value: payload.subscriber_value, is_secret: true }),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    deleteSubscriber = action((id) => {
        return this.store.apiStore
            .delete({
                url: `/subscribers/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    editEvent = action(({ id, payload }) => {
        return this.store.apiStore
            .put({
                url: `/events/${id}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({ event_name: payload.event_name, default_message: payload.default_message }),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });

    eventAssign = action(({ id, subscribers }) => {
        return this.store.apiStore
            .post({
                url: `/events/assign`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({ event_id: id, subscribers_ids: subscribers }),
            })
            .then((response) => response.json())
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setNewError(err);
            });
    });
}
export default NotificationStore;
