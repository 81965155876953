import { Alert, Button, Modal, Row, Spin, Typography, message } from "antd";
import { useState } from "react";

interface Props {
    type: string;
    name: string;
    onConfirm: () => Promise<any>;
}

export const DeleteConfirmationContent: React.FC<Props> = ({ type, name, onConfirm }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = () => {
        setIsLoading(true);
        onConfirm()
            .then((msg) => {
                message.success(msg || `${type} deleted`);
                Modal.destroyAll();
            })
            .catch((err) => setErrorMessage(err?.message))
            .finally(() => setIsLoading(false));
    };

    return (
        <Spin spinning={isLoading}>
            <Row style={{ flexDirection: "column" }}>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Are you sure to delete?
                </Typography.Title>
                <ul style={{ paddingLeft: 20 }}>
                    <li style={{ wordBreak: "break-word" }}>
                        {type}
                        <ul>
                            <li style={{ wordBreak: "break-word" }}>{name}</li>
                        </ul>
                    </li>
                </ul>
            </Row>
            {errorMessage && (
                <Row>
                    <Alert message="Error" description={errorMessage} type="error" />
                </Row>
            )}
            <Row justify="end">
                <div className="ant-modal-confirm-btns">
                    <Button onClick={() => Modal.destroyAll()}>No</Button>
                    <Button danger onClick={handleSubmit}>
                        Yes
                    </Button>
                </div>
            </Row>
        </Spin>
    );
};
