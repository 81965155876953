import { createBrowserHistory } from "history";
class HistoryStore {
    history = createBrowserHistory();

    store;

    constructor(store) {
        this.store = store;
    }
}

export default HistoryStore;
