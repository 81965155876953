import { Button, Col, Layout, Row, theme } from "antd";
import { Link } from "react-router-dom";

import dayjs from "dayjs";
import img from "../../../assets/images/pages/error/404.svg";

export default function Error() {
    const { useToken } = theme;
    const { token } = useToken();
    return (
        <>
            <Layout
                style={{
                    background: token.colorPrimaryBgHover,
                    position: "-webkit-sticky",
                    top: 0,
                    height: "calc(100vh - 60px)",
                }}
            >
                <Layout.Content style={{ position: "sticky", padding: "0 48px" }}>
                    <Row id="error_page" className="da-bg-color-primary-4 da-text-center">
                        <Col className="da-error-content da-py-32" span={24}>
                            <Row className="da-h-100" align="middle" justify="center">
                                <Col>
                                    <img className="da-d-block da-m-auto" src={img} alt="404" />

                                    <h1
                                        style={{ color: token.colorText }}
                                        className="da-error-content-title da-mb-sm-0 da-mb-8 da-font-weight-300"
                                    >
                                        404
                                    </h1>

                                    <h2 style={{ color: token.colorText }} className="h1 da-mb-sm-0 da-mb-16">
                                        Oops, Page not found!
                                    </h2>

                                    <p style={{ color: token.colorText }} className="da-mb-32">
                                        You can go back home.
                                    </p>

                                    <Link to="/">
                                        <Button type="primary">Back to Home</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
            <Layout.Footer style={{ background: token.colorPrimaryBgHover, textAlign: "center", bottom: 0, position: "sticky" }}>
                <Col span={24} className="da-py-24">
                    <p className="da-mb-0 da-badge-text">©{dayjs().format("YYYY")} X-Automate</p>
                </Col>
            </Layout.Footer>
        </>
    );
}
