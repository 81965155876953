import { AutoComplete, Badge, Input, Space, Spin, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { RiSearchLine } from "react-icons/ri";
import { withRouter } from "react-router-dom";

@inject("store")
@observer
class HeaderSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            autocompleteLoading: false,
            searchText: "",
        };
        this.timer = null;
    }

    componentDidMount = async () => {
        this.props.store.intervalStore.registerWatcher();
    };

    unregisterWatcher() {
        this.props.store.intervalStore.unregisterWatcher();
    }

    componentWillUnmount = () => {
        this.props.store.testCaseStore.setTestCases([]);
        this.props.store.testApproachStore.setTestApproaches([]);
        this.props.store.testSuiteStore.setTestSuites([]);
        return this.props.store.requirementStore.setRequirements([]);
    };

    onChange = (value) => {
        this.setState({ searchText: value });
        this.props.store.searchStore.setSearchText(value);
    };

    onSearch = async (value) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({ autocompleteLoading: true });
            const promises = [];
            promises.push(this.props.store.searchStore.searchHeaderData("case", value));
            promises.push(this.props.store.searchStore.searchHeaderData("suite", value));
            promises.push(this.props.store.searchStore.searchHeaderData("approach", value));
            promises.push(this.props.store.searchStore.searchHeaderData("requirement", value));
            return Promise.all(promises).then(() => this.setState({ autocompleteLoading: false }));
        }, 500);
    };

    handleClick = (item) => {
        let category = item?.toLowerCase().split("-")[0];
        let id = item?.toLowerCase().split("-")[1];
        if (category === "suite") {
            this.props.store.searchStore.setSearchItemObjectCategory("suite");
            this.props.store.searchStore.obj.item.id = id;
            this.props.history.push("/tests/suites");
        } else if (category === "case") {
            this.props.store.searchStore.setSearchItemObjectCategory("case");
            this.props.store.searchStore.obj.item.id = id;
            this.props.history.push("/tests/cases");
        } else if (category === "approach") {
            this.props.store.searchStore.setSearchItemObjectCategory("approach");
            this.props.store.searchStore.obj.item.id = id;
            this.props.history.push("/tests/approaches");
        } else if (category === "requirement") {
            this.props.store.searchStore.setSearchItemObjectCategory("requirement");
            this.props.store.searchStore.obj.item.id = id;
            this.props.history.push("/requirements");
        }
        this.setState({ searchText: null });
    };

    render() {
        const renderTitle = (title, href, amount) => (
            <>
                <Badge count={amount} offset={[17, 5]}>
                    <Typography.Text strong>{title}</Typography.Text>
                </Badge>
                <a
                    style={{
                        float: "right",
                    }}
                    href={href}
                >
                    more
                </a>
            </>
        );

        const renderItem = (category, friendly_id, title, id) => ({
            value: `${category}-${id}`,
            key: id,
            label: (
                <Space className={category}>
                    <Typography.Text type="secondary">{friendly_id}</Typography.Text>
                    <Typography>{title}</Typography>
                </Space>
            ),
        });
        const options = [];
        if (!this.state.autocompleteLoading) {
            const items_cases =
                this.props.store.testCaseStore.testsCases &&
                this.props.store.testCaseStore.testsCases.length > 0 &&
                this.props.store.searchStore.searchText !== ""
                    ? this.props.store.testCaseStore.testsCases
                    : [];
            const items_suites =
                this.props.store.testSuiteStore.testsSuites &&
                this.props.store.testSuiteStore.testsSuites.length > 0 &&
                this.props.store.searchStore.searchText !== ""
                    ? this.props.store.testSuiteStore.testsSuites
                    : [];
            const items_approach =
                this.props.store.testApproachStore.testApproaches &&
                this.props.store.testApproachStore.testApproaches.length > 0 &&
                this.props.store.searchStore.searchText !== ""
                    ? this.props.store.testApproachStore.testApproaches
                    : [];
            const items_requirement =
                this.props.store.requirementStore.requirements &&
                this.props.store.requirementStore.requirements.length > 0 &&
                this.props.store.searchStore.searchText !== ""
                    ? this.props.store.requirementStore.requirements
                    : [];
            if (this.props.store.searchStore.searchText !== "" && items_cases.length > 0) {
                options.push({
                    label: renderTitle("Test Cases", "/tests/cases", this.props.store.testCaseStore.testsCases.length),
                    options: items_cases.map((opt) => {
                        return renderItem("case", opt.friendly_id, opt.name, opt.id);
                    }),
                });
            }
            if (this.props.store.searchStore.searchText !== "" && items_suites.length > 0) {
                options.push({
                    label: renderTitle("Test Suites", "/tests/suites", this.props.store.testSuiteStore.testsSuites.length),
                    options: items_suites.map((opt) => {
                        return renderItem("suite", opt.friendly_id, opt.name, opt.id);
                    }),
                });
            }
            if (this.props.store.searchStore.searchText !== "" && items_approach.length > 0) {
                options.push({
                    label: renderTitle("Test Approach", "/tests/approaches", this.props.store.testApproachStore.testApproaches.length),
                    options: items_approach.map((opt) => {
                        return renderItem("approach", opt.friendly_id, opt.name, opt.id);
                    }),
                });
            }
            if (this.props.store.searchStore.searchText !== "" && items_requirement.length > 0) {
                options.push({
                    label: renderTitle("Requirements", "/requirements", this.props.store.requirementStore.requirements.length),
                    options: items_requirement.map((opt) => {
                        return renderItem("requirement", opt.friendly_id, opt.name, opt.work_item_id);
                    }),
                });
            }
        }

        return (
            <AutoComplete
                disabled={this.state.loading}
                icon={<RiSearchLine />}
                value={this.state.searchText}
                placeholder="Search..."
                notFoundContent={this.state.autocompleteLoading ? <Spin /> : "No matches"}
                onChange={(val) => this.onChange(val)}
                onSearch={(val) => this.onSearch(val)}
                onSelect={(item) => this.handleClick(item)}
                dropdownMatchSelectWidth={500}
                dropdownStyle={{ padding: 10 }}
                style={{ width: "100%" }}
                options={options}
            >
                <Input className="search" loading={this.state.loading} width={"15%"} />
            </AutoComplete>
        );
    }
}

export default withRouter(HeaderSearch);
