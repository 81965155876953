import React, { useState } from "react";
import { Button, List, Modal, Row, Space, Tag, Typography } from "antd";
import { AiOutlineWarning } from "react-icons/ai";
import { ReposWithResourceType } from "./types";
import { TagTheme } from "../../../../view/shared/TagTheme";
import { RiGitRepositoryLine } from "react-icons/ri";

interface Props {
    repos: ReposWithResourceType;
    onSubmit: (id: string | number) => void;
    onCancel: () => void;
    isOpened?: boolean;
}

const ITEMS_DEFAULT_AMOUNT = 3;

export const GitReposConflictModal: React.FC<Props> = ({ repos, onSubmit, onCancel, isOpened = false }) => {
    const [expandedItems, setExpandedItems] = useState([]);

    const handleExpandedItems = (repo: string) => {
        if (expandedItems.includes(repo)) {
            setExpandedItems((prevState) => prevState.filter((item) => item !== repo));
        } else {
            setExpandedItems((prevState) => [...prevState, repo]);
        }
    };

    return (
        <Modal
            title={
                <Space className="da-d-flex-center">
                    <AiOutlineWarning className="da-mr-8 delete-confirm-modal-icon" size={28} />
                    <Typography style={{ fontSize: 16 }}>Git repository conflicts</Typography>
                </Space>
            }
            open={isOpened}
            onCancel={onCancel}
            okButtonProps={{ danger: true, style: { display: "none" } }}
        >
            <List id="git-repos-conflict-modal-list" split={false}>
                {Object.keys(repos).map((repo, index) => (
                    <List.Item
                        key={`conflicted-git-repo-${index}`}
                        style={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
                    >
                        <Row justify="space-between">
                            <Tag color="gold" style={{ display: "flex", alignItems: "center", padding: "8px 40px", margin: 0 }}>
                                <RiGitRepositoryLine className="remix-icon" style={{ paddingRight: 8 }} />
                                {repos[repo].name}
                            </Tag>
                            <Button
                                type="default"
                                onClick={() => {
                                    onSubmit(Number(repo));
                                    onCancel();
                                }}
                            >
                                Continue with&nbsp;<b>{repos[repo].name}</b>
                            </Button>
                        </Row>
                        {Array.isArray(repos[repo]?.items) && (
                            <List
                                style={{ marginTop: 10 }}
                                bordered={true}
                                dataSource={
                                    expandedItems.includes(repo) ? repos[repo].items : repos[repo].items.slice(0, ITEMS_DEFAULT_AMOUNT)
                                }
                                loadMore={
                                    repos[repo].items.length > ITEMS_DEFAULT_AMOUNT && (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                marginTop: 12,
                                                height: 32,
                                                lineHeight: "32px",
                                            }}
                                        >
                                            <Button onClick={() => handleExpandedItems(repo)}>{`show ${
                                                expandedItems.includes(repo) ? "less" : "more"
                                            }`}</Button>
                                        </div>
                                    )
                                }
                                renderItem={(item: any, itemIndex) => (
                                    <List.Item
                                        key={`conflicted-git-repo-resource-${itemIndex}`}
                                        style={{
                                            justifyContent: "flex-start",
                                            alignItems: "baseline",
                                        }}
                                    >
                                        <TagTheme text={item.id} style={{ marginBottom: 0 }} />
                                        <Typography>{item.name}</Typography>
                                    </List.Item>
                                )}
                            ></List>
                        )}
                    </List.Item>
                ))}
            </List>
        </Modal>
    );
};
