import { Avatar, Card, Col, Divider, Row } from "antd";
import { useContext } from "react";
import { ThemeContext } from "../../../../themeProvider";
export const SelectBoxItem = (props) => {
    const themeContextProvider = useContext(ThemeContext);
    let className = props.className
        ? props.className
        : themeContextProvider?.["isDarkMode"]
        ? "selected-test-suite-dark"
        : "selected-test-suite";

    return (
        <div className="hp-select-box-item" style={props.style}>
            <input type="radio" hidden id={props.id} name={props.name} />

            <label htmlFor={props.id} className="text-title hp-d-block hp-cursor-pointer">
                <Card
                    id={props.id ? `${props.id}-card` : null}
                    style={props.cardStyle}
                    className={`hp-border-color-black-40 ${className} ${props.disabled ? "disabled" : null}`}
                    hoverable={props.disabled ? false : true}
                    bordered={props.bordered}
                    bodyStyle={props.bodyStyle}
                    onClick={props.disabled ? null : props.onClick}
                >
                    {props.type === "boxed" ? (
                        <>
                            <Row className="hp-text-center hp-mb-8" style={{ justifyContent: "center" }}>
                                {props.image && (
                                    <Col span={24} className="hp-my-12">
                                        {props.imageType === "circle" ? (
                                            <Avatar src={props.image} size={props.imageHeight && props.imageHeight} className="hp-m-auto" />
                                        ) : (
                                            <img src={props.image} alt={props.title} height={props.imageHeight && props.imageHeight} />
                                        )}
                                    </Col>
                                )}

                                {props.icon && (
                                    <Col span={24} className="hp-my-12">
                                        {props.icon}
                                    </Col>
                                )}

                                {props.title && (
                                    <Col span={24}>
                                        <span className="h4 hp-d-block">{props.title}</span>
                                    </Col>
                                )}

                                {props.subTitle && (
                                    <Col span={24}>
                                        <span className="hp-p1-body hp-text-color-black-80 hp-text-color-dark-30 hp-d-block">
                                            {props.subTitle}
                                        </span>
                                    </Col>
                                )}

                                {props.badge && (
                                    <Col span={24}>
                                        <span className="hp-p1-body hp-text-color-danger-2 hp-mt-8 hp-d-block">{props.badge}</span>
                                    </Col>
                                )}
                                {props.content && (
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Divider className="hp-mt-16 hp-bg-black-40" />
                                        <Col>
                                            <div className="hp-select-box-item-description">{props.content}</div>
                                        </Col>
                                    </Row>
                                )}
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" className="hp-py-8">
                                <Col md={12} span={24}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        {props.image && (
                                            <Col className="hp-mr-16 hp-flex-sm-100 hp-mr-sm-0 hp-mb-sm-8">
                                                {props.imageType === "circle" ? (
                                                    <Avatar
                                                        src={props.image}
                                                        size={props.imageHeight && props.imageHeight}
                                                        className="hp-m-auto"
                                                    />
                                                ) : (
                                                    <img
                                                        src={props.image}
                                                        alt={props.title}
                                                        height={props.imageHeight && props.imageHeight}
                                                    />
                                                )}
                                            </Col>
                                        )}

                                        {props.icon && <Col className="hp-mr-16 hp-flex-sm-100 hp-mr-sm-0 hp-mb-sm-8">{props.icon}</Col>}

                                        <Col flex="1 0 0">
                                            <span className="h4 hp-d-block">{props.title && props.title}</span>

                                            <span className="hp-caption hp-text-color-black-80 hp-text-color-dark-30 hp-d-block">
                                                {props.subTitle && props.subTitle}
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={12} span={24}>
                                    {props.info && (
                                        <span className="h5 hp-d-block hp-text-right hp-text-sm-left hp-mt-sm-8">{props.info}</span>
                                    )}
                                </Col>
                            </Row>
                            {props.content && <div className="hp-select-box-item-description">{props.content}</div>}
                            {props.description && (
                                <div className="hp-select-box-item-description">
                                    <Divider className="hp-mt-16 hp-bg-black-40" />

                                    <ul className="hp-caption hp-text-color-black-80 hp-text-color-dark-30 hp-pl-16">
                                        {props.description.map((item, index) => (
                                            <li key={index} className="hp-mb-6">
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}
                </Card>
            </label>
        </div>
    );
};
export default SelectBoxItem;
