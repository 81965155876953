import { Avatar, Col, Row, Skeleton, Statistic, Tooltip } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";
import { StatisticFormatter } from "../../../../view/shared/tileWithStatistics";
import SelectBoxItem from "../selectbox/selectBox";

interface MetricTileType {
    metricName: string | ReactElement;
    metricValue: string | number | ReactElement;
    metricIcon?: { icon: ReactElement; tooltip?: string; className?: string };
    tile_content?: ReactElement;
    loading?: boolean;
    link?: string;
    onClick?: (e: any) => void;
    tooltip?: string;
    id: string;
    icon_content?: ReactElement;
}

export const MetricTile: FunctionComponent<MetricTileType> = observer(
    ({ metricIcon, metricName, metricValue, id, link, icon_content, loading = false, tooltip = null, tile_content = null, onClick }) => {
        const handleDefaultItemContent = () => {
            let icon = icon_content ?? <Avatar className={metricIcon?.className ?? "avatar-with-icon"} icon={metricIcon.icon} size={50} />;

            if (metricIcon?.tooltip) {
                icon = <Tooltip title={metricIcon.tooltip}>{icon}</Tooltip>;
            }

            return (
                <Row gutter={16} align="middle">
                    <Col className="hp-pl-0" span={icon_content ? 8 : 6}>
                        {icon}
                    </Col>

                    <Col className="hp-pr-0" id={`metric_column_${id}`} span={icon_content ? 16 : 18}>
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 1 }} />
                        ) : (
                            <>
                                <h3 className="hp-mb-0" id={`metric_value_${id}`}>
                                    {typeof metricValue === "number" ? (
                                        <Statistic value={metricValue} formatter={StatisticFormatter} />
                                    ) : (
                                        metricValue
                                    )}
                                </h3>
                                <p className="da-p1-body da-mb-0 da-text-color-black-80" id={`metric_name_${id}`}>
                                    {metricName}
                                </p>
                            </>
                        )}
                    </Col>
                </Row>
            );
        };

        let item: ReactElement = null;
        if (tooltip) {
            item = <Tooltip title={tooltip}>{handleDefaultItemContent()}</Tooltip>;
        } else {
            item = tile_content ? tile_content : handleDefaultItemContent();
        }

        return link ? (
            <Link to={link} className="da-p1-body">
                <SelectBoxItem className="metricTile" content={item} />
            </Link>
        ) : (
            <SelectBoxItem className="metricTile" content={item} onClick={onClick} disabled={onClick ? false : true} />
        );
    }
);
export default MetricTile;
