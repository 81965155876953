import { Avatar, Badge, Calendar, Col, List, Popover, Row, Space, Tag, theme, Tooltip, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineFieldTime } from "react-icons/ai";
import { RiCalendar2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";

export const ExecutionsCalendarComponent = () => {
    const store = useStore();
    const history = useHistory();
    const { useToken } = theme;
    const { token } = useToken();
    const [open, setOpen] = useState(false);

    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        async function fetchData() {
            if (!store.croneJobStore.schedules || store.croneJobStore.schedules?.length === 0) {
                return getSchedules();
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setSchedules(store.croneJobStore.schedules ?? []);
    }, [store.croneJobStore.schedules]);

    const isScheduledForToday = useMemo(() => {
        return schedules.find((s: any) => dayjs(s.next_run).isSame(new Date(), "day")) != null;
    }, [schedules]);

    const getSchedules = () => {
        store.croneJobStore.getSchedule({ returned: true }).then((data: any) => {
            setSchedules(data.items);
        });
    };

    const dateCellRender = (value: Dayjs, info: { type: string }) => {
        if (info.type === "date") {
            const scheduledRuns = schedules.filter((s: any) => dayjs(s.next_run).isSame(value, "day"));
            const hasSchedules = scheduledRuns?.length > 0;
            const isToday = value.isSame(new Date(), "day");
            const link = (
                <a onClick={() => history.push("/executions/results#scheduler")} type="text" className={"da-button da-color-inherit"}>
                    {value.format("D")}
                </a>
            );
            const badge = (
                <Badge
                    dot={hasSchedules}
                    className={`da-color-inherit da-badge-height-16 ${hasSchedules && isToday ? "badge-calendar-today" : ""}`}
                >
                    {isToday ? (
                        <Avatar size={"small"} className={"da-m-0"}>
                            {link}
                        </Avatar>
                    ) : (
                        link
                    )}
                </Badge>
            );
            if (hasSchedules) {
                return (
                    <Tooltip
                        overlayInnerStyle={{ width: "max-content", padding: 15 }}
                        autoAdjustOverflow
                        title={scheduleInfo(scheduledRuns)}
                    >
                        {badge}
                    </Tooltip>
                );
            } else {
                return badge;
            }
        } else if (info.type === "month") {
            const scheduledRuns = schedules.filter((s: any) => dayjs(s.next_run).isSame(value, "month"));
            const hasSchedules = scheduledRuns?.length > 0;
            const link = (
                <a onClick={() => history.push("/executions/results#scheduler")} type="text" className={"da-button"}>
                    {value.format("MMM")}
                </a>
            );
            const badge = (
                <>
                    <Badge dot={hasSchedules}>{link}</Badge>
                </>
            );
            if (hasSchedules) {
                return <Tooltip title={scheduleInfo(scheduledRuns, "MONTH")}>{badge}</Tooltip>;
            } else {
                return badge;
            }
        }
    };

    const scheduleInfo = (schedules: any[], mode: "MONTH" | "DAY" = "DAY") => {
        return (
            <Row gutter={[16, 16]}>
                <Col md={24} span={24}>
                    <List
                        size={"small"}
                        dataSource={schedules.slice(0, 3)}
                        renderItem={(item) => {
                            return (
                                <List.Item>
                                    <Space style={{ display: "flex", alignItems: "baseline" }}>
                                        <Typography.Text style={{ color: token.colorWhite }}>
                                            {item.schedule_type === "SYSTEM"
                                                ? item.schedule_id
                                                : item.execution_params?.args?.[0]?.[0]?.execution_name}
                                        </Typography.Text>
                                        <Tag
                                            className={"da-pl-8 da-pr-8 da-ml-8"}
                                            icon={<AiOutlineFieldTime size={20} className={"da-vertical-align-middle da-mr-8"} />}
                                        >
                                            {dayjs(item.next_run).format(mode === "DAY" ? "HH:mm" : "DD-MM-YYYY HH:mm")}
                                        </Tag>
                                    </Space>
                                </List.Item>
                            );
                        }}
                    />
                    {schedules?.length > 3 && (
                        <Typography.Link style={{ marginLeft: 10 }} onClick={() => history.push("/executions/results#scheduler")}>
                            See more
                        </Typography.Link>
                    )}
                </Col>
            </Row>
        );
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const calendar = <Calendar style={{ width: 300 }} fullscreen={false} fullCellRender={dateCellRender} />;

    return (
        <>
            <Popover content={calendar} trigger="click" open={open} onOpenChange={handleOpenChange}>
                <Badge dot={isScheduledForToday} offset={[-10, 10]}>
                    <Avatar className="da-cursor-pointer" icon={<RiCalendar2Fill className="icon-calendar" />} size={40} />
                </Badge>
            </Popover>
        </>
    );
};
