import React from "react";
import { Redirect, Route } from "react-router-dom";
import { AUTH_TOKEN } from "../enums";
import { CustomRouteProps } from "./types";

const PrivateRoute: React.FC<CustomRouteProps> = ({ exact, path, location, store, ...restProps } = {}) => {
    if (localStorage.getItem(AUTH_TOKEN) && !!store.authStore.user) return <Route {...restProps} />;
    return <Redirect to="/login" />;
};

export default PrivateRoute;
