import { Col, Row, theme } from "antd";

export default function PageTitle(props) {
    const { pageTitle, pageText } = props;

    const { useToken } = theme;
    const { token } = useToken();
    return (
        <Col span={24}>
            <Row>
                <Col span={24}>
                    {pageTitle && (
                        <h1 style={{ color: token.colorText }} className="da-mb-8">
                            {pageTitle}
                        </h1>
                    )}
                </Col>

                <Col span={24}>
                    {pageText && (
                        <p style={{ color: token.colorText }} className="title-text da-p1-body">
                            {pageText}
                        </p>
                    )}
                </Col>
            </Row>
        </Col>
    );
}
