export const AUTH_TOKEN = "authToken";
export const MODE = "mode";
export const USER = "user";

export const DB = "DB";
export const SQL = "SQL";
export const SNOWFLAKE = "SNOWFLAKE";
export const ORACLE = "ORACLE";
export const DATABRICKS = "DATABRICKS";
export const TECHNOLOGY = ["etl", "dq", "reconciliation"];
export const TEST_TYPE_TECHNOLOGY = {
    CUSTOM: "custom",
    ETL: "etl",
    DQ: "dq",
    RECON: "reconciliation",
};
export const TASK_TYPE_TECHNOLOGY = {
    "Execute ETL Tests": "etl",
    "Execute CUSTOM Tests": "custom",
    "Execute DQ Tests": "dq",
    "Execute Reconciliation Tests": "reconciliation",
};
export const DB_TECHNOLOGIES = [TEST_TYPE_TECHNOLOGY.ETL, TEST_TYPE_TECHNOLOGY.DQ, TEST_TYPE_TECHNOLOGY.RECON];
export const SQL_DIALECT = {
    GENERAL: "general",
    MYSQL: "my_sql",
    MSSQL: "ms_sql",
    POSTGRESQL: "postgre_sql",
    REDSHIFT: "redshift",
    SNOWFLAKE: "snowflake",
    DATABRICKS: "databricks",
    ORACLE: "oracle",
};
export const DB_CONNECTION_TYPE = ["MYSQL", "MSSQL", "POSTGRESQL", "REDSHIFT", "SNOWFLAKE", "DATABRICKS", "ORACLE"];
export const CONNECTION_TYPE = ["GIT", "DB"];

export const IS_REQUIRED = "is required!";

export const colors = ["#CCAC5C", "#005B4D", "#ACA596", "#A0C1B5", "#2E3E66"];

export const DATA_QUALITY_ATTRIBUTES = [
    { type: "value_count", value: "Unique count" },
    { type: "missing_values", value: "Missing values" },
    { type: "revision", value: "Revision" },
    { type: "average_agg", value: "Average" },
    { type: "avg_length", value: "Average length" },
    { type: "data_type", value: "Data type" },
    { type: "distinct_values", value: "Distinct values" },
    { type: "max_date", value: "Maximum date" },
    { type: "maximum_agg", value: "Maximum" },
    { type: "min_date", value: "Minimum date" },
    { type: "min_length", value: "Minimum length" },
    { type: "max_length", value: "Maximum length" },
    { type: "minimum_agg", value: "Minimum" },
    { type: "missing_values", value: "Missing values" },
    { type: "null_count", value: "Null count" },
    { type: "std_agg", value: "Standard deviation" },
    { type: "sum_agg", value: "Sum" },
    { type: "var_agg", value: "Variance" },
    { type: "zeroes_count", value: "Zero count" },
    { type: "record_count", value: "Record count" },
];

export const DATA_QUALITY_DIMENSIONS = [
    { type: "correctness", value: "Correctness" },
    { type: "consistency", value: "Consistency" },
    { type: "timeliness", value: "Timeliness" },
    { type: "accuracy", value: "Accuracy" },
    { type: "completeness", value: "Completeness" },
    { type: "validity", value: "Validity" },
    { type: "integrity", value: "Integrity" },
    { type: "dq_measurements", value: "DQ Measurements" },
];

export const Task = {
    ETL: "execute_etl_task",
    CUSTOM: "execute_web_task",
    RECON: "execute_data_reconciliation_flow",
    PROFILING: "profile_database",
    METADATA: "gather_database_metadata",
};

export const ScheduleTaskType = {
    TESTS: "execute_robot_task_schedule",
    DAG: "execute_dag_schedule",
    RECON: "execute_db_reconciliation_tests_schedule",
    PROFILING: "profile_db_schedule",
    FULL_FLOW: "gather_global_aggregates_full_flow_schedule",
    METADATA: "gather_db_metadata_schedule",
    GLOBAL_AGGREGATES: "gather_global_aggregates_schedule",
    QUERY_PREDICTIONS: "run_model_retraining_and_prediction_schedule",
};

export const execution_types = ["TEST", "DAG", "TECHNICAL"];

export const execution_type_without_technical = ["TEST", "DAG"];

export const schedule_types = ["TEST", "DAG", "TECHNICAL", "SYSTEM"];

export const PARAMETERS_MODE = {
    ISOLATED: "ISOLATED",
    REPLACE: "REPLACE",
    "PASS OVER": "PASS_OVER",
    "REUSE ENV": "REUSE_ENV",
    "MERGE FORWARD ENV": "MERGE_FORWARD_ENV",
    "FORWARD ENV": "FORWARD_ENV",
};

export function filterParametersModeByValue(params) {
    let parameters_mode = [];
    for (let key in PARAMETERS_MODE) {
        if (PARAMETERS_MODE[key] === params) {
            parameters_mode.push(key);
        }
    }
    return parameters_mode;
}

export const GITModalTypes = {
    EDIT: "edit",
    NEW: "new",
    DUPLICATE: "duplicate",
};
export const RerunType = {
    ONLY_FAILED: "failed_test_case",
    ALL: "all_test_case",
};

export const RuntimeVariablesModalModeType = {
    ADD: "Add",
    EDIT: "Edit",
};

export const ExecutionType = {
    DAG: "DAG",
    TEST: "TEST",
    TECHNICAL: "TECHNICAL",
    DAG_SUBTASK: "DAG_SUBTASK",
};
export const PageType = {
    TestSuite: "test_suite",
    TestCase: "test_case",
    TestApproach: "test_approach",
    Release: "release",
    Rules: "rules",
    Connections: "connection",
    Executions: "executions",
    Schedules: "schedules",
    DqResults: "dq_results",
    DagExecutions: "dag-executions",
    Predictions: "predictions",
    Requirements: "requirements",
    DagTemplates: "dag-templates",
};

export const TagsMode = {
    OR: "OR",
    AND: "AND",
};
export const schedule_types_mode = { TEST: "TEST", DAG: "DAG", TECHNICAL: "TECHNICAL", SYSTEM: "SYSTEM" };
export const DEFAULT_TAGS_COUNT = 10;

export const DQ_TYPE = {
    PREDEFINED: "predefined",
    CUSTOM: "custom",
};

export const WorkflowType = {
    Selected: "selected",
    Edit: "edit",
    Clone: "clone",
};

export const GitConnectionRepositoryType = {
    ADO: "ado",
    BITBUCKET: "bitbucket",
};
