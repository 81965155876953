import { action, makeObservable, observable } from "mobx";
import { DEFAULT_FAILURE_THRESHOLD, DEFAULT_WARNING_THRESHOLD } from "../view/pages/data_quality/utils";
import PaginationStore, { DEFAULT_PAGE_SIZE } from "./paginationStore";

export type SourceType = {
    db_config_id: number | string;
    schema?: string;
    table: string;
};

export type dqRuleType = {
    dq_name: string;
    dq_description: string;
    custom_tags: string[];
    threshold_critical: number;
    threshold_warning: number;
    custom_requirements_ids: any;
    test_suites?: any[];
    test_approaches?: any[];
};

export type SortType = {
    sort_key: string | number;
    column: string;
    direction: "desc" | "asc";
};

export type ParametersType = {
    column?: ColumnType;
    type?: string;
    values?: any[];
    operator?: string;
};

export type AggregationType = {
    key: string | number;
    parameters: ParametersType;
    custom: {
        enabled?: boolean;
        value?: string;
    };
};
export type ColumnType = {
    name: string;
    type: string;
    id: number;
};

export type FilterType = {
    key: string | number;
    parameters: ParametersType;
    custom: {
        enabled?: boolean;
        value?: string;
    };
};

const defaultDqRule = {
    dq_name: null,
    dq_description: null,
    custom_tags: [],
    threshold_warning: DEFAULT_WARNING_THRESHOLD,
    threshold_critical: DEFAULT_FAILURE_THRESHOLD,
    custom_requirements_ids: [],
    test_suites: [],
    test_approaches: []
};

class NewDataQuality extends PaginationStore {
    store;

    error = {
        new: "",
        variants: "",
    };

    DBDQResults = observable([]);
    columns = observable([]);
    source: SourceType = null;
    filters: FilterType[] = observable([]);
    aggregations: AggregationType[] = observable([]);
    dqRule: dqRuleType = defaultDqRule;
    aggregationVariants = observable({});

    constructor(store) {
        super();
        this.store = store;
        makeObservable(this, {
            source: observable,
            dqRule: observable,
            filters: observable,
            aggregations: observable,
        });
    }

    reset() {
        this.setDBDQResults([]);
        this.setNewError("");
        this.setVariantsError('');
        this.setSource(null);
        this.setFilters([]);
        this.setAggregations([]);
        this.setDqRule(defaultDqRule);
        this.store.dataQualityStore.setError("new", "");
    }

    setSource = action((data) => {
        this.source = data;  
    });

    setFilters = action((data) => {
        this.filters = data;
    });

    setAggregations = action((data) => {
        this.aggregations = data;
    });

    setDqRule = action((data) => {
        this.dqRule = data;
    });

    setNewError = (data) => {
        this.error.new = data;
    };

    setVariantsError = (data) => {
        this.error.variants = data;
    };

    setDBDQResults = (data) => {
        this.DBDQResults = data;
    };

    setColumns = (data) => {
        this.columns = data;
    };

    setAggregationVariants = (columnType, data) => {
        this.aggregationVariants[columnType] = data;
    };

    isAggregationValid = (aggregation) => {
        const currentVariant = this.aggregationVariants[aggregation?.parameters?.column?.type]?.find(
            (variant) => variant.type === aggregation.parameters.type
        );

        return Boolean(
            aggregation?.custom?.enabled
                ? aggregation?.custom?.value
                : aggregation?.parameters?.column?.type &&
                      aggregation?.parameters?.type &&
                      (aggregation?.parameters?.values.filter((value) => value || value === 0).length >=
                          currentVariant?.minimum_amount_of_values ||
                          0) &&
                      currentVariant.accepted_operators.includes(aggregation?.parameters?.operator)
        );
    };

    isAggregationsValid = () => {
        return this.aggregations.length > 0 && this.aggregations.every(this.isAggregationValid);
    }

    isThresholdValid = () => {
        const isThresholdsWarningValid = Boolean(this.dqRule.threshold_warning || this.dqRule.threshold_warning === 0);
        const isThresholdsCriticalValid = Boolean(this.dqRule.threshold_critical || this.dqRule.threshold_critical === 0);
        return isThresholdsWarningValid && isThresholdsCriticalValid && this.dqRule.threshold_critical < this.dqRule.threshold_warning;
    };

    getDBConfigsDQResults = action(({ page = 1, size = DEFAULT_PAGE_SIZE, name = "" } = {}) => {
        let url = `/connections/db_with_schema?page=${page}&size=${size}`;

        if (name) {
            url = url + `&name=${name}`;
        }

        return this.store.apiStore
            .get({
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then(async (response) => {
                const data = await response.json();

                this.setDBDQResults(data?.items || []);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);

                return data;
            })
            .catch(async (err) => {
                const errorData = await err.json();
                Promise.reject(errorData?.detail);

                this.setPage(0);
                this.setTotal(0);
                this.setSize(0);
                this.setDBDQResults([]);

                return null;
            });
    });

    getTablesForDbConfig = action(({ dbConfigId = null, schemaName = "", name = "", page = 1, size = DEFAULT_PAGE_SIZE } = {}) => {
        let url = `/connections/db_schema_with_table/${dbConfigId}?page=${page}&size=${size}`;
        if (schemaName) {
            url = url + `&schema_name=${schemaName}`;
        }
        if (name) {
            url = url + `&table_name=${name}`;
        }

        return this.store.apiStore
            .get({
                url: url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .catch(() => null);
    });

    getColumnsForDbConfig = action(({ db_config_id, schema, table, name, page = 1, size = DEFAULT_PAGE_SIZE }) => {
        let url = `/db_meta_results/lookup/${db_config_id}?page=${page}&size=${size}&table=${table}`;

        if (schema) {
            url = url + `&schema=${schema}`;
        }

        if (name) {
            url = url + `&column_name=${name}`;
        }

        return this.store.apiStore
            .get({
                url,
                headers: {
                    "Content-Type": "application/json",
                    accept: "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then(async (response) => {
                const data = await response.json();

                const columnItems = data?.items.map((item) => ({
                    id: item.uid,
                    name: item.column_name,
                    type: item.column_type,
                })) || [];

                this.setColumns(columnItems);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);

                return { ...data, items: columnItems };
            })
            .catch(async (err) => {
                const errorData = await err.json();
                Promise.reject(errorData?.detail);

                this.setPage(0);
                this.setTotal(0);
                this.setSize(0);
                this.setColumns([]);

                return null;
            });
    });

    getAggregationsForColumnType = action((column_type) => {
        this.setVariantsError("");

        return new Promise((resolve, reject) => {
            if (this.aggregationVariants[column_type]) {
                return resolve(this.aggregationVariants[column_type]);
            }

            this.store.apiStore
                .get({
                    url: `/data_quality_v3/variants/database/${column_type}`,
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();
                    this.setAggregationVariants(column_type, data);
                    resolve(data);
                })
                .catch(async (err) => {
                    const errorData = await err.json();
                    this.setVariantsError(errorData?.detail);
                    reject(errorData?.detail);
                });
        });
    });

    getDatabaseDataTypes = action((db_type) => {
        this.setVariantsError("");

        return new Promise((resolve, reject) => {
            this.store.apiStore
                .get({
                    url: `/db_info/db_types/${db_type}`,
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();
                    resolve(data);
                })
                .catch(async (err) => {
                    const errorData = await err.json();
                    this.setVariantsError(errorData?.detail);
                    reject(errorData?.detail);
                });
        });
    });

    getDatabaseOperators = action((db_type, column_type) => {
        this.setVariantsError("");

        return new Promise((resolve, reject) => {
            this.store.apiStore
                .get({
                    url: `/db_info/operators/${db_type}/${column_type}?column_type=${column_type}`,
                    headers: {
                        "Content-Type": "application/json",
                        accept: "application/json",
                    },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then(async (response) => {
                    const data = await response.json();
                    resolve(data);
                })
                .catch(async (err) => {
                    const errorData = await err.json();
                    this.setVariantsError(errorData?.detail);
                    reject(errorData?.detail);
                });
        });
    });

    prepareBodyForNewTestCase = (item) => {
        return JSON.stringify(item);
    };

    createNewDQTest = action(({ preview, payload }) => {
        return this.store.apiStore
            .post({
                url: preview ? "/data_quality_v3/preview_test" : "/data_quality_v3/create_test_v3",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(payload),
            })
            .then((response) => response.json())
            .then((data) => {
                if (preview) {
                    this.store.dataQualityStore.setPreviewDQRule(data);
                } else {
                    this.store.dataQualityStore.setInfo(data);
                }

                return Promise.resolve();
            })
            .catch(async (err) => {
                let errorDetail = [];

                try {
                    errorDetail = (await err.json()).detail;
                } catch (err) {
                    errorDetail = ["Something went wrong"];
                }

                if (errorDetail.length > 0) {
                    if (errorDetail[0].msg) this.store.dataQualityStore.setError("new", errorDetail[0].msg);
                    else if (errorDetail) this.store.dataQualityStore.setError("new", errorDetail);
                }

                if (preview) {
                    this.store.dataQualityStore.setPreviewDQRule({});
                }
                
                return Promise.reject();
            });
    });

    editDQTest = action(({ id, payload }) => {
        return this.store.apiStore
            .put({
                url: `/data_quality_v3/${id}`,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(payload),
            })
            .then((response) => response.json())
            .then(() => Promise.resolve())
            .catch(async (err) => {
                let errorDetail = [];

                try {
                    errorDetail = (await err.json()).detail;
                } catch (err) {
                    errorDetail = ["Something went wrong"];
                }

                if (errorDetail.length > 0) {
                    if (errorDetail[0].msg) this.store.dataQualityStore.setError("edit", errorDetail[0].msg);
                    else if (errorDetail) this.store.dataQualityStore.setError("edit", errorDetail);
                }
                
                return Promise.reject();
            });
    });
}
export default NewDataQuality;
