import { Tag, theme } from "antd";
import { FunctionComponent, useContext } from "react";
import { ThemeContext } from "../../themeProvider";

type TagComponentProps = {
    text: string;
    background?: any;
    style?: any;
};

export const TagTheme: FunctionComponent<TagComponentProps> = ({ text, background, style }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const { isDarkMode } = useContext(ThemeContext);

    let colorStyle = background
        ? { color: isDarkMode ? token.colorTextHeading : token.colorTextLabel, background: background }
        : { color: isDarkMode ? token.colorTextHeading : token.colorTextLabel };
    return (
        <Tag style={{ maxWidth: "100%", whiteSpace: "normal", ...colorStyle, ...style }} key={text}>
            {text}
        </Tag>
    );
};
