import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";
class LineageStore extends PaginationStore {
    store;

    lineage_attributes = observable([]);

    error = {
        new: "",
    };
    constructor(store) {
        super();
        this.store = store;
    }

    setLineageAttribute = (data) => {
        this.lineage_attributes = data;
    };

    setNewError = (err) => {
        this.error.new = err;
    };

    getLineageForAttributes = action(({ attributes, db_config_id }) => {
        let url = `/lineage/by_names?db_config_id=${db_config_id}`;
        if (attributes?.length > 0) {
            let names = attributes.map((it) => `names=${it}`);
            url = `${url}&${names.join("&")}`;
        }
        return this.store.apiStore
            .get({
                url: url,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setLineageAttribute(data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail) || Promise.reject(data?.detail[0])))
            .catch((err) => {
                this.setLineageAttribute([]);
                this.setNewError(err);
            });
    });
}
export default LineageStore;
