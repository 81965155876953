import React from "react";
import { Redirect, Route } from "react-router-dom";
import { CustomRouteProps } from "./types";

const SuperUserRoute: React.FC<CustomRouteProps> = ({ exact, path, location, store, ...restProps } = {}) => {
    if (store.authStore.ifUserIsAnAdmin()) return <Route {...restProps} />;
    return <Redirect to="/" />;
};

export default SuperUserRoute;
