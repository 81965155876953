import dayjs from "dayjs";
import { useState } from "react";
import Chart from "react-apexcharts";

export default function LineChart(props) {
    const { items, names } = props;
    const [data] = useState({
        series: [
            {
                name: names[0],
                data: items.filter((i) => i.status === names[0]).map((e) => e.count),
            },
            {
                name: names[1],
                data: items.filter((i) => i.status === names[1]).map((e) => e.count),
            },
        ],
        options: {
            chart: {
                fontFamily: "Manrope, sans-serif",
                type: "line",
            },
            colors: ["#005B4D", "#c65664"],
            labels: {
                style: {
                    fontSize: "14px",
                },
            },

            dataLabels: {
                enabled: false,
            },

            grid: {
                borderColor: "#DFE6E9",
                row: {
                    opacity: 0.5,
                },
            },
            tooltip: {
                x: {
                    format: "yyyy-MM-dd HH:mm:ss",
                },
            },
            xaxis: {
                type: "datetime",
                title: {
                    text: "Date",
                },
                tickPlacement: "between",
                labels: {
                    datetimeUTC: false,
                    style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                    },
                },
                categories: items.map((it) => {
                    var newDate = dayjs(it.time_sec).format("YYYY-MM-DD HH:mm");
                    return `${newDate}`;
                }),
                forceNiceScale: true,
            },
            legend: {
                horizontalAlign: "right",
                offsetX: 40,
                position: "bottom",
            },
            yaxis: {
                title: {
                    text: "Tests",
                },
                labels: {
                    style: {
                        colors: ["636E72"],
                        fontSize: "14px",
                    },
                    formatter: (value) => {
                        return value;
                    },
                },
                forceNiceScale: true,
            },
        },
    });

    return (
        <div id="chart">
            <Chart options={data.options} series={data.series} type="area" height={350} legend="legend" />
        </div>
    );
}
