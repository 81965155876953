import { Schedule } from "../view/pages/executions/simple/steps/executionSCJ/SchedulerModalComponent";

export const useScheduleValidation = () => {
    const checkScheduleValidation = (schedule: Schedule) => {
        const isNotValid = schedule?.type === "cron" && schedule?.frequency === "daily" && (schedule?.daily?.weekdays?.length ?? 0) === 0;
        const areDatesNotValid =
            (schedule?.type === "cron" && (schedule.startDate == null || schedule.endDate == null)) ||
            (schedule?.type === "date" && schedule.runOnceDate == null);

        return !isNotValid && !areDatesNotValid;
    };

    return { checkScheduleValidation };
};
