import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props {
    items: any[];
    pageCount: number;
    onLoadPage: (page: number) => void;
    children: string | JSX.Element | JSX.Element[];
    isLoading?: boolean;
    height?: string | number;
    style?: any;
    loader?: JSX.Element;
    scrollableTarget?: string;
}

export const InfiniteScrollWrapper: React.FC<Props> = ({
    items,
    pageCount,
    height = "auto",
    onLoadPage,
    children,
    isLoading,
    style,
    scrollableTarget,
    loader = <Spin />,
}) => {
    const [currentPage, setCurrentPage] = useState(1);

    const loadMore = () => {
        if (!isLoading) setCurrentPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        onLoadPage(currentPage);
    }, [currentPage]);

    return (
        <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore={currentPage < pageCount}
            loader={loader}
            height={height}
            style={{ position: "relative", display: "flex", flexDirection: "column", overflowX: "hidden", ...style }}
            scrollableTarget={scrollableTarget}
        >
            {children}
        </InfiniteScroll>
    );
};
