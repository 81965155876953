import { Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { RiFoldersLine, RiGitCommitLine, RiRocketLine } from "react-icons/ri";
import { TiDeleteOutline } from "react-icons/ti";
import MetricTile from "../../layout/components/content/tile/tile";

@inject("store")
@observer
class StatsTileRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                total_execution: true,
                test_cases_count: true,
                releases_count: true,
            },
        };
    }

    componentDidMount = async () => {
        await this.props.store.metricsStore.getReleasesCount().then(() =>
            this.setState({
                loading: { ...this.state.loading, releases_count: false },
            })
        );
        await this.props.store.metricsStore.getTestCaseCount().then(() =>
            this.setState({
                loading: { ...this.state.loading, test_cases_count: false },
            })
        );
        await this.props.store.metricsStore.getTestRulesCount().then(() =>
            this.setState({
                loading: { ...this.state.loading, test_rules_count: false },
            })
        );
    };

    render() {
        let status =
            Object.keys(this.props.store.metricsStore.executions_in_time).length > 0
                ? this.props.store.metricsStore.executions_in_time[0]?.status
                : "No data";
        return (
            <Row align="middle" gutter={[32, 32]} justify="space-between">
                <Col xl={6} sm={12} xs={24}>
                    <MetricTile
                        id="query_predictions"
                        tooltip="Click to see details"
                        loading={this.props.loading.query_predictions}
                        metricValue={this.props.query_predictions?.total > 0 ? this.props.query_predictions.total : "No data"}
                        metricName={"Query Predictions"}
                        metricIcon={{ icon: <RiRocketLine className="remix-icon" /> }}
                        link={"/predictions"}
                    />
                </Col>
                <Col xl={6} sm={12} xs={24}>
                    <MetricTile
                        id="last_executions"
                        tooltip="Click to see details"
                        loading={this.props.execution_in_time}
                        metricValue={status}
                        metricName={"Last Execution Status"}
                        metricIcon={
                            status === "Failed"
                                ? { icon: <TiDeleteOutline className="remix-icon" /> }
                                : { icon: <BiCheckCircle className="remix-icon" /> }
                        }
                        link={"/executions/results"}
                    />
                </Col>
                <Col xl={6} sm={12} xs={24}>
                    <MetricTile
                        id="releases_count"
                        tooltip="Click to see details"
                        loading={this.state.loading.releases_count}
                        metricValue={
                            Object.keys(this.props.store.metricsStore.releases_count).length > 0
                                ? this.props.store.metricsStore.releases_count[0].release_count
                                : "No data"
                        }
                        metricName={"Releases Count"}
                        metricIcon={{ icon: <RiGitCommitLine className="remix-icon" /> }}
                        link={"/release"}
                    />
                </Col>
                <Col xl={6} sm={12} xs={24}>
                    <MetricTile
                        id="test_cases_count"
                        tooltip="Click to see details"
                        loading={this.state.loading.test_cases_count}
                        metricValue={
                            Object.keys(this.props.store.metricsStore.test_case_count).length > 0
                                ? this.props.store.metricsStore.test_case_count.case_count
                                : "No data"
                        }
                        metricName={"Test Cases Count"}
                        metricIcon={{ icon: <RiFoldersLine className="remix-icon" /> }}
                        link={"/tests/cases"}
                    />
                </Col>
            </Row>
        );
    }
}
export default StatsTileRow;
