export const getTestsFromNodeFilters = (nodeFilters = []) => {
    const testSuites = [],
        testCases = [],
        testApproaches = [];

    nodeFilters.forEach(({ test_suites, test_cases, test_approaches }) => {
        if (test_suites?.length) {
            testSuites.push(...test_suites);
        }

        if (test_cases?.length) {
            testCases.push(...test_cases);
        }

        if (test_approaches?.length) {
            testApproaches.push(...test_approaches);
        }
    });

    return { testSuites, testCases, testApproaches };
};
