import { message } from "antd";
import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";
class UsersStore extends PaginationStore {
    store;

    users = observable([]);
    error = {
        view: "",
        new: "",
        edit: "",
        delete: "",
        reset_pass: "",
    };

    constructor(store) {
        super();
        this.store = store;
    }

    setUsers = action((users) => {
        this.users = users;
    });

    setDeleteErrorMessage = action((err) => {
        this.error.delete = err;
    });

    setViewErrorMessage = action((err) => {
        this.error.view = err;
    });

    setNewErrorMessage = action((err) => {
        this.error.new = err;
    });

    setEditErrorMessage = action((err) => {
        this.error.edit = err;
    });

    setNewPassErrorMessage = action((err) => {
        this.error.reset_pass = err;
    });

    getAllUsers() {
        return this.store.apiStore
            .get({
                url: "/users/",
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setUsers(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to get users")))
            .catch((err) => this.setViewErrorMessage(err));
    }

    combineUsersFilters = (filters, type) => {
        let items = [];
        Object.entries(filters).map((it) => {
            if (it[0] === "name") {
                items.push({
                    column: "user.username",
                    is_value_column: false,
                    search_value: `%${filters?.["name"]?.[0]}%`,
                    search_type: "like",
                });
            } else if (it[0] === "id") {
                items.push({ column: "user.id", is_value_column: false, search_value: filters["id"], search_type: "in" });
            }
        });

        return [
            {
                logical_opperand: type ? type : "and",
                items: items,
            },
        ];
    };

    searchUsers = action(({ filters, type = "and", pagination = false, page = 1, size = 10, returned = false }) => {
        let ready_filter = this.combineUsersFilters(filters, type);
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                body: JSON.stringify({
                    search: [{ table_name: "user" }],
                    filters: ready_filter,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) return data;
                if (data) {
                    this.setUsers(data);
                    return data;
                } else {
                    this.setUsers([]);
                    return [];
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    createNewUser = action((username, password, full_name, role_id, email) => {
        return this.store.apiStore
            .post({
                url: "/users/",
                body: JSON.stringify({
                    username: username,
                    password: password,
                    email: email,
                    full_name: full_name,
                    role_id: role_id,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getAllUsers(this.page, this.size);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to create user")))
            .catch((err) => this.setNewErrorMessage(err));
    });

    editUser = action((user_id, form) => {
        let forms = JSON.stringify(form);
        return this.store.apiStore
            .put({
                url: `/users/${user_id}`,
                body: forms,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getAllUsers(this.page, this.size);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to edit user")))
            .catch((err) => this.setEditErrorMessage(err));
    });

    deleteUser = action((user_id) => {
        return this.store.apiStore
            .delete({
                url: `/users/${user_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return this.getAllUsers(this.page, this.size);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to edit user")))
            .catch((err) => this.setDeleteErrorMessage(err));
    });

    changeUserPassword = action((old_pass, new_pass, confirmation) => {
        return this.store.apiStore
            .post({
                url: `/users/user-password-reset`,
                body: JSON.stringify({
                    old_password: old_pass,
                    new_password: new_pass,
                    confirm_password: confirmation,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                message.success(data?.msg);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || data?.msg)))
            .catch((err) => this.setNewPassErrorMessage(err));
    });
}

export default UsersStore;
