const defaultConfig = {
    colorPrimary: "#ccac5c",
    colorPrimaryBg: "#f4eedb",
    colorPrimaryBgHover: "#fcf8eb",
    colorSuccess: "#005b4d",
    colorSuccessBg: "#cedfd9",
    colorWarning: "#ffc212",
    colorError: "#c65664",
    colorInfo: "#949494",
    colorTextBase: "#2d3436",
    colorTextSecondary: "#636e72",
    borderRadius: 8,
    wireframe: false,
    type: "default",
    fontFamily: "Manrope,sans-serif",
    colorTableHover: "#f4f2eb",
    colorTagPrimaryBg: "#f0f3f5",
    colorTagTextPrimary: "#636e72",
};

export default defaultConfig;
