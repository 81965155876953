import { useState } from "react";

import { Button, Layout } from "antd";
import { RiArrowUpLine } from "react-icons/ri";
import ScrollToTop from "react-scroll-up";

import MenuFooter from "./components/footer";
import MenuHeader from "./components/header";
import Sidebar from "./components/menu/Sidebar";

const { Content } = Layout;

export default function VerticalLayout(props) {
    const { children } = props;

    const [visible, setVisible] = useState(false);

    return (
        <>
            <Layout className="da-app-layout">
                <Sidebar visible={visible} setVisible={setVisible} />

                <Layout>
                    <MenuHeader setVisible={setVisible} />

                    <Content className="da-content-main">{children}</Content>

                    <MenuFooter />
                </Layout>
            </Layout>

            <div className="scroll-to-top">
                <ScrollToTop showUnder={300} style={{ bottom: "5%" }}>
                    <Button className="da-primary-shadow scroll-button-top" type="primary" shape="circle" icon={<RiArrowUpLine />} />
                </ScrollToTop>
            </div>
        </>
    );
}
