import { observable, action } from "mobx";

export const DEFAULT_PAGE_SIZE = 50;

class PaginationStore {
    page = observable(1);
    size = observable(DEFAULT_PAGE_SIZE);
    pagination = observable(true);
    total = observable(0);

    setSize = action((size) => {
        this.size = size;
    });

    setPage = action((page) => {
        this.page = page;
    });

    setPagination = action((page) => {
        this.pagination = page;
    });

    setTotal = action((tot) => {
        this.total = tot;
    });
}
export default PaginationStore;
