import { Badge, Button, Card, Col, Divider, Dropdown, Input, Row, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FilterOptions } from "./FilterOptions";
import { findFilter } from "./utils";

type DropdownButtonFilterType = {
    options: any[];
    values: any[];
    buttonLabel: string;
    keyOptions: string;
    loading: boolean;
    onSave: () => any;
    setOptions: (e: any, a: string) => void;
    searchLocal?: boolean;
    getOptions?: (filter_value: any, page?: number) => void;
    radio?: boolean;
    pageCount?: number;
};

export const DropdownButtonFilter: React.FC<DropdownButtonFilterType> = ({
    options,
    values,
    buttonLabel,
    keyOptions,
    loading,
    searchLocal,
    setOptions,
    getOptions,
    onSave,
    radio = false,
    pageCount = 1,
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filterSearch, setFilterSearch] = useState("");

    const handleSearch = (filters: any) => {
        if (!searchLocal) {
            if (getOptions) getOptions(filters["filters"]);
        }
    };

    useEffect(() => {
        let dbKey = keyOptions === "db_connections" ? "db_config_id" : "id";
        handleSearch({
            filters: { name: [], [dbKey]: [] },
        });
    }, []);

    let selectedOptions = findFilter(values, keyOptions);
    let filteredOptions = options?.filter((option) => {
        if (!filterSearch) {
            return option.name;
        }

        return (
            option?.name?.toLowerCase().includes(filterSearch?.toLowerCase()) ||
            option?.value?.toString()?.includes(filterSearch) ||
            option?.friendly_id?.includes(filterSearch)
        );
    });

    return (
        <Spin spinning={loading}>
            <Dropdown
                placement="bottom"
                onOpenChange={(open) => setDropdownOpen(open)}
                open={dropdownOpen}
                key={`${keyOptions}-dropdown`}
                dropdownRender={() => (
                    <Card className={"card-padding-10"} bordered={false} style={{ padding: 10, maxWidth: 500 }}>
                        <Row>
                            <Col>
                                <Input
                                    allowClear
                                    id={`input-${keyOptions}-search`}
                                    value={filterSearch}
                                    placeholder={`Search ${keyOptions.replace("_", " ")}`}
                                    addonBefore={<AiOutlineSearch />}
                                    onChange={(e: any) => {
                                        setFilterSearch(e.target.value);
                                        if (!searchLocal) {
                                            let dbKey = keyOptions === "db_connections" ? "db_config_id" : "id";
                                            if (radio) {
                                                handleSearch({
                                                    filters: { [dbKey]: [e.target.value] },
                                                });
                                            }
                                            handleSearch({
                                                filters: { name: [e.target.value], [dbKey]: [e.target.value] },
                                            });
                                        }
                                    }}
                                />

                                <Divider />
                                <FilterOptions
                                    selectedOptions={selectedOptions}
                                    options={filteredOptions}
                                    isRadio={radio}
                                    loading={loading}
                                    keyOptions={keyOptions}
                                    pageCount={pageCount}
                                    setOptions={setOptions}
                                    onLoadPage={(page) => {
                                        if (getOptions) {
                                            getOptions({ ...(filterSearch && { id: [filterSearch] }) }, page);
                                        }
                                    }}
                                    onChange={() => {
                                        if (searchLocal) {
                                            setFilterSearch("");
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Divider />
                        <Row style={{ justifyContent: "end", marginTop: 20 }}>
                            <Button
                                id={`button-${keyOptions}-reset`}
                                style={{ justifyContent: "end", marginRight: 20 }}
                                onClick={() => {
                                    setFilterSearch("");
                                    setOptions([], keyOptions);

                                    if (!searchLocal) {
                                        let dbKey = keyOptions === "db_connections" ? "db_config_id" : "id";
                                        handleSearch({
                                            filters: { name: [""], [dbKey]: [""] },
                                        });
                                    }

                                    onSave();
                                }}
                            >
                                Reset
                            </Button>

                            <Button
                                id={`button-${keyOptions}-save`}
                                type="primary"
                                onClick={() => {
                                    setDropdownOpen(false);
                                    onSave();
                                }}
                            >
                                Save
                            </Button>
                        </Row>
                    </Card>
                )}
            >
                <Button className={keyOptions}>
                    <Space>
                        <>
                            {buttonLabel}
                            {selectedOptions?.length > 0 && <Badge count={selectedOptions?.length} />}
                        </>
                    </Space>
                </Button>
            </Dropdown>
        </Spin>
    );
};
