import { Button, Input, Space } from "antd";
import { observer } from "mobx-react";
import { FunctionComponent, useEffect, useState } from "react";
import { FilterItemPropsType } from "../types";
import { findFilter } from "../utils";
import { useStore } from "../../../../../stores/StoreContext";
import { useDebouncedValue } from "../../../../../hooks";
import { DropdownButtonFilter } from "../DropdownButtonFilter";
import { TestCase } from "../../../../../view/types/testCaseType";
import { TEST_TYPE_TECHNOLOGY } from "../../../../../enums";
import { TagType } from "../../../../../view/types/tagType";
import { TestSuiteType } from "../../../../../view/types/testSuiteType";
import { TestApproachType } from "../../../../../view/types/testApproachType";
import {
    useTagsOptions,
    useTestApproachOptions,
    useTestCaseOptions,
    useTestSuiteOptions,
    useGitRepoOptions,
    useTestDataParameterOptions,
} from "../hooks";

export const TestCasesFilters: FunctionComponent<FilterItemPropsType> = observer(({ filters, setOptions, getFullResults }) => {
    let execName = findFilter(filters, "name")[0] || "";
    let description = findFilter(filters, "description")[0] || "";

    const store = useStore();
    const [showBasic, setShowBasic] = useState<boolean>(true);

    const { testCases, testCasesIsLoading, fetchTestCaseOptions } = useTestCaseOptions(store);
    const { testSuites, testSuitesIsLoading, fetchTestSuiteOptions } = useTestSuiteOptions(store);
    const { testApproaches, testApproachesIsLoading, fetchTestApproachOptions } = useTestApproachOptions(store);
    const { tags, tagsIsLoading, fetchTagsOptions } = useTagsOptions(store);
    const { repos, reposIsLoading, fetchRepoOptions } = useGitRepoOptions(store);
    const { testDataParameters, testDataParametersIsLoading, fetchTestDataParameterOptions } = useTestDataParameterOptions(store);

    const debouncedExecName = useDebouncedValue(execName, 500);
    const debouncedDescription = useDebouncedValue(description, 500);

    useEffect(() => {
        fetchTestCaseOptions();
        fetchTestSuiteOptions();
        fetchTagsOptions();
        fetchTestApproachOptions();
        fetchRepoOptions();
        fetchTestDataParameterOptions();
    }, []);

    const loadResults = () => getFullResults(filters, 1, 50);

    useEffect(() => {
        if (!filters.length) return;

        loadResults();
    }, [debouncedExecName, debouncedDescription]);

    return (
        <Space wrap style={{ marginBottom: 20 }}>
            <DropdownButtonFilter
                loading={testCasesIsLoading}
                options={testCases.items.map((tc: TestCase) => ({ name: tc.name, value: tc.id, friendly_id: tc.friendly_id }))}
                pageCount={testCases.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"ID"}
                keyOptions={"id"}
                setOptions={(e: any) => setOptions(e, "id")}
                getOptions={fetchTestCaseOptions}
                onSave={loadResults}
            />
            <Input
                value={execName}
                id={"search-test-case-name-input"}
                allowClear
                style={{ padding: 8, width: 300 }}
                placeholder={"Search test case name...."}
                onChange={(e: any) => setOptions([e.target.value], "name")}
            />
            {!showBasic ? (
                <Input
                    value={description}
                    allowClear
                    id={"search-test-case-description-input"}
                    style={{ padding: 8, width: 300 }}
                    placeholder={"Search description...."}
                    onChange={(e: any) => setOptions([e.target.value], "description")}
                />
            ) : null}
            <DropdownButtonFilter
                loading={false}
                options={Object.values(TEST_TYPE_TECHNOLOGY).map((it) => ({
                    name: it?.toUpperCase(),
                    value: it,
                }))}
                values={filters}
                searchLocal={true}
                buttonLabel={"Test type"}
                keyOptions={"test_type"}
                setOptions={(e: any) => setOptions(e, "test_type")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={tagsIsLoading}
                options={tags.items.map((tag: TagType) => ({ name: tag.tag, value: tag.tag, friendly_id: tag.friendly_id }))}
                pageCount={tags.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Tags"}
                keyOptions={"tags"}
                setOptions={(e: any) => setOptions(e, "tags")}
                getOptions={fetchTagsOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testSuitesIsLoading}
                options={testSuites.items.map((tag: TestSuiteType) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testSuites.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Test Suites"}
                keyOptions={"test_suites"}
                setOptions={(e: any) => setOptions(e, "test_suites")}
                getOptions={fetchTestSuiteOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={testApproachesIsLoading}
                options={testApproaches.items.map((tag: TestApproachType) => ({
                    name: tag.name,
                    value: tag.id,
                    friendly_id: tag.friendly_id,
                }))}
                pageCount={testApproaches.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Test Approaches"}
                keyOptions={"test_approaches"}
                setOptions={(e: any) => setOptions(e, "test_approaches")}
                getOptions={fetchTestApproachOptions}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={false}
                options={[
                    { name: "ACTIVE", value: "1" },
                    { name: "INACTIVE", value: "0" },
                ]}
                searchLocal={true}
                values={filters}
                buttonLabel={"Status"}
                keyOptions={"is_active"}
                radio={true}
                setOptions={(e: any) => setOptions(e, "is_active")}
                onSave={loadResults}
            />
            <DropdownButtonFilter
                loading={reposIsLoading}
                options={repos.items.map((it: any) => ({
                    name: it.connection_name,
                    value: it.id,
                    friendly_id: it.friendly_id,
                }))}
                pageCount={repos.pageCount}
                searchLocal={false}
                values={filters}
                buttonLabel={"Git repo"}
                keyOptions={"git_repo"}
                setOptions={(e: any) => setOptions(e, "git_repo")}
                getOptions={fetchRepoOptions}
                onSave={loadResults}
            />

            {!showBasic ? (
                <DropdownButtonFilter
                    loading={testDataParametersIsLoading}
                    options={testDataParameters.items.map((it) => ({
                        name: it.parameter,
                        value: it.parameter,
                        key: it.id,
                    }))}
                    pageCount={testDataParameters.pageCount}
                    searchLocal={false}
                    values={filters}
                    buttonLabel={"Test parameters"}
                    keyOptions={"test_data"}
                    setOptions={(e: any) => setOptions(e, "test_data")}
                    onSave={loadResults}
                    getOptions={fetchTestDataParameterOptions}
                />
            ) : null}

            <Button
                type="link"
                id={"show-more-filter-options"}
                className="da-mr-sm-8 da-mr-16"
                onClick={() => setShowBasic((prevState) => !prevState)}
            >
                {showBasic ? "Show more filters" : "Show less filters"}
            </Button>
        </Space>
    );
});
