import { Typography } from "antd";

function Text(props) {
    const { Text } = Typography;

    return (
        <Text
            style={props.ellipsis ? { width: props.width } : undefined}
            className={props.isLink ? (props.hoverClassName ? props.hoverClassName : "text-hover-link") : "text"}
            id={props.id}
            ellipsis={props.ellipsis}
            onClick={props.onClick}
        >
            {props.text}
        </Text>
    );
}

export default Text;
