import { useState } from "react";
import { GitReposConflictModal } from "../GitReposConflictModal";
import { GitReposConflictContext } from "./useGitReposConflictContext";
import { ReposWithResourceType } from "../types/reposWithResource.type";

export const GitReposConflictModalProvider = ({ children }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [repos, setRepos] = useState<ReposWithResourceType>({});
    const [onGitRepoSubmit, setOnGitRepoSubmit] = useState(() => () => {});

    const openModal = (selectedRepos: ReposWithResourceType, onSubmit: (repoId: number) => Promise<void>) => {
        setIsOpened(true);
        setRepos(selectedRepos);
        setOnGitRepoSubmit(() => onSubmit);
    };

    const closeModal = () => setIsOpened(false);

    return (
        <GitReposConflictContext.Provider value={{ openModal, closeModal }}>
            {children}
            <GitReposConflictModal repos={repos} onSubmit={onGitRepoSubmit} onCancel={closeModal} isOpened={isOpened} />
        </GitReposConflictContext.Provider>
    );
};
