import { Avatar, Card, Col, Row, Statistic, theme } from "antd";
import { FunctionComponent, useState } from "react";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { RiFoldersLine } from "react-icons/ri";
import MetricTile from "../../layout/components/content/tile/tile";

interface TileType {
    id?: string;
    title: string;
    value: number;
}

export const StatisticFormatter = (value: number) => (
    <CountUp duration={1} className="hp-mb-0" style={{ fontSize: 24 }} end={value} separator=" " decimal="," decimals={value % 1 ? 2 : 0} />
);

export const TileWithStatistics: FunctionComponent<TileType> = ({ id, title, value }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const [data] = useState({
        series: [40],
        options: {
            chart: {
                stacked: true,
                toolbar: {
                    show: false,
                },
            },
            grid: {
                show: false,
                padding: {
                    left: -24,
                    right: -24,
                    top: -16,
                    bottom: -16,
                },
            },
            plotOptions: {
                radialBar: {
                    startAngle: 0,
                    endAngle: 360,
                    hollow: {
                        size: "45%",
                    },
                    track: {
                        show: true,
                        background: "#ffffff",
                        strokeWidth: "100%",
                        opacity: 1,
                        margin: 0,
                    },
                    dataLabels: {
                        show: true,
                        value: {
                            fontSize: "14px",
                            color: token.colorTextDescription,
                            fontWeight: 500,
                            offsetY: -11,
                        },
                        total: {
                            show: true,
                            fontSize: "12px",
                            label: "",
                            formatter: function (w: number) {
                                return 40 + "%";
                            },
                        },
                    },
                },
            },
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            colors: [token.colorPrimaryText],
            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                show: false,
                max: 100,
            },
            tooltip: {
                x: {
                    show: false,
                },
            },
        },
    });

    return (
        <>
            <MetricTile
                id="test_cases_count"
                metricValue={123123}
                metricName={"Test Cases Count"}
                metricIcon={{ icon: <RiFoldersLine className="remix-icon" /> }}
                link={"/tests/cases"}
            />
            <Card className="hp-border-color-black-40 hp-border-radius-xxl" bodyStyle={{ padding: 15, paddingLeft: 20 }}>
                <Row gutter={[16, 16]} align="middle">
                    <Col flex="0 0 106px" style={{ height: 80 }}>
                        <div className="hp-h-100 hp-bg-warning-4 hp-bg-color-dark-90 hp-border-radius-xl hp-d-flex-center">
                            <Avatar
                                shape="square"
                                size={80}
                                src={
                                    <Chart
                                        options={data.options}
                                        series={data.series}
                                        height={80}
                                        type="radialBar"
                                        legend="legend"
                                        className="hp-mt-4"
                                        style={{ marginLeft: 2 }}
                                    />
                                }
                            />
                        </div>
                    </Col>

                    <Col flex="1 0 0" className="hp-overflow-hidden">
                        <>
                            <h3 className="hp-mb-0" id={`metric_value_${id}`}>
                                {typeof value === "number" ? <Statistic value={value} formatter={StatisticFormatter} /> : value}
                            </h3>
                            <p className="da-p1-body da-mb-0 da-text-color-black-80" id={`metric_name_${id}`}>
                                {title}
                            </p>
                        </>
                    </Col>
                </Row>
            </Card>
        </>
    );
};
