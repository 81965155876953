import { message } from "antd";
import dayjs from "dayjs";
import { unionBy } from "lodash";
import { action, observable } from "mobx";
import { findFilter } from "../layout/components/content/filters";
import PaginationStore, { DEFAULT_PAGE_SIZE } from "./paginationStore";

const defaultPaginationData = { items: [], total: 0, page: 1, size: DEFAULT_PAGE_SIZE };

class TestsStore extends PaginationStore {
    store;

    errors = {
        sql: "",
    };

    keywords = observable([]);
    sqlQueryMetadata = observable({});

    constructor(store) {
        super();
        this.store = store;
    }

    @action reset() {
        this.testApproaches = observable([]);
        this.errors = {
            sql: "",
        };
        this.expectedResult = observable([]);
        this.sqlQueryMetadata = observable({});
    }

    setSQLQuery = action((query, index = 0, set_status = false) => {
        this.sqlQueryMetadata[index] = {
            data: [],
            status: null,
        };
        if (set_status) {
            this.sqlQueryMetadata[index].data = query;
            this.sqlQueryMetadata[index].status = false;
        } else {
            this.sqlQueryMetadata[index].data = query;
            this.sqlQueryMetadata[index].status = query.values ? true : false;
        }
    });

    setKeywords = action((result) => {
        this.keywords = result;
    });

    setSqlErrorMessage = action((error) => {
        this.errors.sql = error;
    });

    getAllKeywords(page = 1, size = 50) {
        return this.store.apiStore
            .get({
                url: `/keyword/?page=${page}&size=${size}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setKeywords(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
                return data["items"];
            })
            .catch((e) => {
                Promise.reject("Unable to get keywords");
            });
    }

    getConnectionTypeForApi(connection_type) {
        if (connection_type?.toUpperCase() === "MYSQL") {
            return "my_sql";
        } else if (connection_type?.toUpperCase() === "MSSQL") {
            return "ms_sql";
        } else if (connection_type?.toUpperCase() === "ORACLE") {
            return "oracle";
        } else if (connection_type?.toUpperCase() === "POSTGRESQL") {
            return "postgre_sql";
        } else if (connection_type?.toUpperCase() === "REDSHIFT") {
            return "redshift";
        } else if (connection_type?.toUpperCase() === "SNOWFLAKE") {
            return "snowflake";
        } else if (connection_type?.toUpperCase() === "DATABRICKS") {
            return "databricks";
        } else {
            return "general";
        }
    }

    validateSQLParser(sql, db_type, index = 0) {
        this.setSQLQuery({}, index);
        const body = JSON.stringify({
            sql: sql,
            db_type: this.getConnectionTypeForApi(db_type.replace("_", "")),
        });
        return this.store.apiStore
            .post({
                url: "/sql_parser/template?propose_test_approach=true",
                body: body,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSQLQuery(data, index);
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setSQLQuery(err, index, true));
    }

    combineTableForSearch(filters, table, sorter = null) {
        let tables = [];
        if (table === "test_case") {
            tables.push({
                table_name: "test_case",
                order_by: [
                    {
                        parameter: "test_case.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "test_suite") {
            tables.push({
                table_name: "test_suite",
                order_by: [
                    {
                        parameter: "test_suite.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "connection") {
            tables.push({
                table_name: "db_config",
                order_by: [
                    {
                        parameter: "db_config.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "repo") {
            tables.push({
                table_name: "git_repo",
                order_by: [
                    {
                        parameter: "git_repo.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "release") {
            tables.push({
                table_name: "release",
                order_by: [
                    {
                        parameter: "release.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "test_approach") {
            tables.push({
                table_name: "test_approach",
                order_by: [
                    {
                        parameter: "test_approach.id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "requirements") {
            tables.push({
                table_name: "requirements",
                order_by: [
                    {
                        parameter: "requirements.work_item_id",
                        order_type: "desc",
                    },
                ],
            });
        } else if (table === "keyword") {
            tables.push({
                table_name: "keyword",
                order_by: [
                    {
                        parameter: "keyword.id",
                        order_type: "desc",
                    },
                ],
            });
        }
        Object.entries(filters).map((key, value) => {
            if (key[0] === "test_suites") {
                tables.push({
                    table_name: "test_suite",
                    parent: "test_case",
                });
            } else if (key[0] === "test_approaches") {
                tables.push({
                    table_name: "test_approach",
                    parent: "test_case",
                });
            } else if (key[0] === "rule_name") {
                tables.push({
                    table_name: "rule",
                    parent: "test_case",
                });
            } else if (key[0] === "tags") {
                tables.push({
                    table_name: "tag",
                    parent: "test_case",
                });
            } else if (key[0] === "test_data" && table === "test_case") {
                tables.push({
                    table_name: "test_case_keyword",
                    parent: "test_case",
                });
                tables.push({
                    table_name: "test_case_keyword_parameter",
                    parent: "test_case_keyword",
                });
                tables.push({
                    table_name: "rule_parameter",
                    parent: "test_case_keyword_parameter",
                });
            } else if (key[0] === "git_repo" && table === "test_case") {
                tables.push({
                    table_name: "test_suite",
                    parent: "test_case",
                });
                tables.push({
                    table_name: "robot_suite",
                    parent: "test_suite",
                });
                tables.push({
                    table_name: "git_repo",
                    parent: "robot_suite",
                });
            } else if (key[0] === "git_repo" && key[1]?.length && table === "test_suite") {
                tables.push({
                    table_name: "robot_suite",
                    parent: "test_suite",
                });
            }
        });
        return tables;
    }

    combineFiltersForSearch(filters, table, type = null) {
        let filtersReady = [];
        let operants = [];
        Object.entries(filters).map((key) => {
            let items = [];
            if (key[0] === "name" && table === "test_case") {
                key[1].map((val) => {
                    items.push({
                        column: "test_case.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "id" && table === "keyword") {
                key[1].map((val) => {
                    items.push({
                        column: "keyword.id",
                        is_value_column: false,
                        search_value: `${val}`,
                        search_type: "eq",
                    });
                });
            } else if (key[0] === "rule_name") {
                key[1].map((val) => {
                    items.push({
                        column: "rule.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "tags") {
                key[1].map((val) => {
                    items.push({
                        column: "tag.tag",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "description" && table === "test_case") {
                key[1].map((val) => {
                    items.push({
                        column: "test_case.description",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "test_data" && table === "test_case") {
                key[1].map((val) => {
                    items.push({
                        column: "rule_parameter.parameter_value",
                        is_value_column: false,
                        search_value: `${val}`,
                        search_type: "eq",
                    });
                });
            } else if ((key[0] === "id" && table === "test_case") || key[0] === "tc_id") {
                items.push({
                    column: "test_case.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "test_type" && key[1]?.length && table === "test_case") {
                items.push({
                    column: "test_case.test_type",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "git_repo" && key[1]?.length && table === "test_case") {
                items.push({
                    column: "git_repo.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "id" && key[1]?.length && table === "test_suite") {
                items.push({
                    column: "test_suite.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "test_suites") {
                items.push({
                    column: "test_suite.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "names" && table === "test_suite") {
                items.push({
                    column: "test_suite.name",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "git_repo" && table === "test_suite") {
                items.push({
                    column: "robot_suite.git_repo_id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "test_approaches" && table === "test_case") {
                items.push({
                    column: "test_approach.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "test_type" && table === "test_suite") {
                items.push({
                    column: "test_suite.test_type",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "name" && table === "test_suite") {
                key[1].map((val) => {
                    items.push({
                        column: "test_suite.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "id" && table === "test_approach") {
                items.push({
                    column: "test_approach.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "name" && table === "test_approach") {
                key[1].map((val) => {
                    items.push({
                        column: "test_approach.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "connection" || (key[0] === "name" && table === "connection")) {
                key[1].map((val) => {
                    items.push({
                        column: "db_config.connection_name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "connection_ids") {
                items.push({
                    column: "db_config.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "repo") {
                key[1].map((val) => {
                    items.push({
                        column: "git_repo.git_repo_url",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "repo_ids") {
                items.push({
                    column: "git_repo.id",
                    is_value_column: false,
                    search_value: key[1],
                    search_type: "in",
                });
            } else if (key[0] === "release") {
                key[1].map((val) => {
                    items.push({
                        column: "release.name",
                        is_value_column: false,
                        search_value: `%${val}%`,
                        search_type: "like",
                    });
                });
            } else if (key[0] === "is_active") {
                if (table === "test_case") {
                    key[1].map((val) => {
                        items.push({
                            column: "test_case.is_active",
                            is_value_column: false,
                            search_value: `${val !== "0" ? "1" : "0"}`,
                            search_type: "eq",
                        });
                    });
                } else if (table === "test_suite") {
                    key[1].map((val) => {
                        items.push({
                            column: "test_suite.is_active",
                            is_value_column: false,
                            search_value: `${val !== "0" ? "1" : "0"}`,
                            search_type: "eq",
                        });
                    });
                } else if (table === "test_approach") {
                    key[1].map((val) => {
                        items.push({
                            column: "test_approach.is_active",
                            is_value_column: false,
                            search_value: `${val !== "0" ? "1" : "0"}`,
                            search_type: "eq",
                        });
                    });
                }
            }
            filtersReady.push(items);
            if (type && type === "or") {
                operants.push("or");
            } else {
                operants.push("and");
            }
        });
        let logical_opperand = "and";
        if (unionBy(operants).includes("or")) {
            logical_opperand = "or";
        }
        if (filtersReady.length > 1) {
            filters = filtersReady.map((it) => it[0]);
        } else {
            filters = filtersReady.map((it) => it)?.[0];
        }

        const filteredItems = filters?.filter((i) => i);
        return filteredItems ? [{ items: filteredItems, logical_opperand: logical_opperand }] : [];
    }

    combineURL = (page, size, filters) => {
        const dateFormat = "YYYY-MM-DD HH:mm:ss";

        let created_by = findFilter(filters, "users");
        let exec_name = findFilter(filters, "name");
        let db_connection = findFilter(filters, "db_connections");
        let test_suites = findFilter(filters, "test_suites");
        let test_approaches = findFilter(filters, "test_approaches");
        let tags = findFilter(filters, "tags");
        let db_table = findFilter(filters, "db_table");
        let db_schema = findFilter(filters, "db_schema");
        let db_column = findFilter(filters, "db_column");
        let is_active = findFilter(filters, "is_active");
        let aggregation_types = findFilter(filters, "aggregation_type");
        let url = `/test_case/dq/search?page=${page}&size=${size}`;
        let startDate = dayjs(findFilter(filters, "start")).format(dateFormat);
        let endDate = dayjs(findFilter(filters, "end")).format(dateFormat);

        created_by.map((t) => (url = url.concat(`&user_ids=${t}`)));
        test_suites.map((t) => (url = url.concat(`&suite_ids=${t}`)));
        db_connection.map((t) => (url = url.concat(`&connection_ids=${t}`)));
        test_approaches.map((t) => (url = url.concat(`&test_approach_ids=${t}`)));
        tags.map((t) => (url = url.concat(`&tags_ids=${t}`)));
        db_table.map((t) => (url = url.concat(`&tables=${t}`)));
        db_schema.map((t) => (url = url.concat(`&schemas=${t}`)));
        db_column.map((t) => (url = url.concat(`&columns=${t}`)));
        aggregation_types.map((t) => (url = url.concat(`&aggregation_types=${t}`)));

        if (is_active?.length > 0) {
            url = url.concat(`&is_active=${is_active?.[0]}`);
        }

        if (exec_name) {
            url = url.concat(`&name_like=${exec_name}`);
        }

        if (dayjs(startDate).isValid()) {
            url = url.concat(`&run_date_start=${startDate}`);
        }

        if (dayjs(endDate).isValid()) {
            url = url.concat(`&run_date_end=${endDate}`);
        }

        return url;
    };

    searchDQTestCaseData = action(({ page, size, filters }) => {
        let url = this.combineURL(page, size, [filters]);
        return this.store.apiStore
            .get({
                url: url,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.testCaseStore.setTestCases(data["items"]);
                this.store.testCaseStore.setTotal(data.total);
                this.store.testCaseStore.setPage(data["page"]);
                this.store.testCaseStore.setSize(data.size);
                this.store.testCaseStore.setPagination(true);
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => message.error(`Problem with your request: ${err}`));
    });

    searchTestCaseData = action(
        ({ filters, pagination = false, page = 1, size = DEFAULT_PAGE_SIZE, append = false, type = "and", returned = false }) => {
            let filters_dict = {};
            if (Array.isArray(filters)) {
                filters.map((it) => {
                    Object.entries(it).map((i) => (filters_dict[i?.[0]] = i?.[1]));
                    return filters_dict;
                });
            } else if (typeof filters === "object" && filters !== null) {
                filters_dict = filters;
            }

            let filtersToSend = this.combineFiltersForSearch(filters_dict, "test_case", type);
            let tables = this.combineTableForSearch(filters_dict, "test_case");

            if (!filtersToSend?.[0]?.items) {
                filtersToSend = this.combineFiltersForSearch({ name: [""] }, "test_case", type);
            }
            let body_final = {
                search: tables,
                filters: filtersToSend,
                distinct_on: "test_case.id",
            };

            return this.store.apiStore
                .post({
                    url: this.store.searchStore.getSearchUrl({ pagination: pagination, page: page, size: size }),
                    body: JSON.stringify(body_final),
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (pagination) {
                            if (append) {
                                let full_data = [...new Set([...this.store.testCaseStore.testsCases, ...data?.items])];
                                this.store.testCaseStore.setTestCases(full_data);
                            } else {
                                if (returned) {
                                    return data;
                                } else {
                                    this.store.testCaseStore.setTestCases(data["items"]);
                                    this.store.testCaseStore.setTotal(data.total);
                                    this.store.testCaseStore.setPage(data["page"]);
                                    this.store.testCaseStore.setSize(data.size);
                                    this.store.testCaseStore.setPagination(true);
                                }
                            }
                        } else {
                            if (returned) {
                                return data;
                            } else {
                                this.store.testCaseStore.setTestCases(data);
                                this.store.testCaseStore.setTotal(data.length);
                                this.store.testCaseStore.setSize(data.length);
                                this.store.testCaseStore.setPagination(false);
                            }
                        }
                    } else {
                        if (!append) {
                            this.store.testCaseStore.setTestCases([]);
                            this.store.testCaseStore.setTotal(0);
                            this.store.testCaseStore.setSize(DEFAULT_PAGE_SIZE);
                            this.store.testCaseStore.setPagination(false);
                        }
                    }

                    return data || defaultPaginationData;
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
            // .catch(err => this.setNewConnectionError(err))
        }
    );

    searchTestSuiteReadyPayload = (payload, pagination = true, page = 1, size = DEFAULT_PAGE_SIZE, returned = false) => {
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: pagination, page: page, size: size }),
                body: JSON.stringify(payload),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (pagination) {
                        if (returned) {
                            return data;
                        }
                        this.store.testSuiteStore.setTestSuites(data["items"]);
                        this.store.testSuiteStore.setTotal(data.total);
                        this.store.testSuiteStore.setPage(data["page"]);
                        this.store.testSuiteStore.setSize(data.size);
                        this.store.testSuiteStore.setPagination(true);
                    } else {
                        this.store.testSuiteStore.setTestSuites(data);
                        this.store.testSuiteStore.setTotal(data.length);
                        this.store.testSuiteStore.setSize(data.length);
                        this.store.testSuiteStore.setPagination(false);
                        return data;
                    }
                } else {
                    this.store.testSuiteStore.setTestSuites([]);
                    this.store.testSuiteStore.setTotal(0);
                    this.store.testSuiteStore.setSize(DEFAULT_PAGE_SIZE);
                    this.store.testSuiteStore.setPagination(false);
                }
            })
            .catch((err) => {
                this.store.testSuiteStore.setTestSuites([]);
                this.store.testSuiteStore.setTotal(0);
                this.store.testSuiteStore.setSize(DEFAULT_PAGE_SIZE);
                this.store.testSuiteStore.setPagination(false);
            });
        // .catch(err => this.setNewConnectionError(err))
    };

    searchTestSuiteData = ({ filters, pagination = false, returned = false, page = 1, size = DEFAULT_PAGE_SIZE, type = "and" }) => {
        let filters_dict = {};
        if (Array.isArray(filters)) {
            filters.map((it) => {
                Object.entries(it).map((i) => (filters_dict[i?.[0]] = i?.[1]));
                type = "and";
                return filters_dict;
            });
        } else if (typeof filters === "object" && filters !== null) {
            filters_dict = filters;
        }

        let filtersToSend = this.combineFiltersForSearch(filters_dict, "test_suite", type);
        let tables = this.combineTableForSearch(filters_dict, "test_suite");
        let body_final = {
            search: tables,
            filters: filtersToSend,
        };

        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination: pagination, page: page, size: size }),
                body: JSON.stringify(body_final),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) {
                    return data || (pagination ? defaultPaginationData : []);
                }

                if (data) {
                    if (pagination) {
                        this.store.testSuiteStore.setTestSuites(data["items"]);
                        this.store.testSuiteStore.setTotal(data.total);
                        this.store.testSuiteStore.setPage(data["page"]);
                        this.store.testSuiteStore.setSize(data.size);
                        this.store.testSuiteStore.setPagination(true);
                    } else {
                        this.store.testSuiteStore.setTestSuites(data);
                        this.store.testSuiteStore.setTotal(data.length);
                        this.store.testSuiteStore.setSize(data.length);
                        this.store.testSuiteStore.setPagination(false);
                    }
                } else {
                    this.store.testSuiteStore.setTestSuites([]);
                    this.store.testSuiteStore.setTotal(0);
                    this.store.testSuiteStore.setSize(DEFAULT_PAGE_SIZE);
                    this.store.testSuiteStore.setPagination(false);
                }
            })
            .catch((err) => {
                this.store.testSuiteStore.setTestSuites([]);
                this.store.testSuiteStore.setTotal(0);
                this.store.testSuiteStore.setSize(DEFAULT_PAGE_SIZE);
                this.store.testSuiteStore.setPagination(false);
            });
        // .catch(err => this.setNewConnectionError(err))
    };

    searchTestApproachData = ({ filters, pagination = false, page = 1, size = DEFAULT_PAGE_SIZE, returned = false, type = null }) => {
        let filtersToSend = this.combineFiltersForSearch(filters, "test_approach", type);
        let tables = this.combineTableForSearch(filters, "test_approach");
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page: page, size: size }),
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (returned) {
                    return data || (pagination ? defaultPaginationData : []);
                }

                if (data) {
                    if (pagination) {
                        this.store.testApproachStore.setTestApproaches(data["items"]);
                        this.store.testApproachStore.setTotal(data.total);
                        this.store.testApproachStore.setPage(data["page"]);
                        this.store.testApproachStore.setSize(data.size);
                        this.store.testApproachStore.setPagination(true);
                    } else {
                        this.store.testApproachStore.setTestApproaches(data);
                        this.store.testApproachStore.setTotal(data.length);
                        this.store.testApproachStore.setSize(data.length);
                        this.store.testApproachStore.setPagination(false);
                    }
                } else {
                    this.store.testApproachStore.setTestApproaches([]);
                    this.store.testApproachStore.setTotal(0);
                    this.store.testApproachStore.setSize(DEFAULT_PAGE_SIZE);
                    this.store.testApproachStore.setPagination(false);
                }
            })
            .catch((err) => {
                this.store.testApproachStore.setTestApproaches([]);
                this.store.testApproachStore.setTotal(0);
                this.store.testApproachStore.setSize(DEFAULT_PAGE_SIZE);
                this.store.testApproachStore.setPagination(false);
            });
        // .catch(err => this.setNewConnectionError(err))
    };

    searchConnectionData = action((filters, pagination = true, type = "and", page = 1, size = 10) => {
        let filtersToSend = this.combineFiltersForSearch(filters, "connection", type);
        let tables = this.combineTableForSearch(filters, "connection");
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                    pagination: pagination,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (pagination) {
                        this.store.settingsStore.setDatabaseConnection(data.items);
                    } else {
                        this.store.settingsStore.setDatabaseConnection(data);
                    }
                }
                return data;
            })
            .catch((err) => err?.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.store.settingsStore.setNewConnectionError(err));
    });

    searchKeywordData(filters) {
        let filtersToSend = this.combineFiltersForSearch(filters, "keyword");
        let tables = this.combineTableForSearch(filters, "keyword");
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    return data;
                }
            })
            .catch((err) => err?.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.store.settingsStore.setNewConnectionError(err));
    }

    searchRepoData = (filters, pagination = true) => {
        let filtersToSend = this.combineFiltersForSearch(filters, "repo");
        let tables = this.combineTableForSearch(filters, "repo");
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: tables,
                    filters: filtersToSend,
                    pagination: pagination,
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.store.gitStore.setGitConnection(data);
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch(err => this.setNewConnectionError(err))
    };

    searchTestDataParameters = action(({ search_value = "", pagination = true, page = 1, size = 10 }) => {
        return this.store.apiStore
            .post({
                url: this.store.searchStore.getSearchUrl({ pagination, page, size }),
                body: JSON.stringify({
                    search: [{ table_name: "test_case_keyword_parameter" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "test_case_keyword_parameter.parameter",
                                    is_value_column: false,
                                    search_value: `${search_value}`,
                                    search_type: "like",
                                },
                            ],
                        },
                    ],
                    distinct_on: "test_case_keyword_parameter.parameter",
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => data)
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch(err => this.setNewConnectionError(err))
    });
}

export default TestsStore;
