import { Modal, Space, Switch, Tag, Typography } from "antd";
import { FunctionComponent, createContext, useContext, useEffect, useState } from "react";
import { AiOutlineWarning } from "react-icons/ai";
import { useStore } from "../../../../stores/StoreContext";
import { TestCase } from "../../../../view/types/testCaseType";

// TODO: better to move to the separate folder and files

const ModalContext = createContext({});
const useInactiveTestCaseModal = () => useContext(ModalContext);

interface InactiveTestResourcesModalProps {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    onInactiveChanged: (item: TestCase) => void;
    inactiveTestCases: TestCase[];
    open: boolean;
    type: "testSuite" | "testApproach";
}

const InactiveTestResourcesModal: FunctionComponent<InactiveTestResourcesModalProps> = ({
    onConfirm,
    onCancel,
    onInactiveChanged,
    inactiveTestCases = [],
    open = false,
    type = "testApproach",
}) => {
    const [cases, setCases] = useState<TestCase[]>(inactiveTestCases);
    const store = useStore();

    useEffect(() => {
        setCases(inactiveTestCases);
    }, [inactiveTestCases]);
    const handleOk = () => {
        onConfirm && onConfirm();
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const switchTestCaseState = (testCase: TestCase) => {
        testCase.is_active = !testCase.is_active;
        store.testCaseStore.editTestCase(testCase.id, null, testCase.is_active).then(() => {
            onInactiveChanged && onInactiveChanged(testCase);
        });
        setCases([...cases]);
    };

    return (
        <Modal
            title={
                <Space className="da-d-flex-center">
                    <AiOutlineWarning className="da-mr-8 delete-confirm-modal-icon" size={28} />
                    <Typography style={{ fontSize: 16 }}>Some test cases are inactive</Typography>
                </Space>
            }
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={"Continue without inactive tests"}
            okButtonProps={{ danger: true }}
        >
            <>
                {inactiveTestCases.map((tc: TestCase) => {
                    return (
                        <div className="da-d-flex-center da-d-flex-between" key={tc.id}>
                            <div>
                                {((type === "testApproach" ? tc.test_approaches : tc.test_suites) ?? []).map((ta) => {
                                    return <Tag key={ta.id}>{ta.name}</Tag>;
                                })}
                                {tc.name}
                            </div>
                            <div className={"da-mr-32"}>
                                <Switch
                                    size={"small"}
                                    checked={tc.is_active}
                                    checkedChildren="ACTIVE"
                                    className="da-mb-16 switch-button"
                                    unCheckedChildren="INACTIVE"
                                    onClick={() => switchTestCaseState(tc)}
                                />
                            </div>
                        </div>
                    );
                })}
            </>
        </Modal>
    );
};

const InactiveTestResourcesModalProvider = ({ children }) => {
    const [inactiveTestCases, setInactiveTestCases] = useState<TestCase[]>([]);
    const [type, setType] = useState("testApproach");
    const [open, setOpen] = useState(false);
    const [onSuccessAction, setOnSuccessAction] = useState(() => () => Promise.resolve());

    const [onInactiveChangedAction, setOnInactiveChangedAction] = useState(() => () => {});

    const openModal = (
        inactiveTestCases: TestCase[],
        type: string,
        onSuccess: () => Promise<void>,
        onInactiveChanged?: (item: TestCase) => void
    ) => {
        setInactiveTestCases(inactiveTestCases);
        setType(type);
        setOpen(true);
        setOnSuccessAction(() => onSuccess);
        setOnInactiveChangedAction(() => onInactiveChanged);
    };
    const closeModal = () => setOpen(false);

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <InactiveTestResourcesModal
                inactiveTestCases={inactiveTestCases}
                type={type as any}
                open={open}
                onInactiveChanged={onInactiveChangedAction}
                onCancel={closeModal}
                onConfirm={
                    (() => {
                        onSuccessAction && onSuccessAction();
                        closeModal();
                    }) as any
                }
            />
        </ModalContext.Provider>
    );
};

export { InactiveTestResourcesModalProvider, useInactiveTestCaseModal };
