import { useState } from "react";
import { optionsSize } from "../config";

export const useTagsOptions = (store: any) => {
    const [tags, setTags] = useState({ items: [], pageCount: 1 });
    const [tagsIsLoading, setTagsIsLoading] = useState(false);

    const fetchTagsOptions = (optionFilters = {}, page = 1) => {
        if (!tags.items.length) setTagsIsLoading(true);

        store.tagsStore
            .searchTags({ tag_names: optionFilters, save: true, size: optionsSize, page })
            .then((data: any) => {
                if (!data) return;

                setTags((prevTags) => {
                    const prevIds = new Set(prevTags.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTags.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTagsIsLoading(false));
    };

    return { tags, tagsIsLoading, fetchTagsOptions };
};
