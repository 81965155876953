const dev_env = {
    API_URL_V2: "http://localhost:3001/api_v2",
    // API_URL_V2: "https://dev.xautomate.se/backend/",
    API_STATIC: "http://localhost:3001",
    METABASE_SUBPAGE: `http://localhost:8095`,
};

const prod_env = {
    API_STATIC: `${window.location.origin}/main`,
    API_URL_V2: `${window.location.origin}/backend`,
    METABASE_SUBPAGE: `${window.location.origin}/metabase`,
};

let env;
if (process.env.NODE_ENV === "production") {
    env = prod_env;
} else {
    env = dev_env;
}

export default env;
