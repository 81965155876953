import React from "react";
import { Redirect, Route } from "react-router-dom";
import { CustomRouteProps } from "./types";

const PublicRoute: React.FC<CustomRouteProps> = ({ exact, path, location, store, ...restProps } = {}) => {
    if (!store.authStore.token && !store.authStore.user?.username) return <Route {...restProps} />;
    return <Redirect to="/" />;
};

export default PublicRoute;
