import { action, observable } from "mobx";
import PaginationStore from "./paginationStore";

class VariablesStore extends PaginationStore {
    store;

    variables = observable({});
    group_variables = observable([]);

    variables = observable({});
    error = {
        view: "",
    };

    constructor(store) {
        super();
        this.store = store;
    }

    setGroupVariables = action((variables) => {
        this.group_variables = variables;
    });

    setVariables = action((err, id, initial_load) => {
        if (initial_load) {
            this.variables[id] = err;
        } else {
            let items = this.variables[id]?.items;
            this.variables[id] = {
                items: [...items, ...err.items],
                page: err.page,
                size: err.size,
                total: err.total,
            };
        }
    });

    setValueForGroupVariable = (group_id, variable_id, data) => {
        let searchVar = this.variables[group_id]?.items.filter((i) => i.id === variable_id);
        if (searchVar.length === 1) {
            searchVar[0]["variable_value"] = data["variable_value"];
        }
    };

    setNewViewMessage = action((err) => {
        this.error.view = err;
    });

    getGroupVariables = ({ page, size } = { page: 1, size: 50 }) => {
        return this.store.apiStore
            .get({
                url: `/variables_group/?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setGroupVariables(data["items"]);
                this.setPage(data["page"]);
                this.setTotal(data["total"]);
                this.setSize(data["size"]);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to get group variables")))
            .catch((err) => this.setNewViewMessage(err));
    };

    getVariablesForGroup = ({ id, page = 1, size = 10 }) => {
        return this.store.apiStore
            .get({
                url: `/variables_group/${id}/variables?page=${page}&size=${size}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setVariables(data, id, page === 1);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to get group variables and variables")))
            .catch((err) => this.setNewViewMessage(err));
    };

    getValueForVariable = ({ group_id, variable_id }) => {
        return this.store.apiStore
            .get({
                url: `/runtime_variables/${group_id}/${variable_id}/reveal`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setValueForGroupVariable(group_id, variable_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail || "Unable to get group variables")))
            .catch((err) => this.setNewViewMessage(err));
    };

    searchGroupVariableWithVariables = action((names) => {
        return this.store.apiStore
            .post({
                url: "/search/?pagination=true&size=10",
                body: JSON.stringify({
                    search: [{ table_name: "variable_group" }, { table_name: "runtime_variables", parent: "variable_group" }],
                    return_schema: "VariableGroupSchemaWithVariablesWithoutValues",
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "runtime_variables.variable_name",
                                    is_value_column: false,
                                    search_value: names ? `%${names}%` : "%%",
                                    search_type: "like",
                                },
                                {
                                    column: "variable_group.persistent",
                                    is_value_column: false,
                                    search_value: true,
                                    search_type: "eq",
                                },
                            ],
                        },
                    ],
                    distinct_on: "variable_group.id",
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchVariableWithValuesById = action((groups, variables) => {
        let finalPayload = {};
        if (groups?.length > 0) {
            finalPayload["groups"] = groups;
        }
        if (variables?.length > 0) {
            finalPayload["variables"] = variables;
        }

        return this.store.apiStore
            .post({
                url: "/runtime_variables/values",
                body: JSON.stringify(finalPayload),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchVariables = action((names) => {
        if (!names) {
            return;
        }
        return this.store.apiStore
            .post({
                url: "/search/?pagination=true&size=10",
                body: JSON.stringify({
                    search: [{ table_name: "runtime_variables" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "runtime_variables.variable_name",
                                    is_value_column: false,
                                    search_value: `%${names}%`,
                                    search_type: "like",
                                },
                            ],
                        },
                    ],
                    distinct_on: "runtime_variables.variable_name",
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchVariablesById = action((names) => {
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify({
                    search: [{ table_name: "runtime_variables" }],
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "runtime_variables.id",
                                    is_value_column: false,
                                    search_value: names,
                                    search_type: "in",
                                },
                            ],
                        },
                    ],
                    distinct_on: "runtime_variables.id",
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
    });

    searchGroupById = action((id) => {
        if (!id) {
            return;
        }
        return this.store.apiStore
            .post({
                url: "/search/?pagination=true&size=10",
                body: JSON.stringify({
                    search: [{ table_name: "variable_group" }],
                    return_schema: "VariableGroupInDbSchema",
                    filters: [
                        {
                            logical_opperand: "and",
                            items: [
                                {
                                    column: "variable_group.id",
                                    is_value_column: false,
                                    search_value: `${id}`,
                                    search_type: "eq",
                                },
                            ],
                        },
                    ],
                    distinct_on: "variable_group.id",
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then(async (response) => (await response.json())?.items[0])
            .catch(async (err) => (await err.json())?.detail);
    });
}

export default VariablesStore;
