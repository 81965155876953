import dayjs from "dayjs";
import { action, observable } from "mobx";

class MetricsStore {
    store;

    total_executions = observable([]);
    executions_in_time = observable([]);
    test_case_coverage_expected_test_cases_vs_actual = observable([]);
    test_case_count = observable([]);
    test_rules_count = observable([]);
    test_automation_trend = observable([]);
    top_failed_tests = observable([]);
    releases_count = observable([]);
    suites = observable([]);
    error = {};

    constructor(store) {
        this.store = store;
    }

    setTotalExecutions = action((exec) => {
        this.total_executions = exec;
    });

    setTestCaseCoverageExpectedTestCasesVsActual = action((cov) => {
        this.test_case_coverage_expected_test_cases_vs_actual = cov;
    });

    setTestCaseCount = action((count) => {
        this.test_case_count = count;
    });

    setTestRulesCount = action((count) => {
        this.test_rules_count = count;
    });

    setTestExecutionsInTime = action((exec) => {
        this.executions_in_time = exec;
    });

    setTestAutomationTrend = action((exec) => {
        this.test_automation_trend = exec;
    });

    setTopFailedTests = action((exec) => {
        this.top_failed_tests = exec;
    });

    setReleaseCount = action((exec) => {
        this.releases_count = exec;
    });

    setTestSuiteMetric = action((suites) => {
        this.suites = suites;
    });

    setTestSuiteMetricDetails = action((suite_name, suites_details) => {
        let suite = this.suites.filter((it) => it.test_suite_name === suite_name);
        if (suite.length === 1) {
            suite[0]["details"] = suites_details;
        }
    });

    setTestSuiteCaseMetricDetails = action((suite_name, case_name, data) => {
        let suite = this.suites.filter((it) => it.test_suite_name === suite_name);
        if (suite.length === 1) {
            let case_obj = suite[0]["cases"].filter((it) => it.test_case_name === case_name);
            if (case_obj.length === 1) {
                case_obj[0]["details"] = data;
            }
        }
    });

    setTestSuiteRunMetricDetails = action((suite_name, run, data) => {
        let suite = this.suites.filter((it) => it.test_suite_name === suite_name);
        if (suite.length === 1) {
            suite[0]["runs"] = {};
            suite[0]["runs"][run] = data;
        }
    });

    setTestSuiteCases = action((suite_name, cases) => {
        let suite = this.suites.filter((it) => it.test_suite_name === suite_name);
        if (suite.length === 1) {
            suite[0]["cases"] = cases;
        }
    });

    getMetricPayload = (metric_name, date_from = null, test_suite_name = null, test_run = null) => {
        let metric = "";
        let params = {};
        var to_date = new Date().getTime();
        let date_string = `${new Date().getFullYear() - 1}-01-01 00:00:00`;
        var from_date = Math.round(new Date(date_string).getTime() / 1000);
        // eslint-disable-next-line default-case
        switch (metric_name) {
            case "top_5_failed_tests_details":
                metric = "top_5_failed_tests_details";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                };
                break;
            case "automation_test_execution_trend":
                metric = "automation_test_execution_trend";
                let start_date = !date_from
                    ? Math.round(dayjs().startOf("year") / 1000)
                    : Math.round(dayjs(date_from).startOf("year").valueOf() / 1000);
                let end_date = !date_from
                    ? Math.round(dayjs().valueOf() / 1000)
                    : Math.round(dayjs(date_from).endOf("year").valueOf() / 1000);
                params = {
                    from_date: start_date,
                    to_date: end_date,
                };
                break;
            case "total_executions":
                let date_string = `${new Date().getFullYear() - 2}-01-01 00:00:00`;
                var from_date = Math.round(new Date(date_string).getTime() / 1000);
                metric = "total_executions";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                };
                break;
            case "all_executions_time_period":
                metric = "all_executions_time_period";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                };
                break;
            case "test_suite_coverage_expected_test_cases_vs_actual_test_cases":
                metric = "test_suite_coverage_expected_test_cases_vs_actual_test_cases";
                break;
            case "suites":
                metric = "unique_executed_test_suite_names";
                break;
            case "suite_details":
                metric = "test_suite_test_runs";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                    test_suite_name: test_suite_name,
                };
                break;
            case "case_details":
                metric = "test_case_test_runs";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                    test_case_name: test_suite_name,
                };
                break;
            case "suite_case_details":
                metric = "unique_executed_test_case_names_for_test_suite";
                params = {
                    test_suite_name: test_suite_name,
                };
                break;
            case "run_details":
                metric = "single_suite_and_run_details";
                params = {
                    from_date: from_date,
                    to_date: to_date,
                    test_suite_name: test_suite_name,
                    test_run_id: test_run,
                };
                break;
        }

        return { metric_name: metric, metric_params: params };
    };

    getReleasesCount = action(() => {
        return this.store.apiStore
            .get({
                url: `/release/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setReleaseCount([{ release_count: data.total }]);
                } else {
                    this.setReleaseCount([{ release_count: 0 }]);
                }
            })
            .catch((e) => {
                this.setReleaseCount([]);
                Promise.reject("Unable to get avg rate");
            });
    });

    getTopFailedTests = action(() => {
        const final_body = this.getMetricPayload("top_5_failed_tests_details");
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTopFailedTests(data);
            })
            .catch((e) => {
                this.setTopFailedTests([]);
                Promise.reject("Unable to get total executions");
            });
    });

    getExecutionsTrend = action((date_from = null) => {
        const final_body = this.getMetricPayload("automation_test_execution_trend", date_from);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestAutomationTrend(data);
            })
            .catch((e) => {
                this.setTestAutomationTrend([]);
                Promise.reject("Unable to get total executions");
            });
    });

    getExecutionsInTime = action(() => {
        const final_body = this.getMetricPayload("all_executions_time_period");
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestExecutionsInTime(data);
            })
            .catch((e) => {
                this.setTotalExecutions([]);
                Promise.reject("Unable to get executions in time");
            });
    });

    getTestCaseCount = action(() => {
        return this.store.apiStore
            .get({
                url: `/test_case/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) this.setTestCaseCount({ case_count: data.total });
            })
            .catch((e) => {
                this.setTestCaseCount([]);
                Promise.reject("Unable to get test cases count");
            });
    });

    getTestRulesCount = action(() => {
        return this.store.apiStore
            .get({
                url: `/rule/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) this.setTestRulesCount({ rules_count: data.total });
            })
            .catch((e) => {
                this.setTestCaseCount([]);
                Promise.reject("Unable to get test rules count");
            });
    });

    getTestSuiteCoverageExpectedTestCasesVsActual = action(() => {
        const final_body = this.getMetricPayload("test_suite_coverage_expected_test_cases_vs_actual_test_cases");
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestCaseCoverageExpectedTestCasesVsActual(data);
            })
            .catch((e) => {
                this.setTestCasePerAttributeCoverage([]);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getSuites = action(() => {
        const final_body = this.getMetricPayload("suites");
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setTestSuiteMetric(data);
                }
            })
            .catch((e) => {
                this.setTestSuiteMetric([]);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getSuiteDetails = action((test_suite_name) => {
        const final_body = this.getMetricPayload("suite_details", null, test_suite_name);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestSuiteMetricDetails(test_suite_name, data);
            })
            .catch((e) => {
                this.setTestSuiteMetricDetails(test_suite_name, []);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getRunDetails = action((test_suite_name, test_run) => {
        const final_body = this.getMetricPayload("run_details", null, test_suite_name, test_run);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestSuiteRunMetricDetails(test_suite_name, test_run, data);
            })
            .catch((e) => {
                this.setTestSuiteRunMetricDetails(test_suite_name, test_run, []);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getCaseDetails = action((test_suite_name, test_case_name) => {
        const final_body = this.getMetricPayload("case_details", null, test_case_name);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestSuiteCaseMetricDetails(test_suite_name, test_case_name, data);
            })
            .catch((e) => {
                this.setTestSuiteCaseMetricDetails(test_suite_name, test_case_name, []);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getSuiteCasesDetails = action((test_suite_name) => {
        const final_body = this.getMetricPayload("suite_case_details", null, test_suite_name);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTestSuiteCases(test_suite_name, data);
            })
            .catch((e) => {
                this.setTestSuiteMetricDetails(test_suite_name, []);
                Promise.reject("Unable to get test cases coverage attributes");
            });
    });

    getTestCaseKeywords = action(({ execution_id, suite_id, test_run_id, test_id }) => {
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    metric_name: "robot_parent_keywords",
                    metric_params: {
                        test_run_id: test_run_id,
                        suite_id: suite_id,
                        test_id: test_id,
                    },
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.tasksStore.setCaseKeywordsLiveResults(execution_id, suite_id, test_id, data);
            })
            .catch((e) => {
                this.store.tasksStore.setCaseKeywordsLiveResults(execution_id, suite_id, test_id, []);
                Promise.reject("Unable to get test cases live keywords ");
            });
    });
    getTestCaseKeywordLogMessage = action(({ test_run_id, test_id, suite_id, execution_id, execution_path }) => {
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    metric_name: "log_messages",
                    metric_params: {
                        test_run_id: test_run_id,
                        test_id: test_id,
                        suite_id: suite_id,
                        execution_path: execution_path,
                    },
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.tasksStore.setCaseKeywordsLogLiveResults(execution_id, test_run_id, suite_id, test_id, execution_path, data);
            })
            .catch((e) => {
                Promise.reject("Unable to get test cases live log ");
            });
    });
}

export default MetricsStore;
