import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { action, observable } from "mobx";
import { findFilter } from "../layout/components/content/filters";
import PaginationStore from "./paginationStore";

class TasksStore extends PaginationStore {
    store;

    tasks = observable([]);
    live_results = observable({});

    error = {
        zip: "",
        view: "",
    };

    lastTask = "";

    constructor(store) {
        super();
        this.store = store;
    }

    setTasks = action((tasks, part = false) => {
        if (!part) {
            this.tasks = tasks;
        } else {
            tasks.map((it) => {
                let filtered_task = this.tasks.find((task) => task.id === it.id);
                if (filtered_task) {
                    filtered_task.status = it.status;
                    filtered_task.start = it.start;
                    filtered_task.celery_task = it.celery_task;
                    filtered_task.elapsed = it.elapsed;
                    filtered_task.execution_bugs_count = it.execution_bugs_count;
                    filtered_task.results = it.results;
                    filtered_task.directory = it.directory;
                    filtered_task.variable_group_id = it.variable_group_id;
                    filtered_task.defects = it.defects;
                }
            });
        }
    });

    setBugs = action((execution_id, val) => {
        let task = this.tasks.filter((it) => it.id === execution_id);
        if (task.length === 1) {
            task[0].bugs = val;
        }
    });

    setSuiteLiveResults = action((execution_id, data) => {
        this.live_results[execution_id] = data;
    });

    setDefectTypeLiveResults = action((data) => {
        data.map((item) => {
            let task = this.tasks.filter((it) => it.id === item.execution_id);
            if (task.length === 1) {
                if (!task[0].defects) {
                    task[0].defects = { suggested: {}, accepted: {} };
                }
                task[0].defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
            }
        });
    });

    setDefectTypeSuitesLiveResults = action((execution_id, data) => {
        data.map((item) => {
            let suite = this.live_results[execution_id]?.filter((it) => it.suite_id === item.suite_id);
            if (suite.length === 1) {
                if (!suite[0].defects) {
                    suite[0].defects = { suggested: {}, accepted: {} };
                }
                suite[0].defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
            }
        });
    });

    setDefectTypeCasesLiveResults = action((execution_id, data) => {
        data.map((item) => {
            let suite = this.live_results[execution_id]?.filter((it) => it.suite_id === item.suite_id);
            if (suite.length === 1) {
                suite[0]?.test_cases.map((test) => {
                    if (test.id === item.test_id) {
                        if (!test.defects) {
                            test.defects = { suggested: {}, accepted: {} };
                        }
                        test.defects[item.suggested ? "suggested" : "accepted"][item.type_of_defect] = item.count;
                    }
                });
            }
        });
    });

    setCaseLiveResults = action((execution_id, suite_id, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            suite[0].test_cases = data;
        }
    });

    setCaseKeywordsLiveResults = action((execution_id, suite_id, case_id, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            let tcase = suite[0].test_cases.filter((it) => it.test_id === case_id);
            if (tcase.length === 1) {
                tcase[0].keywords = data;
            }
        }
    });

    setCaseKeywordsLogLiveResults = action((execution_id, test_run_id, suite_id, case_id, execution_path, data) => {
        let suite = this.live_results[execution_id].filter((it) => it.suite_id === suite_id);
        if (suite.length === 1) {
            let tcase = suite[0].test_cases.filter((it) => it.test_id === case_id);
            if (tcase.length === 1) {
                let keyword = tcase[0].keywords.find((a) => a.test_run_id === test_run_id && a.execution_path === execution_path);
                if (keyword) {
                    keyword.logs = data;
                }
            }
        }
    });

    setLiveCountedResults = action((data, exec_ids) => {
        exec_ids.map((item) => {
            let full_source = this.tasks.filter((it) => it.id === parseInt(item));
            let data_to_fill = data.filter((it) => parseInt(it.execution_id) === item);
            if (full_source.length === 1 && data && data_to_fill.length > 0) {
                full_source[0].live_counted_results = {
                    passed: data_to_fill[0].passed,
                    failed: data_to_fill[0].failed,
                    total: data_to_fill[0].total,
                    not_run: data_to_fill[0].not_run,
                    running: data_to_fill[0].running,
                    skipped: data_to_fill[0].skipped,
                };
            }
        });
    });

    setLastTask = action((value) => {
        this.lastTask = value;
    });

    setErrorZipMessage = action((err) => {
        this.error.zip = err;
    });

    setErrorViewMessage = action((err) => {
        this.error.view = err;
    });

    getZipFileForExecutions = (item) => {
        return this.store.apiStore
            .saveFile({
                url: `/tasks/result/${item.id}`,
                headers: this.store.authStore.getAuthHeader(),
            })
            .then((res) => res.blob())
            .then((data) => {
                saveAs(data, `results-${item.name}`);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorZipMessage(err));
    };

    getResultsByList = action((executions_ids, returned = false) => {
        return this.store.apiStore
            .post({
                url: `/execution/list`,
                body: JSON.stringify(executions_ids),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (!returned) {
                    this.setTasks(data, true);
                } else {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    combineURL = (start, end, page, size, filters) => {
        let release_ids = findFilter(filters, "release");
        let created_by = findFilter(filters, "users");
        let exec_name = findFilter(filters, "execution_name");
        let status = findFilter(filters, "statuses");
        let types = findFilter(filters, "type");
        let db_connection = findFilter(filters, "db_connections");
        let git_repos = findFilter(filters, "git_repo");
        let test_suites = findFilter(filters, "test_suites");
        let test_approaches = findFilter(filters, "test_approaches");
        let test_cases = findFilter(filters, "test_cases");
        let url = `/execution/?page=${page}&size=${size}`;
        let bugs_only = findFilter(filters, "bugs_only");
        if (start && end) {
            url = `/execution/?from_date=${start}&to_date=${end}&page=${page}&size=${size}`;
        }
        types.map((t) => (url = url.concat(`&execution_types=${t}`)));
        status.map((t) => (url = url.concat(`&statuses=${t}`)));
        created_by.map((t) => (url = url.concat(`&user_ids=${t}`)));
        release_ids.map((t) => (url = url.concat(`&release_ids=${t}`)));
        test_suites.map((t) => (url = url.concat(`&suite_ids=${t}`)));
        db_connection.map((t) => (url = url.concat(`&connection_ids=${t}`)));
        git_repos.map((t) => (url = url.concat(`&git_repo_ids=${t}`)));
        test_approaches.map((t) => (url = url.concat(`&test_approach_ids=${t}`)));
        test_cases.map((t) => (url = url.concat(`&test_case_ids=${t}`)));
        if (bugs_only?.[0] && bugs_only?.[0] !== "0") {
            url = url.concat(`&with_reported_bugs_only=${bugs_only}`);
        }
        if (exec_name !== []) {
            url = url.concat(`&execution_name_like=${exec_name}`);
        }
        return url;
    };

    searchExecutions = action(({ filters, page = 1, size = 10 }) => {
        return this.store.apiStore
            .get({
                url: this.combineURL(null, null, page, size, filters),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getResults = action(({ filters, page = 1, size = 10 }) => {
        let start_date = findFilter(filters, "start");
        let end_date = findFilter(filters, "end");
        let start = Math.round(dayjs(start_date).valueOf() / 1000);
        let end = Math.round(dayjs(end_date).valueOf() / 1000);
        if (isNaN(start)) {
            start = Math.round(dayjs(dayjs().subtract(7, "days").startOf("day")).valueOf() / 1000);
        }
        if (isNaN(end)) {
            end = Math.round(dayjs(dayjs().endOf("day")).valueOf() / 1000);
        }

        return this.store.apiStore
            .get({
                url: this.combineURL(start, end, page, size, filters),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTasks(data["items"]);
                this.setPage(data.page);
                this.setTotal(data.total);
                this.setSize(data.size);
                this.setPagination(true);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getMetricExecutionsPayload = (metric_name, params = {}) => {
        return { metric_name: metric_name, metric_params: params };
    };

    getExecutionsMetric = action((metric_name, params) => {
        const final_body = this.getMetricExecutionsPayload(metric_name, params);
        return this.store.apiStore
            .post({
                url: `/metrics/`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify(final_body),
            })
            .then((response) => response.json())
            .then((data) => {
                if (metric_name === "defect_types_count_execution") {
                    this.setDefectTypeLiveResults(data);
                }
                if (metric_name === "defect_types_count_suite") {
                    this.setDefectTypeSuitesLiveResults(params["execution_id"], data);
                }
                if (metric_name === "defect_types_count_test_case") {
                    this.setDefectTypeCasesLiveResults(params["execution_id"], data);
                } else {
                    return data;
                }
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get executions metric");
            });
    });

    combineDQURLRequest = (page, size, filters) => {
        let tc_ids = findFilter(filters, "tc_ids");
        let status = findFilter(filters, "dq_test_results");
        let url = `/dq_stats/?page=${page}&size=${size}`;

        status.map((t) => (url = url.concat(`&dq_test_results=${t}`)));
        tc_ids.map((t) => (url = url.concat(`&dq_check_ids=${t}`)));

        return url;
    };

    getDQResults = action(({ filters, page = 1, size = 10 }) => {
        return this.store.apiStore
            .get({
                url: this.combineDQURLRequest(page, size, filters),
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch((err) => this.setErrorViewMessage(err));
    });

    getLiveSuiteResults = action((execution_id) => {
        return this.store.apiStore
            .get({
                url: `/metrics/live_test_suites_results_for_execution?execution_id=${execution_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSuiteLiveResults(execution_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getLiveCaseResults = action(({ execution_id, suite_id } = {}) => {
        return this.store.apiStore
            .get({
                url: `/metrics/live_test_case_results_for_suite_execution?execution_id=${execution_id}&suite_id=${suite_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setCaseLiveResults(execution_id, suite_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorViewMessage(err));
    });

    getCountedLiveResults = action((hashes) => {
        return this.store.apiStore
            .post({
                url: "/metrics/live_test_results_status_for_executions",
                body: JSON.stringify(hashes),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    this.setLiveCountedResults(data, hashes);
                    return data;
                }
            });
    });

    getExecutionBugs = action((execution_id) => {
        return this.store.apiStore
            .get({
                url: `/reported_bugs/by_execution/${execution_id}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setBugs(execution_id, data);
                return data;
            })
            .catch((e) => {
                Promise.reject("Unable to get bugs");
                this.setBugs(execution_id, []);
            });
    });
}

export default TasksStore;
