import { Avatar, Button, Col, Divider, Row, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import { RiLogoutBoxLine } from "react-icons/ri";

@inject("store")
@observer
class MenuFooter extends React.Component {
    handleLogout = () => {
        this.props.store.authStore.removeAuthToken();
        this.props.store.authStore.setUser(null);
    };

    render() {
        return (
            <Row className="da-sidebar-footer da-px-24" align="middle" justify="space-between">
                <Divider className="da-border-color-black-20 da-mt-0" />

                <Col>
                    <Row align="middle">
                        <Col className="da-d-flex-center da-mr-4">
                            <Space>
                                <Button type="link" onClick={() => this.handleLogout()} style={{ fontSize: "larger" }}>
                                    <Avatar
                                        className="da-cursor-pointer"
                                        style={{ marginRight: 10 }}
                                        icon={<RiLogoutBoxLine style={{ marginTop: 7 }} size={25} className="icon-logout remix-icon" />}
                                        size={40}
                                    />
                                    <Typography.Text>Logout</Typography.Text>
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default MenuFooter;
