import { useState } from "react";
import Chart from "react-apexcharts";

export default function ColumnChart(props) {
    const { categories, expected_data, actual_data } = props;

    const [data] = useState({
        series: [
            {
                name: "Expected",
                data: expected_data,
            },
            {
                name: "Actual",
                data: actual_data,
            },
        ],
        options: {
            colors: ["#005B4D", "#ccac5c"],
            chart: {
                fontFamily: "Manrope, sans-serif",
                type: "bar",

                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: true,
                },
            },
            labels: {
                style: {
                    fontSize: "14px",
                },
            },

            dataLabels: {
                enabled: false,
            },

            grid: {
                borderColor: "#DFE6E9",
                row: {
                    opacity: 0.5,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 2,
                    columnWidth: "45%",
                    endingShape: "rounded",
                },
            },

            stroke: {
                show: true,
                width: 4,
                colors: ["transparent"],
            },
            xaxis: {
                title: {
                    text: "Test Suite",
                },
                axisTicks: {
                    show: false,
                    borderType: "solid",
                    color: "#005B4D",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                },
                tickPlacement: "on",
                labels: {
                    style: {
                        colors: ["#636E72"],
                        fontSize: "14px",
                    },
                    formatter: (value) => {
                        return value?.length > 15 ? value.substring(0, 15) + "..." : value;
                    },
                },
                categories: categories,
            },
            tooltip: {
                custom: (value) => {
                    let text = categories[value.dataPointIndex];
                    let serie = value.seriesIndex === 0 ? "Expected" : "Actual";
                    let val = value.series[value.seriesIndex][value.dataPointIndex];
                    return (
                        '<div class="apexcharts-tooltip-title" style="font-family: Manrope, sans-serif; font-size: 12px;"> ' +
                        text +
                        ' </div> \
  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex">' +
                        '<span class="apexcharts-tooltip-marker" style="background-color: #ccac5c;"></span>' +
                        '<span class="apexcharts-tooltip-text" style="font-family: Manrope, sans-serif; font-size: 12px;"/>' +
                        '<div class="apexcharts-tooltip-y-group"> \
          <span class="apexcharts-tooltip-text-y-label">' +
                        serie +
                        '</span> \
          <span class="apexcharts-tooltip-text-y-value">' +
                        val +
                        " test cases</span> \
      </div> \
  </div>"
                    );
                },
            },
            legend: {
                horizontalAlign: "right",
                offsetX: 40,
                position: "top",
                markers: {
                    radius: 12,
                },
            },
            yaxis: {
                title: {
                    text: "Test Case",
                },
                labels: {
                    style: {
                        colors: ["#636E72"],
                        fontSize: "14px",
                    },
                    formatter: (value) => {
                        return value + " cases";
                    },
                },

                min: 0,
                max: Math.max(...expected_data),
                tickAmount: 4,
            },
        },
    });

    return (
        <div id="chart" style={expected_data.length > 10 ? { overflowX: "auto", overflowY: "hidden" } : {}}>
            <Chart
                options={data.options}
                width={expected_data.length > 10 ? expected_data.length * 130 : "100%"}
                series={data.series}
                type="bar"
                height={400}
                legend="legend"
            />
        </div>
    );
}
