import dayjs from 'dayjs';
import { useState } from "react";
import { DB_TECHNOLOGIES, TEST_TYPE_TECHNOLOGY } from "../enums";

export const useHandleExecutionForm = (store: any, form: any) => {
    const [technology, setTechnology] = useState(store.executionStore.technology);

    const handleRelease = (releaseId: number) => {
        store.executionStore.setRelease(releaseId);
        let release = store.releaseStore.releases.find((release) => release.id === releaseId);
        let executionNameFull = "";

        if (release) {
            executionNameFull =
                store.executionStore.execution_name instanceof String
                    ? `${store.executionStore.execution_name}-${release.name.replace(" ", "_")}-${dayjs().format("YYYY-MM-DD-HH-mm")}`
                    : `${release.name.replace(" ", "_")}-${dayjs().format("YYYY-MM-DD-HH-mm-ss")}`;

            store.executionStore.setExecutionName(executionNameFull);
        }

        form.setFieldsValue({ execution_name: executionNameFull, release: releaseId });
    };

    const handleTechnology = (currentTechnology) => {
        setTechnology(currentTechnology);
        store.executionStore.setTechnology(currentTechnology);
        form.setFieldsValue({ technology: store.executionStore.technology });

        if (currentTechnology.includes(TEST_TYPE_TECHNOLOGY.CUSTOM) && store.gitStore.repos.length === 1) {
            store.webExecutionStore.setRepo(store.gitStore.repos[0].id);
            form.setFieldsValue({ git_repository: store.gitStore.repos[0].id });
            form.setFieldsValue({ connection: null });
        } else if (!currentTechnology.includes(TEST_TYPE_TECHNOLOGY.CUSTOM)) {
            store.webExecutionStore.setRepo(null);
            form.setFieldsValue({ git_repository: null });
        }
    };

    const handleConnection = (connection) => {
        store.dbExecutionStore.setConnection(connection);
        form.setFieldsValue({ connection });
    };

    const handleReconcilitation = (rec_connection) => {
        store.dbExecutionStore.setReconciliationConnection(rec_connection);
        form.setFieldsValue({ rec_connection });
    };

    const handleGitRepo = (git_repository) => {
        store.webExecutionStore.setRepo(git_repository);
        form.setFieldsValue({ git_repository });
    };

    const handleExecutionName = (execution_name) => {
        store.executionStore.setExecutionName(execution_name);
        form.setFieldsValue({ execution_name });
    };

    const handleDBTechnologies = () => {
        const defaultDbConnection = store.settingsStore.getDefaultDbConnection();

        if (!store.dbExecutionStore.connection && defaultDbConnection) {
            store.dbExecutionStore.setConnection(defaultDbConnection.db_config_id);
            form.setFieldsValue({ connection: defaultDbConnection.db_config_id });
        }
        if (
            !store.dbExecutionStore.reconciliationConnection &&
            store.executionStore.technology.includes(TEST_TYPE_TECHNOLOGY.RECON) &&
            defaultDbConnection
        ) {
            store.dbExecutionStore.setReconciliationConnection(defaultDbConnection.db_config_id);
            form.setFieldsValue({ rec_connection: defaultDbConnection.db_config_id });
        }
    };

    const handleExecutionForm = (field) => {
        let fieldObj = Object.entries(field);
        let fieldKey = fieldObj[0][0];
        let fieldValue = fieldObj[0][1];

        if (fieldKey === "release") {
            handleRelease(fieldValue as number);
        } else if (fieldKey === "technology") {
            handleTechnology(fieldValue);
        } else if (fieldKey === "connection") {
            handleConnection(fieldValue);
        } else if (fieldKey === "rec_connection") {
            handleReconcilitation(fieldValue);
        } else if (fieldKey === "git_repository") {
            handleGitRepo(fieldValue);
        } else if (fieldKey === "execution_name") {
            handleExecutionName(fieldValue);
        }

        if (store.executionStore.technology.some((r) => DB_TECHNOLOGIES.includes(r))) {
            handleDBTechnologies();
        }
    };

    const resetFormValues = () => {
        handleTechnology([]);
        handleRelease(null);
        handleConnection(null);
        handleReconcilitation(null);
        handleGitRepo(null);
        handleExecutionName(null);
        store.executionStore.setTags([]);
        store.dbExecutionStore.setRuntimeVariables([]);
        store.dbExecutionStore.setGroupVariables(null);
    };

    return {
        handleExecutionForm,
        handleTechnology,
        handleRelease,
        handleConnection,
        handleReconcilitation,
        handleGitRepo,
        handleExecutionName,
        handleDBTechnologies,
        resetFormValues,
        technology,
    };
};
