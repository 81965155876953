import { action, observable } from "mobx";

class ReconciliationStore {
    store;
    release = observable("");
    source = observable("");
    target = observable("");
    include = observable([]);
    exclude = observable([]);
    generate_only = observable(false);
    execution_name = observable("");
    comparison_object_schema = observable("");
    comparison_object = observable("");
    target_object_schema = observable("");
    target_comparison_object = observable("");
    source_advanced_object = observable("");
    target_advanced_object = observable("");
    target_filter = observable({ include: [], exclude: [] });
    source_filter = observable({ include: [], exclude: [] });

    profiling_results = observable({});
    error = {
        new: "",
        profiling: "",
    };
    info = "";

    constructor(store) {
        this.store = store;
    }

    setTargetFilter = (key, data) => {
        this.target_filter[key] = data;
    };

    setSourceFilter = (key, data) => {
        this.source_filter[key] = data;
    };

    reset = () => {
        this.release = "";
        this.source = "";
        this.target = "";
        this.comparison_object_schema = "";
        this.comparison_object = "";
        this.target_comparison_object = "";
        this.target_object_schema = "";
        this.include = [];
        this.exclude = [];
        this.execution_name = "";
        this.target_advanced_object = "";
        this.source_advanced_object = "";
        this.generate_only = false;
    };

    setRelease = (data) => {
        this.release = data;
    };

    setSource = (data) => {
        this.source = data;
    };

    setTarget = (data) => {
        this.target = data;
    };

    setInclude = (data) => {
        this.include = data;
    };

    setExclude = (data) => {
        this.exclude = data;
    };

    setOnlyGeneration = (data) => {
        this.generate_only = data;
    };

    setExecutionName = (data) => {
        this.execution_name = data;
    };

    setError = (key, err) => {
        if (err[0]?.msg) {
            this.error[key] = err[0].msg;
        } else {
            this.error[key] = err;
        }
    };

    setInfo = (info) => {
        this.info = info;
    };

    setComparisonObject = (data) => {
        this.comparison_object = data;
    };

    setProfilingData = (id, data) => {
        this.profiling_results[id] = data;
    };

    resetProfilingData = (id) => {
        this.profiling_results[id] = {};
    };

    setTargetComparisonObject = (data) => {
        this.target_comparison_object = data;
    };

    setTargetObjectSchema = (data) => {
        this.target_object_schema = data;
    };

    setComparisonObjectSchema = (data) => {
        this.comparison_object_schema = data;
    };
    setAdvancedSourceObject = (data) => {
        this.source_advanced_object = data;
    };
    setAdvancedTargetObject = (data) => {
        this.target_advanced_object = data;
    };

    createNewReconciliation = action(() => {
        let comp_obj;
        let t_comp_obj;
        if (this.source_advanced_object.value_ === "" || this.source_advanced_object === "") {
            comp_obj = `${this.comparison_object_schema}.${this.comparison_object}`;
        } else {
            comp_obj = this.source_advanced_object;
        }
        if (this.target_advanced_object.value_ === "" || this.target_advanced_object === "") {
            t_comp_obj = `${this.target_object_schema}.${this.target_comparison_object}`;
        } else {
            t_comp_obj = this.target_advanced_object;
        }
        return this.store.apiStore
            .post({
                url: "/tasks/reconcile",

                body: JSON.stringify({
                    release_id: this.release,
                    execution_name: this.execution_name,
                    generate_only: this.generate_only,
                    reconciliation_settings: {
                        source_db_config_id: this.source,
                        target_db_config_id: this.target,
                        comparison_object: comp_obj,
                        target_comparison_object: t_comp_obj,
                        exclude: this.source_filter.exclude,
                        include: this.source_filter.include,
                        target_exclude: this.target_filter.exclude,
                        target_include: this.target_filter.include,
                    },
                }),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setInfo(data["task_id"]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError("new", err));
    });

    runProfiling = action(({ db_config, schema, object, advanced_obj, is_advanced, include = [], exclude = [] }) => {
        let obj = advanced_obj;
        let body;
        if (!is_advanced) {
            obj = `${schema}.${object}`;
        }
        if (!include && !exclude) {
            body = {
                db_config_id: db_config,
                include: [obj],
                exclude: [],
            };
        } else {
            body = {
                db_config_id: db_config,
                include: include,
                exclude: exclude,
            };
        }
        return this.store.apiStore
            .post({
                url: "/db_profiling/",

                body: JSON.stringify(body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "profiling");
                this.store.intervalStore.registerWatcher();
                return this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `PROFILING Submit`,
                    status: "SUCCESS",
                    type: "PROFILING",
                    link: "/settings/connections",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setError("profiling", err));
    });

    getProfileResults = action((db_config, table, page = 1) => {
        let url = table ? `/db_profiling/${db_config}?table=${table}&page=${page}` : `/db_profiling/${db_config}`;
        return this.store.apiStore
            .get({
                url: `${url}`,
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data) {
                    if (page === 1) {
                        this.setProfilingData(db_config, {
                            items: data["items"],
                            page: data["page"],
                            total: data["total"],
                            size: data["size"],
                            pagination: true,
                        });
                    } else {
                        const existingData = this.profiling_results[db_config];
                        this.setProfilingData(db_config, {
                            items: existingData.items.concat(data["items"]),
                            page: data["page"],
                            total: data["total"],
                            size: data["size"],
                            pagination: true,
                        });
                    }
                }
            })
            .catch((e) => {
                Promise.reject(`Unable to get profiling results for connection ${db_config} and table ${table}`);
            });
    });
}
export default ReconciliationStore;
