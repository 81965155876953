import { unionBy } from "lodash";
import { useState } from "react";

export const useAggregationOptions = (store: any) => {
    const [aggregations, setAggregations] = useState([]);
    const [aggregationsIsLoading, setAggregationsIsLoading] = useState(false);

    const fetchAggregationsOptions = () => {
        if (!aggregations.length) setAggregationsIsLoading(true);

        store.dataQualityStore
            .getAllVariants()
            .then((data: any) => {
                if (!data) return;
                setAggregations([...unionBy(data, "type")]);
            })
            .finally(() => setAggregationsIsLoading(false));
    };

    return { aggregations, aggregationsIsLoading, fetchAggregationsOptions };
};
