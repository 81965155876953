import { useState } from "react";

export const useConnectionData = (store: any) => {
    const [connection, setConnection] = useState(null);

    const getConnectionDataById = async (dbId: string | number) => {
        const currentConnection =
            store.settingsStore.databases.find((item) => item.db_config_id === Number(dbId)) ||
            (await store.testsStore.searchConnectionData({ connection_ids: [dbId] }, false))[0];

        setConnection(currentConnection);

        return currentConnection;
    };

    return { connection, getConnectionDataById };
};
