import { ConfigProvider, theme } from "antd";
import React, { useState } from "react";
import darkConfig from "./configs/darkConfig";
import defaultConfig from "./configs/lightConfig";
import { MODE } from "./enums";

export const getDarkMode = () => localStorage.getItem(MODE);

export const darkTheme = localStorage.getItem(MODE) === "dark" || false;

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
    const [isDarkMode, setIsDarkMode] = useState(getDarkMode() === "dark" || false);

    const setModeTheme = (e) => {
        setIsDarkMode(e);
        if (e) {
            localStorage.setItem(MODE, "dark");
        } else {
            localStorage.setItem(MODE, "light");
        }
    };

    const themeConfig = isDarkMode ? darkConfig : defaultConfig;
    return (
        <ThemeContext.Provider value={{ isDarkMode, setModeTheme }}>
            <ConfigProvider
                theme={{
                    prefixCls: isDarkMode ? "dark" : "da",
                    hashed: false,
                    algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                    token: themeConfig,
                    components: {
                        Message: {
                            colorBgContainer: themeConfig.colorTextBase,
                        },
                        Badge: {
                            colorError: themeConfig.colorPrimary,
                        },
                        Table: {
                            colorIcon: themeConfig.colorTextBase,
                        },
                        Tag: {
                            colorText: themeConfig.colorTagTextPrimary,
                            colorFillQuaternary: themeConfig.colorTagPrimaryBg,
                        },
                        Tooltip: {
                            colorTextLightSolid: isDarkMode ? themeConfig.colorTextBase : themeConfig.colorTableHover,
                        },
                    },
                }}
            >
                {children}
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};
