import { message } from "antd";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/index.css";
import "./assets/icons/remixicon.css";

ReactDOM.render(<App />, document.getElementById("root"));
window.addEventListener("error", (e) => {
    if (/Loading chunk [\d]+ failed/.test(e.message)) {
        message.info("We discover new app version...");
        window.location.reload();
    }
});
