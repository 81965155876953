export const ExecutionStatusInternal = {
    FAILED: "FAILED",
    PASSED: "PASSED",
    QUEUED: "QUEUED",
    RUNNING: "RUNNING",
    CANCELED: "CANCELED",
    ERROR: "ERROR",
    FINISHED: "FINISHED",
    ACTIVE: "ACTIVE",
    UNKNOWN: "UNKNOWN",
    WARNING: "WARNING",
};

export const ExecutionCeleryStatus = {
    FAILURE: "FAILURE",
    REVOKED: "REVOKED",
    QUEUED: "QUEUED",
    RUNNING: "RUNNING",
    CANCELED: "CANCELED",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
    UNKNOWN: "UNKNOWN",
};
