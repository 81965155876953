// Routes Imports
import PrivatePages from "./PrivatePages";
import PublicPages from "./PublicPages";
import SuperUserPages from "./SuperUserPages";
// Merge Routes
const PrivateRoutes = [...PrivatePages];
const PublicRoutes = [...PublicPages];
const SuperUserPagesRoutes = [...SuperUserPages];

export { PrivateRoutes, PublicRoutes, SuperUserPagesRoutes };
