import { RadialBar } from "@ant-design/plots";
import { Empty } from "antd";
import { useStore } from "../../stores/StoreContext";
export const RadialBarChart = (props) => {
    const store = useStore();

    let data = Number.isInteger(Math.round(store.dbMetaResults.statistics[props.connection_select]?.matched))
        ? [
              {
                  name: "Covered",
                  value: store.dbMetaResults.statistics[props.connection_select].matched,
              },
              {
                  name: "To be covered",
                  value: store.dbMetaResults.statistics[props.connection_select].unmatched,
              },
              {
                  name: "Unique",
                  value: store.dbMetaResults.statistics[props.connection_select].unique,
              },
              {
                  name: "Total",
                  value: store.dbMetaResults.statistics[props.connection_select].total,
              },
          ]
        : [];

    const config = {
        data,
        xField: "name",
        yField: "value",
        maxAngle: 270,
        radius: 0.8,
        innerRadius: 0.2,
        xAxis: {
            label: {
                style: {
                    fontSize: 16,
                },
                formatter: (item) => {
                    let full = data.filter((i) => i.name === item);
                    if (full.length > 0) {
                        return `${item}: ${full[0]["value"]}`;
                    } else {
                        return `${item}`;
                    }
                },
            },
        },

        tooltip: {
            showTitle: false,
            formatter: (datum) => {
                return {
                    name: datum.name,
                    value: datum.value,
                };
            },
        },

        colorField: "name",
        color: ({ name }) => {
            if (name?.toLowerCase() === "covered") {
                return "#005B4D";
            } else if (name?.toLowerCase() === "to be covered") {
                return "#c65664";
            } else if (name?.toLowerCase() === "unique") {
                return "#2e3e66";
            } else if (name?.toLowerCase() === "total") {
                return "#CCAC5C";
            }
        },
    };
    return data.length > 0 ? <RadialBar className="radial_bar" {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
};
