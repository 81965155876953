import { Avatar, Col, Segmented } from "antd";
import { observer } from "mobx-react";
import { useContext } from "react";
import { BiCheckCircle, BiFile, BiHelpCircle } from "react-icons/bi";
import { CiLight } from "react-icons/ci";
import { GoSettings } from "react-icons/go";
import { ImFileEmpty } from "react-icons/im";
import { IoIosNotifications } from "react-icons/io";
import { MdOutlineDarkMode, MdOutlineNotes } from "react-icons/md";
import { RiFolderUserFill, RiNodeTree, RiSettings4Fill, RiUserFill } from "react-icons/ri";
import { SiSwagger } from "react-icons/si";
import { TiDeleteOutline, TiDocumentText } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import { ThemeContext } from "../../../themeProvider";
import { HeaderNotifications } from "./HeaderNotifications";

export const HeaderUser = observer(() => {
    const store = useStore();
    const handleClear = async () => {
        await store.intervalStore.resetNotifications();
    };

    const { isDarkMode, setModeTheme } = useContext(ThemeContext);
    const toggleTheme = () => {
        setModeTheme(!isDarkMode);
    };

    const menu = [
        {
            icon: <RiNodeTree />,
            title: "Connections",
            subtitle: "Databases & git connection setups",
            link: "/settings/connections",
            admin: false,
        },
        {
            icon: <RiFolderUserFill />,
            title: "User management",
            link: "/settings/users",
            admin: true,
        },
        {
            icon: <GoSettings />,
            title: "Application settings",
            link: "/settings",
            admin: true,
        },
        {
            icon: <BiFile />,
            title: "Get daily support bundle",
            subtitle: "Download app support bundle",
            support: true,
            admin: false,
        },
    ];

    const docs_menu = [
        {
            icon: <TiDocumentText />,
            title: "UI documentation",
            subtitle: "Documentation based on robot tests",
            link: `/docs/requirements`,
            external: true,
        },
        {
            icon: <MdOutlineNotes />,
            title: "Release notes",
            subtitle: "Latest change",
            link: `/docs/changelog`,
            external: true,
        },
        {
            icon: <TiDocumentText />,
            title: "User Manual",
            subtitle: "Tips & tricks",
            link: `/docs/user_manual`,
            external: true,
        },
        {
            icon: <SiSwagger />,
            title: "API documentation",
            subtitle: "API swagger documentation",
            link: `/main/docs`,
            external: true,
        },
    ];

    const notification_menu =
        store.intervalStore.notifications.length > 0
            ? store.intervalStore.notifications.map((item) => {
                return {
                    ...item,
                    icon: item.status !== "SUCCESS" ? <TiDeleteOutline /> : <BiCheckCircle />,
                    title: `${item.content} - ${item.status}`,
                    subtitle: item.task,
                };
              })
            : [
                  {
                      icon: <ImFileEmpty />,
                      title: "No new notifications",
                  },
              ];
    const admSettings = store.authStore.ifUserIsAnAdmin() ? (
        <Col className="da-d-flex-center da-mr-4">
            <HeaderNotifications
                icon={<RiSettings4Fill className="remix-icon settings-icon" />}
                id="settings-header-item"
                title="Settings"
                dropdown={menu}
            />
        </Col>
    ) : (
        <Col className="da-d-flex-center da-mr-4">
            <HeaderNotifications
                icon={<RiSettings4Fill className="remix-icon settings-icon" />}
                id="settings-header-item"
                title="Settings"
                dropdown={menu.filter((i) => !i.admin)}
            />
        </Col>
    );

    return (
        <Col className="da-d-flex-center" style={{ justifyContent: "end", display: "-webkit-flex", WebkitJustifyContent: "flex-end" }}>
            <Col style={{ marginRight: 20 }}>
                <Segmented
                    size="large"
                    onChange={toggleTheme}
                    value={isDarkMode}
                    options={[
                        {
                            value: false,
                            icon: <CiLight />,
                        },
                        {
                            value: true,
                            icon: <MdOutlineDarkMode />,
                        },
                    ]}
                />
            </Col>
            <Col className="da-d-flex-center da-mr-8">
                <HeaderNotifications
                    icon={<BiHelpCircle className="remix-icon settings-icon" />}
                    id="documentation-header-item"
                    title="Documentation"
                    dropdown={docs_menu}
                />
            </Col>
            {admSettings}
            <Col className="da-d-flex-center da-mr-4">
                <HeaderNotifications
                    icon={<IoIosNotifications className="remix-icon settings-icon" />}
                    id="settings-header-notifications"
                    title="Notifications"
                    dropdown={notification_menu}
                    badge={store.intervalStore.notifications.length > 0}
                    clear={true}
                    handleClear={handleClear}
                />
            </Col>
            <Link to="/profile/information">
                <Avatar icon={<RiUserFill />} id="profile-header-item" size={50} />
            </Link>
        </Col>
    );
});

export default HeaderUser;
