import { message } from "antd";
import { action, observable } from "mobx";
import PaginationStore, { DEFAULT_PAGE_SIZE } from "./paginationStore";
import { getUrlPart } from "./utils/storeUtils";
class MLPredictions extends PaginationStore {
    store;
    query_prediction = observable({
        review: {},
        reject: {},
        accept: {},
    });
    query_suggestion = observable({});

    constructor(store) {
        super();
        this.store = store;
    }

    reset = () => {
        this.query_prediction = {
            review: {},
            reject: {},
            accept: {},
        };
    };

    error = {
        getQuery: "",
        train: "",
        retrain: "",
        predict: "",
    };

    setErrorMessage = (property, err) => {
        this.error[property] = err;
    };

    setQuerySuggestion = (db_id, value) => {
        this.query_suggestion[db_id] = value;
    };

    setQueryPredictions = action((db_id, rel, status) => {
        this.query_prediction[status][db_id] = rel;
    });

    setQueryPredictionsWithApproach = action((db_id, approach_id, status, rel) => {
        if (!this.query_prediction[status][db_id]) {
            this.query_prediction[status][db_id] = observable({});
        }
        this.query_prediction[status][db_id][approach_id] = rel;
    });

    getApproachPrediction = action(({ db_config_id, approach_id, page = 1, size = 50, column, table, schema } = {}) => {
        const url = `/query_prediction/${db_config_id}?approach_id=${approach_id}&page=${page}&size=${size}&status=review${getUrlPart(
            "column_name",
            column
        )}${getUrlPart("table_name", table)}${getUrlPart("schema_name", schema)}`;
        return this.store.apiStore
            .get({
                url: url,
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (page === 1) {
                    this.setQueryPredictionsWithApproach(db_config_id, approach_id, "review", data["items"]);
                    this.setPage(data["page"]);
                    this.setPagination(true);
                    this.setSize(data["size"]);
                    this.setTotal(data["total"]);
                } else {
                    this.setQueryPredictionsWithApproach(
                        db_config_id,
                        approach_id,
                        "review",
                        this.query_prediction["review"][db_config_id][approach_id].concat(data["items"])
                    );
                    this.setPage(data["page"]);
                    this.setPagination(true);
                    this.setSize(data["size"]);
                    this.setTotal(data["total"]);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => {
                this.setQueryPredictionsWithApproach(db_config_id, approach_id, "review", []);
                this.setErrorMessage("getQuery", err);
            });
    });

    getQueryPredictions = action(
        ({ db_config_id, status = "review", page = 1, size = 50, returned = false, suggest = false, column, table, schema } = {}) => {
            const url = `/query_prediction/${db_config_id}?page=${page}&size=${size}&status=${status}${getUrlPart(
                "column_name",
                column
            )}${getUrlPart("table_name", table)}${getUrlPart("schema_name", schema)}`;
            return this.store.apiStore
                .get({
                    url: url,
                    headers: { accept: "application/json", "Content-Type": "application/json" },
                    auth_headers: this.store.authStore.getAuthHeader(),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (returned) {
                        return data;
                    } else if (suggest) {
                        return this.setQuerySuggestion(db_config_id, data);
                    } else {
                        if (page === 1) {
                            this.setPage(data["page"]);
                            this.setPagination(true);
                            this.setSize(data["size"]);
                            this.setTotal(data["total"]);
                            return this.setQueryPredictions(db_config_id, data["items"], status);
                        } else {
                            this.setPage(data["page"]);
                            this.setPagination(true);
                            this.setSize(data["size"]);
                            this.setTotal(data["total"]);
                            return this.setQueryPredictions(
                                db_config_id,
                                this.query_prediction[status][db_config_id].concat(data["items"]),
                                status
                            );
                        }
                    }
                })
                .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
                .catch((err) => {
                    this.setErrorMessage("getQuery", err);
                });
        }
    );

    trainModel = (db_config_id) => {
        return this.store.apiStore
            .post({
                url: `/query_prediction/train_and_predict`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    common_settings: {
                        feature_columns: [
                            "schema_name",
                            "table_name",
                            "column_name",
                            "schema_name_2",
                            "table_name_2",
                            "column_name_2",
                            "data_type",
                            "unique",
                            "monotonicity",
                            "zeros_count",
                            "missing_values",
                        ],
                        target_column: "expected_rules",
                        model_path: "QUERY_PREDICTION_MODEL/model",
                        vocabulary_path: "QUERY_PREDICTION_MODEL/vocabulary.json",
                        plot_charts: false,
                    },
                    training_settings: {
                        db_config_ids: [],
                        batch_size: 128,
                        train_test_split_ratio: 0.2,
                        test_val_split_ratio: 0.5,
                        shuffle_size: 4096,
                        epochs: 10,
                        lr: 0.05,
                        lr_factor: 0.8,
                        lr_patience: 2,
                    },
                    prediction_settings: {
                        db_config_id: db_config_id,
                        batch_size: 128,
                        score_threshold: 0.8,
                    },
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "ML TRAINING & PREDICTION");
                this.store.intervalStore.registerWatcher();
                this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `ML Training & Prediction Submit`,
                    status: "SUCCESS",
                    type: "AI/ML",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("train", err));
    };

    reTrainModel = (db_config_id) => {
        return this.store.apiStore
            .post({
                url: `/query_prediction/retrain_and_predict`,

                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({
                    common_settings: {
                        feature_columns: [
                            "schema_name",
                            "table_name",
                            "column_name",
                            "schema_name_2",
                            "table_name_2",
                            "column_name_2",
                            "data_type",
                            "unique",
                            "monotonicity",
                            "zeros_count",
                            "missing_values",
                        ],
                        target_column: "expected_rules",
                        model_path: "QUERY_PREDICTION_MODEL/model",
                        vocabulary_path: "QUERY_PREDICTION_MODEL/vocabulary.json",
                        plot_charts: false,
                    },
                    training_settings: {
                        db_config_ids: [],
                        batch_size: 128,
                        train_test_split_ratio: 0.2,
                        test_val_split_ratio: 0.5,
                        shuffle_size: 4096,
                        epochs: 10,
                        lr: 0.05,
                        lr_factor: 0.8,
                        lr_patience: 2,
                    },
                    prediction_settings: {
                        db_config_id: db_config_id,
                        batch_size: 128,
                        score_threshold: 0.8,
                    },
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.updateTaskUpdator(data["task_id"], "ML RETRAINING & PREDICTION");
                this.store.intervalStore.registerWatcher();
                this.store.intervalStore.pushNew({
                    task: data["task_id"],
                    content: `ML Retraining & Prediction Submit`,
                    status: "SUCCESS",
                    type: "AI/ML",
                });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("retrain", err));
    };

    getAllPredictiedQueriesModel = () => {
        return this.store.apiStore
            .get({
                url: `/query_prediction/`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("predict", err));
    };

    acceptQueries = (query_id) => {
        const body = JSON.stringify({
            query_prediction_id: query_id,
        });
        return this.store.apiStore
            .post({
                url: "/query_prediction/accept",
                body: body,

                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.pushNew({ task: "", content: `AI/ML Query - Accepted! `, status: "SUCCESS", type: "AI/ML" });
                message.success({ content: `Accepted!`, duration: 1, key: "queries" });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("accept", err));
    };

    rejectQueries = (query_id) => {
        const body = JSON.stringify({
            query_prediction_id: query_id,
        });
        return this.store.apiStore
            .post({
                url: "/query_prediction/reject",
                body: body,

                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.store.intervalStore.pushNew({ task: "", content: `AI/ML Query - Rejected! `, status: "SUCCESS", type: "AI/ML" });
                message.success({ content: `Rejected!`, duration: 1, key: "queries" });
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("reject", err));
    };

    searchForPredictionsColumns = ({ dbConfigId, columnName, tableName, schema }) => {
        return Promise.allSettled([
            this.searchForPredictions({
                search: [
                    {
                        table_name: "db_query_predictions",
                    },
                ],
                filters: [
                    {
                        logical_opperand: "and",
                        items: [
                            ...(schema != null
                                ? [
                                      {
                                          column: "db_query_predictions.schema_name",
                                          is_value_column: false,
                                          search_value: schema,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            ...(tableName != null
                                ? [
                                      {
                                          column: "db_query_predictions.table_name",
                                          is_value_column: false,
                                          search_value: tableName,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            {
                                column: "db_query_predictions.column_name",
                                is_value_column: false,
                                search_value: `%${columnName}%`,
                                search_type: "like",
                            },
                            {
                                column: "db_query_predictions.db_config_id",
                                is_value_column: false,
                                search_value: `${dbConfigId}`,
                                search_type: "eq",
                            },
                        ],
                    },
                ],
                distinct_on: "db_query_predictions.column_name",
            }),
            this.searchForPredictions({
                search: [
                    {
                        table_name: "db_query_predictions",
                    },
                ],
                filters: [
                    {
                        logical_opperand: "and",
                        items: [
                            ...(schema != null
                                ? [
                                      {
                                          column: "db_query_predictions.schema_name_2",
                                          is_value_column: false,
                                          search_value: schema,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            ...(tableName != null
                                ? [
                                      {
                                          column: "db_query_predictions.table_name_2",
                                          is_value_column: false,
                                          search_value: tableName,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            {
                                column: "db_query_predictions.column_name_2",
                                is_value_column: false,
                                search_value: `%${columnName}%`,
                                search_type: "like",
                            },
                            {
                                column: "db_query_predictions.db_config_id",
                                is_value_column: false,
                                search_value: `${dbConfigId}`,
                                search_type: "eq",
                            },
                        ],
                    },
                ],
                distinct_on: "db_query_predictions.column_name_2",
            }),
        ]).then((data) => {
            return [...data?.[0]?.value, ...data?.[1]?.value];
        });
    };

    searchForPredictionsTable = ({ dbConfigId, tableName, schema }) => {
        return Promise.allSettled([
            this.searchForPredictions({
                search: [
                    {
                        table_name: "db_query_predictions",
                    },
                ],
                filters: [
                    {
                        logical_opperand: "and",
                        items: [
                            ...(schema != null
                                ? [
                                      {
                                          column: "db_query_predictions.schema_name",
                                          is_value_column: false,
                                          search_value: schema,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            {
                                column: "db_query_predictions.table_name",
                                is_value_column: false,
                                search_value: `%${tableName}%`,
                                search_type: "like",
                            },
                            {
                                column: "db_query_predictions.db_config_id",
                                is_value_column: false,
                                search_value: `${dbConfigId}`,
                                search_type: "eq",
                            },
                        ],
                    },
                ],
                distinct_on: "db_query_predictions.table_name",
            }),
            this.searchForPredictions({
                search: [
                    {
                        table_name: "db_query_predictions",
                    },
                ],
                filters: [
                    {
                        logical_opperand: "and",
                        items: [
                            ...(schema != null
                                ? [
                                      {
                                          column: "db_query_predictions.schema_name_2",
                                          is_value_column: false,
                                          search_value: schema,
                                          search_type: "eq",
                                      },
                                  ]
                                : []),
                            {
                                column: "db_query_predictions.table_name_2",
                                is_value_column: false,
                                search_value: `%${tableName}%`,
                                search_type: "like",
                            },
                            {
                                column: "db_query_predictions.db_config_id",
                                is_value_column: false,
                                search_value: `${dbConfigId}`,
                                search_type: "eq",
                            },
                        ],
                    },
                ],
                distinct_on: "db_query_predictions.table_name_2",
            }),
        ]).then((data) => {
            return [...data?.[0]?.value, ...data?.[1]?.value];
        });
    };
    searchForPredictionsSchema = ({ dbConfigId, schema }) => {
        return this.searchForPredictions({
            search: [
                {
                    table_name: "db_query_predictions",
                },
            ],
            filters: [
                {
                    logical_opperand: "and",
                    items: [
                        {
                            column: "db_query_predictions.db_config_id",
                            is_value_column: false,
                            search_value: `${dbConfigId}`,
                            search_type: "eq",
                        },
                    ],
                },
                {
                    logical_opperand: "or",
                    items: [
                        {
                            column: "db_query_predictions.schema_name",
                            is_value_column: false,
                            search_value: `%${schema}%`,
                            search_type: "like",
                        },
                        {
                            column: "db_query_predictions.schema_name_2",
                            is_value_column: false,
                            search_value: `%${schema}%`,
                            search_type: "like",
                        },
                    ],
                },
            ],
            distinct_on: "db_query_predictions.schema_name",
        });
    };

    searchForPredictions = (searchObject, pagination = false, size = DEFAULT_PAGE_SIZE) => {
        let url = "/search/";
        if (pagination) {
            url = `/search/?pagination=${pagination}&size=${size}`;
        }
        return this.store.apiStore
            .post({
                url: url,
                body: JSON.stringify(searchObject),
                headers: { "Content-Type": "application/json", accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data ?? [];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setErrorMessage("reject", err));
    };

    searchSuggestedQueries = (query_text) => {
        let body = {
            search: [
                {
                    table_name: "db_query_predictions",
                },
            ],
            filters: [
                {
                    logical_opperand: "and",
                    items: [
                        {
                            column: "db_query_predictions.predicted_query",
                            is_value_column: false,
                            search_value: `%${query_text}%`,
                            search_type: "like",
                        },
                    ],
                },
            ],
            distinct_on: "db_query_predictions.id",
        };
        return this.searchForPredictions(body, true);
    };
}
export default MLPredictions;
