import { useState } from "react";
import { optionsSize } from "../config";

export const useTestApproachOptions = (store: any) => {
    const [testApproaches, setTestApproaches] = useState({ items: [], pageCount: 1 });
    const [testApproachesIsLoading, setTestApproachesIsLoading] = useState(false);

    const fetchTestApproachOptions = (optionFilters = {}, page = 1) => {
        if (!testApproaches.items.length) setTestApproachesIsLoading(true);

        store.testsStore
            .searchTestApproachData({
                page,
                filters: optionFilters,
                pagination: true,
                size: optionsSize,
                type: "or",
                returned: true,
            })
            .then((data: any) => {
                setTestApproaches((prevTestApproaches) => {
                    const prevIds = new Set(prevTestApproaches.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevTestApproaches.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setTestApproachesIsLoading(false));
    };

    return { testApproaches, testApproachesIsLoading, fetchTestApproachOptions };
};
