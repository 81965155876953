import { makeAutoObservable, observable } from "mobx";

class DbMetaResults {
    store;
    matched = {};
    unmatched = {};
    matched_unique = {};
    statistics = {};
    all = observable({});
    tables = {};
    schemas = {};
    error = {
        matched: "",
        matched_unique: "",
        unmatched: "",
        all: "",
        tables: "",
        schemas: "",
        statistics: "",
    };

    constructor(store) {
        this.store = store;
        makeAutoObservable(this.store);
    }

    reset = () => {
        this.all = {};
    };

    setMatchedResults = (id, data) => {
        this.matched[id] = data;
    };

    setUniqueMatchedResults = (id, data) => {
        this.matched_unique[id] = data;
    };

    setUnmatchedResults = (id, data) => {
        this.unmatched[id] = data;
    };

    setStatistics = (id, data) => {
        this.statistics[id] = data;
    };

    setDbResultsError = (property, error) => {
        this.error[property] = error;
    };

    setAllResults = (id, data) => {
        this.all[id] = data;
    };

    setTablesForId = (id, data) => {
        this.tables[id] = data;
    };

    setSchemasForId = (id, data) => {
        this.schemas[id] = data;
    };

    getTotalResultsForDb = (db_config_id) => {
        return this.store.apiStore
            .get({
                url: `/db_meta_results/${db_config_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setAllResults(db_config_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("all", err));
    };

    getTablesForDbConfigId = (db_config_id, schema = null, unique = true) => {
        const url = schema
            ? `/db_meta_results/elements/${db_config_id}?unique=${unique}&el_type=table&schema=${schema}&size=500`
            : `/db_meta_results/elements/${db_config_id}?unique=${unique}&el_type=table&size=500`;
        return this.store.apiStore
            .get({
                url: url,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setTablesForId(db_config_id, data["items"], schema);
                return data["items"];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("tables", err));
    };

    getMatchedResultsForDb = (db_config_id, unique = false) => {
        return this.store.apiStore
            .get({
                url: `/db_meta_results/matched_elements/${db_config_id}?unique=${unique}&el_type=column`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (unique) {
                    this.setUniqueMatchedResults(db_config_id, data);
                } else {
                    this.setMatchedResults(db_config_id, data);
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("matched", err));
    };

    getUnmatchedResultsForDb = (db_config_id) => {
        return this.store.apiStore
            .get({
                url: `/db_meta_results/unmatched/${db_config_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setUnmatchedResults(db_config_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("unmatched", err));
    };

    getStatisticsForDb = (db_config_id) => {
        return this.store.apiStore
            .post({
                url: `/db_meta_results/statistics/${db_config_id}`,
                headers: { "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
                body: JSON.stringify({ exclude_schemas: [], exclude_tables: [] }),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setStatistics(db_config_id, data);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("statistics", err));
    };

    resolveItemsWithValue = (db_config_id, schema, tableName, columnName) => {
        let items = [];
        items.push({
            column: "database_metadata.db_config_id",
            is_value_column: false,
            search_value: db_config_id,
            search_type: "eq",
        });
        if (schema) {
            items.push({
                column: "database_metadata.schema",
                is_value_column: false,
                search_value: schema,
                search_type: "eq",
            });
        }

        if (columnName != null && tableName != null) {
            items.push({
                column: "database_metadata.table_name",
                is_value_column: false,
                search_value: `${tableName}`,
                search_type: "eq",
            });
            items.push({
                column: "database_metadata.column_name",
                is_value_column: false,
                search_value: `%${columnName}%`,
                search_type: "like",
            });
        } else {
            if (tableName) {
                items.push({
                    column: "database_metadata.table_name",
                    is_value_column: false,
                    search_value: `%${tableName}%`,
                    search_type: "like",
                });
            }
        }
        return items;
    };

    searchDbResult = (db_config_id, schema, tableName, columnName = undefined) => {
        let items = this.resolveItemsWithValue(db_config_id, schema, tableName, columnName);
        let final_body = {
            search: [
                {
                    table_name: "database_metadata",
                    order_by: [
                        {
                            parameter: "database_metadata.table_name",
                            order_type: "asc",
                        },
                    ],
                },
            ],
            return_schema: "DatabaseMetadataSchema",
            filters: [
                {
                    logical_opperand: "and",
                    items: items,
                },
            ],
            distinct_on: "database_metadata.table_name",
        };
        return this.store.apiStore
            .post({
                url: "/search/?pagination=true",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.total > 0) {
                    let unique_data = [...new Set(data?.items.map((it) => it.table_name))];
                    this.setTablesForId(db_config_id, unique_data);
                    return unique_data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("tables", err));
    };

    searchDbSchemaResult = (db_config_id) => {
        let items = this.resolveItemsWithValue(db_config_id);
        let final_body = {
            search: [
                {
                    table_name: "database_metadata",
                    order_by: [
                        {
                            parameter: "database_metadata.schema",
                            order_type: "asc",
                        },
                    ],
                },
            ],
            return_schema: "DatabaseMetadataSchema",
            filters: [
                {
                    logical_opperand: "and",
                    items: items,
                },
            ],
            distinct_on: "database_metadata.schema",
        };
        return this.store.apiStore
            .post({
                url: "/search/?pagination=true&distinct=true",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setSchemasForId(
                    db_config_id,
                    data["items"].map((it) => it.schema)
                );
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("schemas", err));
    };

    searchDbColumnResult = (db_config_id, schema, tableName, columnName) => {
        let items = this.resolveItemsWithValue(db_config_id, schema, tableName, columnName);
        let final_body = {
            search: [
                {
                    table_name: "database_metadata",
                    order_by: [
                        {
                            parameter: "database_metadata.column_name",
                            order_type: "asc",
                        },
                    ],
                },
            ],
            return_schema: "DatabaseMetadataSchema",
            filters: [
                {
                    logical_opperand: "and",
                    items: items,
                },
            ],
            distinct_on: "database_metadata.column_name",
        };
        return this.store.apiStore
            .post({
                url: "/search/?distinct=true",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("tables", err));
    };

    findSchemasFromAttributes = (metadata, db_config_id) => {
        let final_body = {
            search: [{ table_name: "database_metadata" }],
            filters: [
                {
                    logical_opperand: "and",
                    items: [
                        {
                            column: "database_metadata.schema",
                            is_value_column: false,
                            search_value: metadata.map((ot) => `${ot}`),
                            search_type: "in",
                        },
                        {
                            column: "database_metadata.db_config_id",
                            is_value_column: false,
                            search_value: db_config_id,
                            search_type: "eq",
                        },
                    ],
                },
            ],
        };
        return this.store.apiStore
            .post({
                url: "/search/",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setDbResultsError("tables", err));
    };

    resolveItemsForSearchList = (db_config_ids, schemas, tables, columns) => {
        let items = [];
        if (db_config_ids) {
            items.push({
                column: "database_metadata.db_config_id",
                is_value_column: false,
                search_value: db_config_ids,
                search_type: "in",
            });
        }

        if (schemas) {
            items.push({
                column: "database_metadata.schema",
                is_value_column: false,
                search_value: schemas,
                search_type: "in",
            });
        }
        if (tables) {
            items.push({
                column: "database_metadata.table_name",
                is_value_column: false,
                search_value: tables,
                search_type: "in",
            });
        }
        if (columns) {
            items.push({
                column: "database_metadata.column_name",
                is_value_column: false,
                search_value: columns,
                search_type: "in",
            });
        }
        return items;
    };

    searchMetadata = (db_config_id, schema, tableName, columnName) => {
        let items = this.resolveItemsForSearchList(db_config_id, schema, tableName, columnName);
        let final_body = {
            search: [
                {
                    table_name: "database_metadata",
                    // order_by: [
                    //     {
                    //         parameter: "database_metadata.column_name",
                    //         order_type: "asc",
                    //     },
                    // ],
                },
            ],
            return_schema: "DatabaseMetadataSchema",
            filters: [
                {
                    logical_opperand: "and",
                    items: items,
                },
            ],
            // distinct_on: "database_metadata.column_name",
        };
        return this.store.apiStore
            .post({
                url: "/search/?distinct=true?pagination=true",
                body: JSON.stringify(final_body),
                headers: { accept: "application/json", "Content-Type": "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    return data;
                }
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)));
        // .catch((err) => this.setDbResultsError("tables", err));
    };
}
export default DbMetaResults;
