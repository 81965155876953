import { useState } from "react";
import { optionsSize } from "../config";

export const useDBConnectionOptions = (store: any) => {
    const [dbConnections, setDBConnections] = useState({ items: [], pageCount: 1 });
    const [dbConnectionsIsLoading, setDBConnectionsIsLoading] = useState(false);

    const fetchDBConnectionOptions = (optionFilters = {}, page = 1) => {
        if (!dbConnections.items.length) setDBConnectionsIsLoading(true);

        store.testsStore
            .searchConnectionData(optionFilters, true, "or", page)
            .then((data) => {
                if (!data) return;

                setDBConnections((prevDBConnections) => {
                    const prevIds = new Set(prevDBConnections.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevDBConnections.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setDBConnectionsIsLoading(false));
    };

    return { dbConnections, dbConnectionsIsLoading, fetchDBConnectionOptions };
};
