import { useState } from "react";
import { useConnectionData } from "./useConnectionData";

export const useDatabaseOperators = (store: any) => {
    const [isLoadingDatabaseOperators, setIsLoadingDatabaseOperators] = useState(false);
    const [databaseOperators, setDatabaseOperators] = useState([]);
    const { getConnectionDataById } = useConnectionData(store);

    const fetchDatabaseOpeartors = async (dbId: string | number, type: string) => {
        if (!dbId || !type) return;

        setIsLoadingDatabaseOperators(true);

        const connection = await getConnectionDataById(dbId);

        if (!connection) {
            setIsLoadingDatabaseOperators(false);
            return;
        }

        store.newDataQuality
            .getDatabaseOperators(connection?.db_type, type)
            .then((data) => setDatabaseOperators(data))
            .catch(() => setDatabaseOperators([]))
            .finally(() => setIsLoadingDatabaseOperators(false));
    };

    return { databaseOperators, isLoadingDatabaseOperators, fetchDatabaseOpeartors };
};
