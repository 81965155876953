import { action, makeObservable, observable } from "mobx";
import { AUTH_TOKEN } from "../enums";
class AuthStore {
    store;
    token;
    isAuthenticated = observable(false);
    errors = "";
    values = observable({
        username: "",
        password: "",
    });
    message = {
        info: "",
        error: "",
        recover_info: "",
        recover_error: "",
    };
    isUserLoading = true;
    user = null;

    constructor(store) {
        this.store = store;
        this.token = localStorage.getItem(AUTH_TOKEN);

        makeObservable(this, {
            token: observable,
            user: observable,
            isUserLoading: observable,
            message: observable,
        });

        if (this.token) {
            this.setAuthenticated(true);
        }
    }

    setAuthenticated = action((state) => {
        this.isAuthenticated = state;
    });

    setUsername = action((username) => {
        this.values.username = username;
    });

    setPassword = action((password) => {
        this.values.password = password;
    });

    setMessage = action((type, msg) => {
        this.message[type] = msg;
    });

    reset = action(() => {
        this.values.username = "";
        this.values.password = "";
    });

    setAuthToken = action((data) => {
        this.token = data["access_token"];
        localStorage.setItem(AUTH_TOKEN, data["access_token"]);
    });

    setUser = action((data) => {
        this.user = data;
    });

    setIsUserLoading = action((data) => {
        this.isUserLoading = data;
    });

    removeAuthToken() {
        localStorage.removeItem(AUTH_TOKEN);
        this.token = null;
        this.setAuthenticated(false);
    }

    getAuthHeader() {
        return this.token ? { Authorization: `Bearer ${this.token}` } : "";
    }

    setErrorMessage = action((errorMessage) => {
        this.errors = errorMessage;
    });

    getMe = action((isLogin = false) => {
        this.setIsUserLoading(true);
        return this.store.apiStore
            .get({
                url: "/users/me",
                auth_headers: this.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setUser(data);
                return data;
            })
            .catch(() => {
                console.log("Could not retrieve user identity");
                this.removeAuthToken();
                this.setUser(null);
            })
            .finally(() => !isLogin && this.setIsUserLoading(false));
    });

    getUserRole = () => {
        return this.user?.role_name?.toLowerCase();
    };

    ifUserIsAnAdmin = () => {
        return !!this.user?.role_name?.toLowerCase().includes("admin");
    };

    login() {
        this.setIsUserLoading(true);
        return this.store.apiStore
            .post({
                url: `/login/access-token`,
                body: new URLSearchParams({
                    username: this.values.username,
                    password: this.values.password,
                }),
                headers: { "Content-Type": "application/x-www-form-urlencoded", accept: "application/json" },
            })

            .then((response) => response.json())
            .then((data) => {
                this.setAuthToken(data);
                this.setAuthenticated(true);
                this.setErrorMessage("");
            })
            .catch((err) =>
                err.status === 401 || err.status === 400
                    ? err.json().then((data) => Promise.reject(data.detail || "Unable to login"))
                    : Promise.reject(err.statusText)
            )
            .catch((err) => {
                this.setErrorMessage(err);
                this.setIsUserLoading(false);
            });
    }

    recoverPassword = (email) => {
        return this.store.apiStore
            .post({
                url: `/login/password-recovery?email=${email}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                return data["msg"];
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setMessage("error", err));
    };

    resetPassword = (token, password) => {
        return this.store.apiStore
            .post({
                url: `/login/password-reset?token=${token}&new_password=${password}`,
                headers: { accept: "application/json" },
                auth_headers: this.store.authStore.getAuthHeader(),
            })
            .then((response) => response.json())
            .then((data) => {
                this.setMessage("recover_info", data["msg"]);
            })
            .catch((err) => err.json().then((data) => Promise.reject(data?.detail)))
            .catch((err) => this.setMessage("recover_error", err));
    };
}

export default AuthStore;
