import { useState } from "react";
import { optionsSize } from "../config";

export const useReleaseOptions = (store: any) => {
    const [releases, setReleases] = useState({ items: [], pageCount: 1 });
    const [releasesIsLoading, setReleasesIsLoading] = useState(false);

    const fetchReleaseOptions = (optionFilters = {}, page = 1) => {
        if (!releases.items.length) setReleasesIsLoading(true);

        store.releaseStore
            .searchRelease({ filters: optionFilters, pagination: true, page, size: optionsSize, type: "or" })
            .then((data) => {
                if (!data) return;

                setReleases((prevReleases) => {
                    const prevIds = new Set(prevReleases.items.map((prevItem) => prevItem.id));
                    return {
                        items: [...prevReleases.items, ...data.items.filter((item) => !prevIds.has(item.id))],
                        pageCount: Math.ceil(data?.total / optionsSize),
                    };
                });
            })
            .finally(() => setReleasesIsLoading(false));
    };

    return { releases, releasesIsLoading, fetchReleaseOptions };
};
