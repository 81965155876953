import { Space } from "antd";
import { Link } from "react-router-dom";
import logo from "../../../../assets/images/logo/named_logo.png";
export default function MenuLogo(props) {
    const user = props.store.authStore.user;
    return (
        <Space style={{ padding: 10 }}>
            <Link
                to={user?.default_path || "/"}
                className="da-d-flex da-align-items-end"
                onClick={props.onClose}
            >
                <img className="da-logo" style={{ height: 50 }} src={logo} alt="logo" />
            </Link>
        </Space>
    );
}
