import { useEffect, useState } from "react";
import { TagsMode } from "../enums";

export const useExecutionTags = (store: any, testSuite: any[], testApproach: any[], initData?: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [tags, setTags] = useState([]);
    const [separateTags, setSeparateTags] = useState(TagsMode.AND);

    const handleDefaultData = async () => {
        if (testSuite && testApproach) {
            setIsLoading(true);
            await store.tagsStore.getRelatedTags(testSuite, testApproach).then(() => setIsLoading(false));
        }

        if (initData?.tags) {
            if (initData?.tags?.length) {
                setTags(initData?.tags);
                setSeparateTags(initData?.separate_tags ? TagsMode.OR : TagsMode.AND ?? TagsMode.AND);
            }
        } else {
            if (store.executionStore.tags?.length) {
                setTags(store.executionStore.tags);
                setSeparateTags(store.executionStore.separate_tags);
            }
        }
    };

    useEffect(() => {
        handleDefaultData();
    }, []);

    useEffect(() => {
        if (initData) {
            setTags(initData?.tags);
            setSeparateTags(initData?.separate_tags ? TagsMode.OR : TagsMode.AND ?? TagsMode.AND);
        }
    }, [initData]);

    return { tags, setTags, separateTags, setSeparateTags, isLoading };
};
